import { title } from "process";
import React, { useEffect, useRef, useState } from "react";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";
import addTopMechanicalEngineering from "../../../../images/courses/top-mechanical-add.svg";
import { SVGProps } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

export function MingcuteRightLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="10px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
        ></path>
      </g>
    </svg>
  );
}

const TopMecEngineering = () => {
  const { data } = useCourseDetails();
  const { courseTitle = "", topColleges } = data || {};
  return (
    <>
      <div className="block md:flex relative" id="top-colleges">
        <div className="flex flex-col bg-[#fff] relative w-full md:w-3/4">
          <div className="flex max-md:w-full">
            <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
              {`Top ${courseTitle} Colleges in India`}

              <ToolTip text="As per NIRF private colleges ranking" mb={3} />
            </h3>
          </div>
          <div className="flex flex-col px-2 py-8 md:py-5 md:px-9 gap-6 blog-content"
            dangerouslySetInnerHTML={{ __html: topColleges }} />
        </div>
        <div className="take-our-quiz-section w-full md:w-3/12 bg-[#FFF1FA] px-7 py-14 hidden md:flex flex-col">
          <div className="course-quiz-box  bg-[#C7EAFB] pt-4">
            <div>
              <img
                src="/images/course-details/course-quiz-text.png"
                alt="course quiz text"
                className="mx-auto"
              />
            </div>
            <div>
              <img
                src="/images/course-details/course-quiz.png"
                alt="course quiz"
                className=""
              />
            </div>
            <div>
              <span className="w-full h-12 bg-[#F6C] text-white justify-end text-xl font-extrabold">
                Take Our Quiz
              </span>
            </div>
          </div>
          <div className="chat-with-mentor bg-[#FFEFB5] mt-5">
            <div>
              <img
                src="/images/course-details/logo.png"
                alt="logo"
                className=""
              />
            </div>
            <div className="relative">
              <img src={addTopMechanicalEngineering} alt="logo" className="" />
              {/* <div className="absolute top-[85px] left-[75px] w-[75px] text-center">
                <p className="text-[14px] text-[#263238]">
                  Not Sure University is <br />
                  <strong>Right For You ?</strong>
                </p>
              </div> */}
              <a
                href="/mentors"
                className="mx-auto flex flex-row w-[266px] font-inter text-white text-xs sm:text-sm md:text-base font-medium md:leading-8 rounded-3xl bg-[#173CBA] group cursor-pointer justify-between items-center p-2"
              >
                <span>Connect with buddy mentor</span>
                <img
                  src="/images/rightarrow.svg"
                  className="w-5 h-5 lg:w-8 lg:h-8 group-hover:translate-x-2 transition-transform duration-300"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopMecEngineering;

const ToolTipIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.497 6.125C8.497 6.50633 8.38867 7.1 8.172 7.906C7.95533 8.70333 7.847 9.22333 7.847 9.466C7.847 9.70867 7.89467 9.83 7.99 9.83C8.08533 9.83 8.25867 9.78667 8.51 9.7L8.627 9.648L8.861 10.298C8.56633 10.5233 8.198 10.7227 7.756 10.896C7.32267 11.0693 6.92833 11.156 6.573 11.156C6.21767 11.156 5.936 11.0563 5.728 10.857C5.52867 10.6577 5.429 10.3717 5.429 9.999C5.429 9.61767 5.533 9.04133 5.741 8.27C5.95767 7.49 6.066 6.996 6.066 6.788C6.066 6.46733 5.95333 6.164 5.728 5.878L5.624 5.735L5.663 5.514C6.11367 5.37533 6.989 5.306 8.289 5.306C8.42767 5.462 8.497 5.735 8.497 6.125ZM7.522 4.292C7.132 4.292 6.82433 4.188 6.599 3.98C6.38233 3.772 6.274 3.50767 6.274 3.187C6.274 2.85767 6.40833 2.58467 6.677 2.368C6.95433 2.14267 7.288 2.03 7.678 2.03C8.068 2.03 8.37133 2.121 8.588 2.303C8.80467 2.485 8.913 2.74067 8.913 3.07C8.913 3.39933 8.77867 3.68533 8.51 3.928C8.25 4.17067 7.92067 4.292 7.522 4.292Z"
      fill="white"
    />
    <circle cx="7" cy="7" r="6.5" stroke="white" />
  </svg>
);

const ToolTip = ({ text, mb }: { text: string; mb: number }) => {
  const tooltipRef = useRef<HTMLSpanElement>(null);
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    if (tooltipRef?.current) {
      const buttonRight = tooltipRef.current.getBoundingClientRect().right;
      const screenWidth = window.innerWidth;

      if (buttonRight > screenWidth) setOverflow(true);
      else setOverflow(false);
    }
  }, [tooltipRef]);

  return (
    <div className={`inline-block relative group mb-${mb} `}>
      <ToolTipIcon />
      <span
        ref={tooltipRef}
        className={`absolute opacity-0 group-hover:opacity-100 w-max px-2 py-1 text-sm text-grey font-normal bg-white border border-grey rounded top-0 left-1/2 -translate-y-[110%] ${overflow ? "-translate-x-full" : ""
          }`}
      >
        {text}
      </span>
    </div>
  );
};
