import { Link, useNavigate } from "react-router-dom";
import Tooltip from "./Tooltip";
import React, { useState } from "react";
import LeadGeneration from "../../../components/LeadGeneration";
import LeadGenCtaBtn from "../../../features/lead-generation/LeadGenCtaBtn";

interface CollegeDetails {
  courses: string[];
  exams: string[];
  additional_info: {
    rankings: {
      shanghai_ranking: {
        world: string;
      };
      times_higher_education: {
        world: string;
        asia: string;
      };
      nirf: {
        university: string;
        overall: string;
      };
      qs_ranking: {
        world: string;
        asia: string;
        india: string;
      };
    };
    accreditations: string[];
  };
}

export interface College {
  college_name: string;
  college_title: string;
  imageUrl: string;
  collegeId: number;
  location: string;
  details: CollegeDetails;
  type: string;
  broucherUrl: string;
}
interface CollegeListProps {
  colleges: College[];
  //   onOverviewClick: (value: any) => void;
}

const isMediumDevice = window.innerWidth >= 768;

const items = ["Admission & Eligibility", "Placement", "Facilities", "Compare"];

const tooltipContent = (
  <ul className="list-disc pl-4">
    <li>Accreditations: NAAC A++, ABET, NBA, ICAR</li>
    <li>NIRF: Overall (32), University (18), Engineering (28)</li>
    <li>QS Ranking: World (1000 - 1200), Asia (162), India (17)</li>
    <li>Times Higher Education: World (1201 - 1500), Asia (401 - 500)</li>
    <li>Shanghai ranking: World (801 - 900), India (9 - 12)</li>
  </ul>
);

export const CollegeList: React.FC<CollegeListProps> = ({ colleges }) => {
  const navigate = useNavigate();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [favoriteColleges, setFavoriteColleges] = useState<
    Record<number, boolean>
  >({});

  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const toggleFavorite = (collegeId: any) => {
    setFavoriteColleges((prevState: any) => ({
      ...prevState,
      [collegeId]: !prevState[collegeId],
    }));
  };
  const formatCollegeName = (name: string): string => {
    return name.toLowerCase().replace(/\s+/g, "-");
  };
  const itemsLinks = ["admissions", "placements", "facilities", "compare"];

  return (
    <>
      {colleges.length === 0 ? (
        <div className="flex justify-center items-center h-[300px] w-full bg-white rounded-lg">
          <h3 className="text-lg text-[#333333]">No Colleges Found</h3>
        </div>
      ) : (
        <div className="flex flex-col gap-3 w-full">
          {colleges.map((eachCollege, index) => {
            const {
              college_name,
              details,
              location,
              type,
              imageUrl,
              college_title,
              collegeId,
              broucherUrl,
            } = eachCollege;
            const additional_info = details?.additional_info;
            const handleCollegeClick = () => {
              navigate(
                `/college-details/${college_name
                  .toLowerCase()
                  .replace(/\s+/g, "-")}`
              );
            };

            return (
              <div
                className="bg-white p-5 rounded-2xl shadow-[0px_2px_10px_2px_rgba(0,0,0,0.06)]"
                key={collegeId}
              >
                <Link
                  to={`../college-details/${college_name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}`}
                >
                  <div className="flex w-full max-md:flex-col gap-2">
                    {!isMediumDevice && (
                      <div className="w-full md:w-3/12 md:relative">
                        <img
                          src={imageUrl}
                          alt="college"
                          className="md:absolute w-full top-0 right-0 md:h-full"
                        />
                      </div>
                    )}
                    <div className="w-full md:w-9/12 pb-5">
                      <Link
                        to="#"
                        className="text-base text-[#00B489] font-semibold"
                      >
                        #NIRF {additional_info?.rankings?.nirf?.overall} College
                        in India
                      </Link>
                      <h3
                        className="text-[#181A27] text-lg font-bold my-[10px] cursor-pointer"
                        onClick={handleCollegeClick}
                      >
                        {college_title}
                      </h3>
                      <div className="flex flex-col md:flex-row ">
                        <div className="flex flex-row min-max-xss:justify-evenly min-max-xs:justify-evenly">
                          <div className=" flex flex-row items-center">
                            <span className="mr-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_1_1209)">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M8.5 7.96875C7.61972 7.96875 6.90625 7.25528 6.90625 6.375C6.90625 5.49472 7.61972 4.78125 8.5 4.78125C9.38028 4.78125 10.0938 5.49472 10.0938 6.375C10.0938 7.25528 9.38028 7.96875 8.5 7.96875ZM8.5 3.71875C7.03322 3.71875 5.84375 4.90769 5.84375 6.375C5.84375 7.84231 7.03322 9.03125 8.5 9.03125C9.96678 9.03125 11.1562 7.84231 11.1562 6.375C11.1562 4.90769 9.96678 3.71875 8.5 3.71875ZM8.5 15.4063C7.61653 15.411 3.1875 8.59616 3.1875 6.375C3.1875 3.44144 5.56591 1.0625 8.5 1.0625C11.4341 1.0625 13.8125 3.44144 13.8125 6.375C13.8125 8.56641 9.36966 15.411 8.5 15.4063ZM8.5 0C4.97941 0 2.125 2.85441 2.125 6.375C2.125 9.04081 7.44016 17.0058 8.5 17C9.54338 17.0058 14.875 9.00469 14.875 6.375C14.875 2.85441 12.0206 0 8.5 0Z"
                                    fill="#173CBA"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1_1209">
                                    <rect width="17" height="17" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                            <a href="">{location}</a>
                          </div>
                          <div className=" mx-[14px]  border-r-2 border-[#ccc] h-4 my-auto"></div>
                          <div className=" flex flex-row items-center">
                            <span className="mr-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                              >
                                <path
                                  d="M1.25 2.98364V14.6736H7.61667C7.93035 14.6736 8.24095 14.7387 8.53066 14.865C8.82036 14.9913 9.08347 15.1764 9.30489 15.4097C9.52631 15.643 9.70169 15.9199 9.82098 16.2245C9.94026 16.5292 10.0011 16.8555 10 17.1849V5.48614C10 5.15751 9.93835 4.83209 9.81858 4.52848C9.6988 4.22486 9.52325 3.94899 9.30194 3.71661C9.08062 3.48423 8.81789 3.2999 8.52873 3.17413C8.23957 3.04837 7.92965 2.98364 7.61667 2.98364H1.25Z"
                                  stroke="#173CBA"
                                  stroke-miterlimit="10"
                                />
                                <path
                                  d="M18.75 2.98364V14.6736H12.3833C12.0697 14.6736 11.7591 14.7387 11.4694 14.865C11.1797 14.9913 10.9165 15.1764 10.6951 15.4097C10.4737 15.643 10.2983 15.9199 10.179 16.2245C10.0598 16.5292 9.99892 16.8555 10 17.1849V5.48614C10 4.82244 10.2511 4.18592 10.6981 3.71661C11.145 3.2473 11.7512 2.98364 12.3833 2.98364H18.75Z"
                                  stroke="#173CBA"
                                  stroke-miterlimit="10"
                                />
                                <path
                                  d="M18.75 14.6738V18.0163H12.3833H7.61667H1.25V14.6738"
                                  stroke="#173CBA"
                                  stroke-miterlimit="10"
                                />
                              </svg>
                            </span>
                            <a href="#">{type}</a>
                          </div>
                          {isMediumDevice && (
                            <div className=" mx-[14px]  border-r-2 border-[#ccc] h-4 my-auto"></div>
                          )}
                        </div>
                        <div className="flex min-max-xss:justify-center min-max-xs:justify-center">
                          <div className="flex flex-row items-center">
                            <span className="mr-2 ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 21 21"
                                fill="none"
                              >
                                <path
                                  d="M7.40582 12.4202H2.98095C1.9387 12.4202 1.08594 13.2729 1.08594 14.3152V20.0002H7.40582V12.4202Z"
                                  stroke="#173CBA"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M11.8194 8.62988H9.28952C8.24729 8.62988 7.39453 9.48264 7.39453 10.5249V20H13.7144V10.5249C13.7144 9.48264 12.8711 8.62988 11.8194 8.62988Z"
                                  stroke="#173CBA"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M18.1397 15.2627H13.7148V20.0002H20.0347V17.1577C20.0347 16.1155 19.182 15.2627 18.1397 15.2627Z"
                                  stroke="#173CBA"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M11.0519 1.1163L11.5541 2.12064C11.6205 2.26277 11.8005 2.39542 11.9521 2.41437L12.8617 2.56598C13.4397 2.66073 13.5818 3.08709 13.1649 3.49452L12.4543 4.20515C12.3311 4.32833 12.2648 4.55574 12.3027 4.71682L12.5016 5.58853C12.6627 6.28022 12.2932 6.54552 11.6868 6.18547L10.834 5.68329C10.6824 5.58853 10.4266 5.58853 10.275 5.68329L9.42223 6.18547C8.81583 6.54552 8.44628 6.28022 8.60735 5.58853L8.80635 4.71682C8.84425 4.55574 8.77793 4.31885 8.65474 4.20515L7.95358 3.50401C7.53668 3.0871 7.66933 2.67019 8.25679 2.57544L9.1664 2.42386C9.318 2.39543 9.49803 2.26278 9.56436 2.13013L10.0665 1.12575C10.3413 0.576196 10.7772 0.576215 11.0519 1.1163Z"
                                  fill="#F9B21D"
                                />
                              </svg>
                            </span>

                            <Tooltip content={tooltipContent}>
                              <span className="flex items-center text-[#181A27]">
                                Ranking & Accreditation (i)
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      {details.courses.length > 0 ||
                      details.exams.length > 0 ? (
                        <div className="card-grid-section mt-5 w-full">
                          <table className="border border-[#D1D1D1] border-collapse w-full lg:w-[550px]">
                            <tbody>
                              {details.courses.length > 0 ? (
                                <tr>
                                  <th className="border border-[#D1D1D1] p-2">
                                    Courses & Fee (i)
                                  </th>
                                  <td className="border border-[#D1D1D1] p-2">
                                    {details.courses.map(
                                      (course: string, index: number) => {
                                        return (
                                          <span key={index}>
                                            {course}
                                            {index <
                                              details.courses.length - 1 && (
                                              <span className="px-1">/</span>
                                            )}
                                          </span>
                                        );
                                      }
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                <tr>
                                  <th className="border border-[#D1D1D1] p-2">
                                    Courses & Fee (i)
                                  </th>
                                  <td className="border border-[#D1D1D1] p-2 text-gray-500">
                                    No data available
                                  </td>
                                </tr>
                              )}
                              {details.exams.length > 0 ? (
                                <tr>
                                  <th className="border border-[#D1D1D1] p-2">
                                    Exams (i)
                                  </th>
                                  <td className="p-2 flex md:justify-between min-max-xs:flex-col">
                                    <span>
                                      {details.exams.length > 1
                                        ? `${details.exams[0]} +${
                                            details.exams.length - 1
                                          }`
                                        : details.exams[0]}
                                    </span>
                                  </td>
                                </tr>
                              ) : (
                                <tr>
                                  <th className="border border-[#D1D1D1] p-2">
                                    Exams (i)
                                  </th>
                                  <td className="border border-[#D1D1D1] p-2 text-gray-500">
                                    No data available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="text-gray-500 mt-5">
                          No data available for courses or exams.
                        </div>
                      )}
                    </div>
                    {isMediumDevice && (
                      <div className="w-full md:w-3/12 md:relative">
                        <img
                          src={imageUrl}
                          alt="college"
                          className="md:absolute top-0 right-0 md:h-full"
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex flex-row">
                    <div className="11/12">
                      <div className="flex min-max-xss:flex-wrap min-max-xs:flex-wrap items-center justify-center  w-full border-t border-dashed border-[#ccc] pt-4">
                        {items.map((item, index) => (
                          <React.Fragment key={item}>
                            <Link
                              to={`/college-details/${formatCollegeName(
                                college_name
                              )}#${itemsLinks[index]}`}
                            >
                              <span className="mx-1 md:mx-2 text-sm capitalize text-[#173CBA]">
                                {item}
                              </span>
                            </Link>
                            {index < items.length - 1 && (
                              <span className="mx-1 md:mx-2 text-[#CCC]">
                                |
                              </span>
                            )}
                          </React.Fragment>
                        ))}
                        <span className="mx-1 md:mx-2 text-[#CCC]">|</span>
                        <LeadGenCtaBtn
                          pageName="college-intermediate"
                          ctaName="request-campus-visit"
                          additionalInfo={college_name}
                          className="mx-1 md:mx-2 text-sm capitalize text-[#173CBA]"
                        >
                          Request Campus Visit
                        </LeadGenCtaBtn>
                        <span className="mx-1 md:mx-2 text-[#CCC]">|</span>

                        {/* <LeadGenCtaBtn
                        pageName="college-intermediate"
                        ctaName="virtual-tour"
                        additionalInfo={college_name}
                        className="mx-1 md:mx-2 text-sm capitalize text-[#173CBA]"
                      >
                        Virutal Tour
                      </LeadGenCtaBtn> 
                      <span className="mx-1 md:mx-2 text-[#CCC]">|</span>*/}
                        {isMediumDevice && (
                          <>
                            <LeadGenCtaBtn
                              pageName="college-intermediate"
                              ctaName="apply-now"
                              additionalInfo={college_name}
                              className="mx-1 md:mx-2 text-sm capitalize text-[#173CBA]"
                            >
                              Apply Now
                            </LeadGenCtaBtn>
                          </>
                        )}
                      </div>
                    </div>
                    {isMediumDevice && (
                      <div className="w-1/12 pt-4 flex justify-center">
                        <span
                          className="cursor-pointer"
                          onClick={() => toggleFavorite(collegeId)}
                        >
                          {!favoriteColleges[collegeId] ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="24"
                              viewBox="0 0 26 24"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.5752 3.5953C10.2609 0.830832 6.39373 -0.0235062 3.4941 2.50791C0.594457 5.03933 0.186227 9.2717 2.46333 12.2656C4.35659 14.7548 10.0862 20.0049 11.9641 21.7042C12.1741 21.8942 12.2792 21.9893 12.4018 22.0266C12.5087 22.0592 12.6257 22.0592 12.7327 22.0266C12.8553 21.9893 12.9602 21.8942 13.1704 21.7042C15.0483 20.0049 20.7778 14.7548 22.6711 12.2656C24.9482 9.2717 24.5898 5.0127 21.6403 2.50791C18.6908 0.00312315 14.8894 0.830832 12.5752 3.5953Z"
                                stroke="#AF1F1F"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="22"
                              viewBox="0 0 24 22"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.5752 2.5953C9.2609 -0.169168 5.39373 -1.02351 2.4941 1.50791C-0.405543 4.03933 -0.813773 8.2717 1.46333 11.2656C3.35659 13.7548 9.08624 19.0049 10.9641 20.7042C11.1741 20.8942 11.2792 20.9893 11.4018 21.0266C11.5087 21.0592 11.6257 21.0592 11.7327 21.0266C11.8553 20.9893 11.9602 20.8942 12.1704 20.7042C14.0483 19.0049 19.7778 13.7548 21.6711 11.2656C23.9482 8.2717 23.5898 4.0127 20.6403 1.50791C17.6908 -0.996877 13.8894 -0.169168 11.5752 2.5953Z"
                                fill="#AF1F1F"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                  {!isMediumDevice && (
                    <div className="flex flex-row gap-6 mt-4">
                      <LeadGenCtaBtn
                        pageName="college-intermediate"
                        ctaName="apply-now"
                        additionalInfo={college_name}
                        className="mx-1 md:mx-2 text-sm capitalize text-[#173CBA]"
                      >
                        Apply Now
                      </LeadGenCtaBtn>

                      <button
                        className={`border border-[#AF1F1F] text-[#AF1F1F] text-sm px-3 py-2 rounded-md flex items-center justify-center ${
                          favoriteColleges[collegeId]
                            ? "bg-[#AF1F1F] !text-white"
                            : ""
                        }`}
                        onClick={() => toggleFavorite(collegeId)}
                      >
                        Add to List
                        <span className="material-icons ml-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="16"
                            viewBox="0 0 26 24"
                            fill="#ffffff"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12.5752 3.5953C10.2609 0.830832 6.39373 -0.0235062 3.4941 2.50791C0.594457 5.03933 0.186227 9.2717 2.46333 12.2656C4.35659 14.7548 10.0862 20.0049 11.9641 21.7042C12.1741 21.8942 12.2792 21.9893 12.4018 22.0266C12.5087 22.0592 12.6257 22.0592 12.7327 22.0266C12.8553 21.9893 12.9602 21.8942 13.1704 21.7042C15.0483 20.0049 20.7778 14.7548 22.6711 12.2656C24.9482 9.2717 24.5898 5.0127 21.6403 2.50791C18.6908 0.00312315 14.8894 0.830832 12.5752 3.5953Z"
                              stroke="#AF1F1F"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  )}
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
