import React from "react";
import GenericSection from "./GenericSection";
import FAQSection from "./FAQSection";
import ExamUpdates from "./ExamUpdates";
import ApplicationForms from "../college-details/sidebar/ApplicationForms";
import CourseQuizBanner from "./CourseQuizBanner";

interface Section {
  title: string;
  description: string | FAQItem[];
  imageUrl?: string;
  order: string;
}

interface FAQItem {
  question: string;
  answer: string;
}

interface AnswerKeyTabProps {
  data: { [key: string]: Section };
}

const AnswerKeyTab: React.FC<AnswerKeyTabProps> = ({ data }) => {
  const sortedSections = Object.keys(data)
    .map((key) => ({ key, ...data[key] }))
    .sort((a, b) => parseInt(a.order) - parseInt(b.order));

  return (
    <div className="section-container bg-[#F4F4F4]">
      <div className="w-full">
        <ExamUpdates />
      </div>
      <div className="py-5">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="w-full lg:w-9/12">
              <div className="flex flex-col gap-6">
                {sortedSections.map((section) => {
                  switch (section.key) {
                    case "key_2": // FAQ Section
                      if (Array.isArray(section.description)) {
                        return (
                          <FAQSection
                            key={section.key}
                            title={section.title}
                            faqs={section.description as FAQItem[]}
                          />
                        );
                      }
                      break;

                    default:
                      return (
                        <GenericSection
                          key={section.key}
                          title={section.title}
                          description={section.description}
                        />
                      );
                  }
                })}
              </div>
            </div>
            <div className="w-full lg:w-3/12 hidden lg:block">
              <div className="flex flex-col gap-20 bg-[#DFE0E0] px-4 py-8">
                <ApplicationForms />
                <CourseQuizBanner />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerKeyTab;
