import React, { useState, useRef, useEffect } from "react";
import BlogTab from "../../../components/courses/ExploreMore/BlogTab";
import config from "../../../util/config";

interface Article {
  image: string;
  publishDate: string;
  description: string;
  title: string;
  slug: string;
}

const ExploreMore: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabIndicatorStyle, setTabIndicatorStyle] =
    useState<React.CSSProperties>({ marginLeft: 0, width: 0 });
  const tabRef = useRef<HTMLButtonElement[]>([]);
  const [articles, setArticles] = useState<Article[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          config.apiUrl + "/api/course/get-all-streams-courses"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setArticles(result.exploreMore.articles || []);
      } catch (err: any) {
        setError(err.message || "An unknown error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
    setIndicatorToActiveTab(index);
  };

  const handleTabMouseEnter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const tabRect = event.currentTarget.getBoundingClientRect();
    const containerRect =
      event.currentTarget.parentElement?.getBoundingClientRect();
    const tabStart = tabRect.left;
    const containerStart = containerRect?.left || 0;
    const distanceTabStartToContainerStart = tabStart - containerStart;
    const halfWidth = tabRect.width / 2;
    const marginLeft =
      distanceTabStartToContainerStart + (tabRect.width - halfWidth) / 2;

    setTabIndicatorStyle({
      marginLeft: `${marginLeft}px`,
      width: `${halfWidth}px`,
    });
  };

  const handleTabMouseLeave = () => {
    setIndicatorToActiveTab(selectedTab);
  };

  const setIndicatorToActiveTab = (index: number) => {
    const tabElement = tabRef.current[index];
    if (!tabElement) return;
    const tabRect = tabElement.getBoundingClientRect();
    const containerRect = tabElement.parentElement?.getBoundingClientRect();
    const tabStart = tabRect.left;
    const containerStart = containerRect?.left || 0;
    const distanceTabStartToContainerStart = tabStart - containerStart;

    setTabIndicatorStyle({
      marginLeft: `${distanceTabStartToContainerStart + tabRect.width / 4}px`,
      width: `${tabRect.width / 2}px`,
    });
  };

  useEffect(() => {
    setIndicatorToActiveTab(selectedTab);
  }, [selectedTab]);

  return (
    <div className="py-16 relative">
      <div className="container mx-auto">
        <div className="flex flex-col gap-4">
          <h3 className="text-3xl font-bold text-black">Explore More</h3>
          <div className="relative">
            <div
              className="tab-container gap-4"
              onMouseLeave={handleTabMouseLeave}
            >
              {["Blogs & Articles"].map((tab, index) => (
                <button
                  key={index}
                  ref={(el) => (tabRef.current[index] = el!)}
                  onClick={() => handleTabClick(index)}
                  onMouseEnter={handleTabMouseEnter}
                  className={`py-2 text-xl hover:text-[#173CBA] transition-all ${
                    selectedTab === index
                      ? "text-[#173CBA] font-bold"
                      : "text-[#A8A8A8]"
                  }`}
                >
                  {tab}
                </button>
              ))}
              <div
                className="absolute bottom-[-4px] h-[6px] bg-[#00C798] rounded-lg transition-all"
                style={tabIndicatorStyle}
              />
            </div>
          </div>
          {isLoading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : (
            selectedTab === 0 && <BlogTab articles={articles} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ExploreMore;
