import { Link } from "react-router-dom";
import LeadGenCtaBtn from "../../../features/lead-generation/LeadGenCtaBtn";

const OnlineCourseCard: React.FC<{
  logo: string;
  title: string;
  college: string;
  approvedBy: string;
  ownership: string;
  program: string;
  feesYearly: string;
  handlePopup: () => void;
  setPopupLogo: (image: string) => void;
}> = ({
  logo,
  title,
  college,
  approvedBy,
  ownership,
  program,
  feesYearly,
  handlePopup,
  setPopupLogo,
}) => {
  const tickLogo = (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6822 4.18832L12.452 5.41117C12.6466 5.98333 12.7523 6.59755 12.7523 7.23703C12.7523 10.3446 10.2544 12.8646 7.17403 12.8646C4.09368 12.8646 1.59577 10.346 1.59577 7.23703C1.59577 4.12942 4.09368 1.60941 7.17403 1.60941C8.70586 1.60941 10.0931 2.23205 11.1009 3.24034L12.4437 2.32601C11.1329 0.895612 9.2577 -0.000488281 7.17403 -0.000488281C3.2124 -0.000488281 0 3.24034 0 7.23703C0 11.2337 3.2124 14.4745 7.17403 14.4745C11.1357 14.4745 14.3481 11.2337 14.3481 7.23703C14.3481 6.1474 14.109 5.11387 13.6822 4.18832Z"
        fill="#00CC99"
      />
      <path
        d="M7.17371 8.05341C9.22541 5.1772 11.8387 3.24476 14.8009 1.8957C14.9496 1.79614 15.0636 2.00228 14.9594 2.08923C12.2891 4.38207 9.83564 7.11805 7.57404 10.2481C7.34051 10.5061 6.93323 10.4879 6.72333 10.2102L5.32912 8.36053C4.44922 7.24987 3.60685 6.80813 2.80618 7.06896C2.74085 7.0928 2.69498 7.02269 2.75336 6.96379L3.36081 6.24018C4.81758 4.69339 6.47312 7.36626 7.17371 8.05341Z"
        fill="#00CC99"
      />
    </svg>
  );

  return (
    <div className="font-open-sans rounded-xl shadow-lg border border-grey-3 p-6 mb-4 flex flex-col gap-4">
      <img src={logo} className="h-7 self-start" />
      <div className="border-t border-grey-3"></div>
      <h1 className="min-h-16 text-xl font-medium text-black text-center">
        {title}
      </h1>
      <div className="border-t border-grey-3"></div>
      <p className="flex gap-2 items-center text-sm text-black">
        {tickLogo}
        Approved by - {approvedBy}
      </p>
      <p className="flex gap-2 items-center text-sm text-bold">
        {tickLogo}
        {ownership}
      </p>
      <div
        className="text-black text-[10px] p-2 rounded-lg flex justify-between border-yellow bg-gradient-to-b from-white to-yellow-light"
        style={{ borderWidth: 0.5 }}
      >
        <div>
          <p>Program</p>
          <p className="font-bold mt-1">{program}</p>
        </div>
        <div>
          <p>Fees Yearly</p>
          <p className="font-bold mt-1">{feesYearly}</p>
        </div>
      </div>
      <LeadGenCtaBtn
        pageName="course-landing"
        ctaName="Online Course Card - Apply Now"
        className="py-2 rounded-lg text-base text-white bg-gradient-to-r from-blue to-[#06AFA9]"
      >
        Apply Now
      </LeadGenCtaBtn>
      <Link
        to={`/college-details/${college}`}
        className="text-blue text-sm font-semibold text-center"
      >
        Courses & Fees
      </Link>
    </div>
  );
};

export default OnlineCourseCard;
