import React, { useState } from "react";
import BannerImg from "../../images/study-abroad/Student/banner-img.png";
import Image from "../Image";
import { EpRight } from "../study-abroad-landing/Banner/Banner";
import LeadGeneration from "../LeadGeneration";
import LeadGenCtaBtn from "../../features/lead-generation/LeadGenCtaBtn";

interface BannerSectionProps {
  data: {
    title: string;
    description: string;
    services: {
      title: string;
      description: string;
      buttonText: string;
    };
  };
}

const BannerSection: React.FC<BannerSectionProps> = ({ data }) => {
  return (
    <section className="bg-[#FFF1FA] py-12 md:py-16">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="w-full lg:w-1/2">
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-6 md:gap-8">
                <h2 className="text-blue text-4xl lg:text-5xl font-bold">
                  {data.title}
                </h2>
                <p className="text-base text-black">{data.description}</p>
              </div>
              <div className="flex flex-col gap-6 md:gap-8">
                <div className="flex flex-col">
                  <h4 className="text-xl text-blue font-semedium">
                    {data.services.title}
                  </h4>
                  <p className="text-base">{data.services.description}</p>
                </div>
                <div className="flex flex-row">
                  <LeadGenCtaBtn
                    pageName="study-abroad-student"
                    ctaName="expert-mentorship"
                    className="bg-green hover:bg-blue button-styles py-3.5 px-3 green-arrow-button flex flex-row items-center justify-center gap-2 text-white"
                  >
                    {data.services.buttonText}{" "}
                    <EpRight className="green-arrow-icon" />
                  </LeadGenCtaBtn>
                  <div className="flex flex-col justify-start text-left"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-2/5 hidden xl:block"></div>
          <div className="w-full lg:w-1/2">
            <div className="rounded-lg">
              <div>
                <Image
                  src="https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/Student/studyabroad-student.png"
                  alt="Banner Image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
