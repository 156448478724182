import { Description } from "@mui/icons-material";
import { title } from "process";
import React from "react";
import { SVGProps } from "react";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";

const eligibilityCriteriaData = {
  title: "Eligibility Criteria for PG Courses",
  description:
    "Candidates can check the official website of the institute for the eligibility criteria of the desired course in Mechanical Engineering. The eligibility criteria may differ slightly depending on the institute, but here are some common eligibility requirements asked by every institute for admissions.",
  listItems: [
    "Candidates must have completed a B.E. or B.Tech degree course in Mechanical, Automobile, Manufacturing, Production, Mechatronics or other related fields of engineering.",
    "Candidates must have secured a minimum aggregate of 55 per cent in the final qualifying exam.",
  ],
};
const topEntranceExamsPGCourses = {
  title: "Top Entrance Exams for PG Courses",
  description:
    "GPAT: GPAT stands for Graduate Aptitude Test in Engineering and it is a postgraduate entrance examination in the field of Engineering, conducted by one of the IITs to provide admission to different IITs, NITs, and GFTIs. Students can find the relevant information for the GPAT Examination in the table below:",
  degree: {
    details: [
      {
        title: "GPAT",
        content: "GPAT Application form",
        url: "https://gate2024.iisc.ac.in/",
      },
      {
        title: "GPAT Exam Pattern",
        content: "GPAT Syllabus",
        url: "https://gate2024.iisc.ac.in/",
      },
      {
        title: "GPAT Mock Test",
        content: "GPAT Preparation Tips",
        url: "https://gate2024.iisc.ac.in/",
      },
      {
        title: "GPAT Cutoff",
        content: "GPAT Result",
        url: "https://gate2024.iisc.ac.in/",
      },
    ],
  },
};

const EligibilityCriteriaTable = ({ data }: { data: any }) => {
  const combinedDetails = data.degree.details;
  const examPoints = data.points || [];

  return (
    <table className="w-full border-collapse border-[#383838] border">
      <tbody>
        {combinedDetails.map((detail: any, index: number) => (
          <tr
            key={index}
            className={`border-[#383838] border ${
              index % 2 === 0 ? "bg-[#EEF2FF]" : "bg-[#fff]"
            }`}
          >
            <td className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]">
              <a href="/exam-details">{detail.title}</a>
            </td>
            <td className="py-2.5 px-5 text-base text-[#4C495C]">
              <a href="/exam-details">
                {Array.isArray(detail.content)
                  ? detail.content.join(", ")
                  : typeof detail.content === "object"
                  ? Object.entries(detail.content)
                      .map(([key, value]) => `${key}: ${value}`)
                      .join("\n")
                  : detail.content}
              </a>
            </td>
          </tr>
        ))}

        {/* Adding the exams from the 'points' array */}
        {examPoints.map((exam: string, index: number) => (
          <tr
            key={`exam-${index}`}
            className={`border-[#383838] border ${
              (index + combinedDetails.length) % 2 === 0
                ? "bg-[#EEF2FF]"
                : "bg-[#fff]"
            }`}
          >
            <td className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C] ">
              {exam}
            </td>
            <td className="py-2.5 px-5 text-base text-[#4C495C]">-</td>
          </tr>
        ))}
        {/* For time being commenting this read more button  */}
        {/* <div className="flex flex-col gap-6 items-end">
        <button className="text-base text-white bg-[--primary-color] py-2 px-4 flex rounded items-center justify-center gap-1 w-36">
          Read more
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="26"
            viewBox="0 0 25 26"
            fill="none"
          >
            <path
              d="M7.2915 11.2773L12.4998 16.4857L17.7082 11.2773"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div> */}
      </tbody>
    </table>
  );
};

const EligibilityCriteriaPGCourses = () => {
  const { data } = useCourseDetails();
  const { eligibility_criteria } = data || {};
  const topEntranceExams =
    eligibility_criteria?.postgraduate?.top_entrance_exams;

  return (
    <>
      <div className="flex flex-col gap-6 bg-[#fff] relative">
        <div className="flex flex-col">
          <div className="flex max-md:w-full">
            <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
              {eligibilityCriteriaData.title}
            </h3>
          </div>
          <div className="flex flex-col gap-6 px-2 py-8 md:py-5 md:px-9">
            <p className="text-base text-[#4C495C] leading-7">
              {eligibility_criteria.description}
            </p>
            <ul className="list-decimal list-inside">
              {eligibilityCriteriaData.listItems.map(
                (item: string, index: number) => (
                  <li
                    key={index}
                    className="text-base text-[#4C495C] leading-7"
                  >
                    {item}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>

      <div className="flex flex-col relative">
        <div className="flex flex-col">
          <div className="flex max-md:w-full">
            <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
              {topEntranceExamsPGCourses.title}
            </h3>
          </div>
          <div className="flex flex-col gap-6 px-2 py-8 md:py-5 md:px-9">
            <p className="text-base text-[#4C495C] leading-7">
              {topEntranceExams?.description}
            </p>
            <EligibilityCriteriaTable
              data={{
                degree: topEntranceExamsPGCourses.degree,
                points: topEntranceExams?.points,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EligibilityCriteriaPGCourses;
