import React from "react";
import AccordionItem from "./components/AccordianItem";
import companyLogo from "../../images/cm-logo-white.png";
import locationIcon from "../../images/footer/location.png";
import phoneIcon from "../../images/footer/phone.png";
import mainIcon from "../../images/footer/mail.png";
import youtubeIcon from "../../images/footer/youtube.png";
import twitterIcon from "../../images/footer/twitter.png";
import linkedinIcon from "../../images/footer/linkedin.png";
import instagramIcon from "../../images/footer/instagram.png";
import facebookIcon from "../../images/footer/facebook.png";
import { FooterLinks, ImportantLinks } from "./Json/FooterJson";

const GlobalFooter = () => {
  // Links with URLs for each section
  const trendingLinks = [
    { text: "BITS Pilani", url: "https://example.com/trending1" },
    { text: "Manipal University", url: "https://example.com/trending2" },
    { text: "Amrita University", url: "https://example.com/trending3" },
    { text: "Sastra University", url: "https://example.com/trending4" },
    { text: "VIT University", url: "https://example.com/trending5" },
    { text: "PES University", url: "https://example.com/trending4" },
    { text: "Srm University", url: "https://example.com/trending5" },
    { text: "Thapar University", url: "https://example.com/trending5" },
  ];

  const mostViewedLinks = [
    { text: "Most Viewed Link 1", url: "https://example.com/viewed1" },
    { text: "Most Viewed Link 2", url: "https://example.com/viewed2" },
    { text: "Most Viewed Link 3", url: "https://example.com/viewed3" },
    { text: "Most Viewed Link 4", url: "https://example.com/viewed4" },
    { text: "Most Viewed Link 5", url: "https://example.com/viewed5" },
  ];
  return (
    <footer className="bg-[#173CBA] text-white">
      <div className="container flex flex-col gap-4 p-6 max-w-screen-2xl">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
          {FooterLinks.map((column, index) => (
            <div key={index}>
              <h4 className="mb-4 text-lg font-semibold">{column.title}</h4>
              <ul className="space-y-2">
                {column.links.map((link, linkIndex) => (
                  <li key={linkIndex} className="list-none">
                    <a
                      href={link.url}
                      className="text-xs text-white transition-opacity duration-200 opacity-70 hover:opacity-100"
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5">
          {ImportantLinks.map((column, index) => (
            <div key={index}>
              <h4 className="mb-4 text-lg font-semibold">{column.title}</h4>
              <ul className="space-y-2">
                {column.links.map((link, linkIndex) => (
                  <li key={linkIndex} className="list-none">
                    <a
                      href={link.url}
                      className="text-xs text-white transition-opacity duration-200 opacity-70 hover:opacity-100"
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div> */}

        <div>
          <AccordionItem
            title="Explore University Applications for 2025"
            links={trendingLinks}
          />
        </div>

        <div className="flex flex-col justify-between p-4 md:items-center md:flex-row xs:items-baseline">
          <div className="flex items-center mb-4 md:mb-0">
            <img src={companyLogo} alt="Logo" className="w-auto h-10" />
          </div>

          <div className="flex items-center space-x-4 text-right">
            <h2 className="text-md">Connect with us</h2>

            <div className="flex mt-2 space-x-4 md:mt-0">
              <a href="https://www.youtube.com/">
                <img
                  loading="lazy"
                  src={youtubeIcon}
                  alt="youtube icon"
                  className="shrink-0 aspect-[1.43] fill-white w-[23px]"
                />
              </a>
              <a href="https://twitter.com/">
                <img
                  loading="lazy"
                  src={twitterIcon}
                  alt="twitter icon"
                  className="w-4 shrink-0 aspect-square"
                />
              </a>
              <a href="https://in.linkedin.com/">
                <img
                  loading="lazy"
                  src={linkedinIcon}
                  alt="linkedin icon"
                  className="w-4 shrink-0 aspect-square"
                />
              </a>
              <a href="https://www.facebook.com/">
                <img
                  loading="lazy"
                  src={facebookIcon}
                  alt="facebook icon"
                  className="w-4 shrink-0 aspect-square"
                />
              </a>
              <a href="https://www.instagram.com/">
                <img
                  loading="lazy"
                  src={instagramIcon}
                  alt="instagram icon"
                  className="w-4 shrink-0 aspect-square fill-white"
                />
              </a>
            </div>
          </div>
          <div className="flex items-center">
            <p className="text-sm">7997166666</p>{" "}
          </div>

          <div className="flex items-center">
            <p className="text-sm">admin@collegementor.com</p>{" "}
          </div>
        </div>

        <div className="flex flex-col items-center justify-between pt-4 border-t border-gray-500 sm:flex-row">
          <div className="mb-4 space-x-4 sm:mb-0">
            <a href="#" className="hover:underline">
              About Us
            </a>{" "}
            <a href="#" className="hover:underline">
              Contact Us
            </a>
            <a href="#" className="hover:underline">
              Privacy Policy
            </a>
            <a href="/careers-dd" className="hover:underline">
              Careers{" "}
            </a>
            <a href="#" className="hover:underline">
              Terms & Conditions
            </a>
          </div>

          <div className="text-center sm:text-right">
            <p>© 2024 collegementor.com. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default GlobalFooter;
