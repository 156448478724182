import React, { useState, useEffect, useRef } from "react";
import Loader from "../../Loader/Loader";
import config from "../../../util/config";
import OverviewTab from "../../../components/college-details/components/OverviewTab";
import RankingsTab from "./RankingsTab";
import CoursesFeesTab from "./CoursesFeesTab";
import AdmissionsTab from "./AdmissionsTab";
import CutoffTab from "./CutoffsTab";
import CampusFacilitiesTab from "./CampusFacilitiesTab";
import CollegeLifeTab from "./CampusLifeTab";
import CMResourcesTab from "./CMResourcesTab";
import PlacementsTab from "./PlacementsTab";
import ResearchTab from "./ResearchTab";
import { useParams } from "react-router-dom";

import type { SVGProps } from "react";

export function EpRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path
        fill="currentColor"
        d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312z"
      ></path>
    </svg>
  );
}
interface Tab {
  title: string;
  name: string;
  orderIndex: number;
  subTabs?: Tab[];
}

const NavigationTabs = ({ tabs }: { tabs: Tab[] }) => {
  const { collegeName } = useParams<{ collegeName: string }>();
  const [activeTab, setActiveTab] = useState<string>(tabs[0]?.name || "");
  const [tabContent, setTabContent] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const tabContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hashTab = window.location.hash.replace("#", "");
    if (hashTab && tabs.some((tab) => tab.name === hashTab)) {
      setActiveTab(hashTab);
    } else if (tabs.length > 0) {
      const defaultTab = tabs.find((tab) => tab.name === "overview") || tabs[0];
      setActiveTab(defaultTab.name);
    }
    scrollToFirstTab();
  }, [tabs]);

  useEffect(() => {
    if (activeTab && collegeName) {
      fetchTabContent(activeTab);
    }
  }, [activeTab, collegeName]);

  useEffect(() => {
    const checkScrollable = () => {
      const tabContainer = tabContainerRef.current;
      if (tabContainer) {
        setIsScrollable(tabContainer.scrollWidth > tabContainer.clientWidth);
      }
    };

    checkScrollable();
    window.addEventListener("resize", checkScrollable);
    return () => window.removeEventListener("resize", checkScrollable);
  }, [tabs]);

  const fetchTabContent = async (tabName: string) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${config.apiUrl}/api/college/college/collegeDetails/${collegeName}/${tabName}`,
        { method: "GET", headers: { Accept: "*/*" } }
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch content for ${tabName}`);
      }

      const data = await response.json();
      setTabContent(data?.data?.details || null);
    } catch (error) {
      console.error(`Error fetching content for ${tabName}:`, error);
      setTabContent(null); // Ensure tabContent is set to null in case of error
    } finally {
      setLoading(false);
    }
  };

  const scrollToFirstTab = () => {
    const tabContainer = tabContainerRef.current;
    if (tabContainer) {
      tabContainer.scrollTo({ left: 0, behavior: "smooth" });
    }
  };

  const handleScrollByAmount = (direction: "left" | "right") => {
    const tabContainer = tabContainerRef.current;
    if (tabContainer) {
      const scrollAmount = tabContainer.clientWidth / 2;
      const newScrollLeft =
        direction === "left"
          ? tabContainer.scrollLeft - scrollAmount
          : tabContainer.scrollLeft + scrollAmount;

      tabContainer.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  const handleTabChange = (tabName: string) => {
    setActiveTab(tabName);
    window.location.hash = `#${tabName}`;
  };

  const renderContent = () => {
    if (loading) {
      return <Loader />;
    }

    if (!tabContent || Object.keys(tabContent).length === 0) {
      return (
        <p className="text-center text-red-500 py-10">
          Data Not Available for this tab.
        </p>
      );
    }

    switch (activeTab) {
      case "overview":
        return <OverviewTab collegeDetails={{ details: tabContent }} />;
      case "rankings":
        return <RankingsTab rankings={tabContent.rankings || {}} />;
      case "courses-&-fees":
        return <CoursesFeesTab CoursesFees={tabContent.CoursesFees || {}} />;
      case "admissions":
        return (
          <AdmissionsTab
            admissionsDetails={tabContent.admissionsDetails || {}}
          />
        );
      case "cut-off":
        return <CutoffTab cutoffData={tabContent.cutoffDetails || {}} />;
      case "placements":
        return <PlacementsTab placementData={tabContent.placementData || {}} />;
      case "campus-facilities":
        return (
          <CampusFacilitiesTab
            facilitiesData={tabContent.facilitiesData || {}}
          />
        );
      case "research":
        return <ResearchTab data={tabContent || {}} />;
      case "college-life":
        return (
          <CollegeLifeTab collegeLifeData={tabContent.collegeLifeData || {}} />
        );
      case "cm-resources":
        return (
          <CMResourcesTab resourcesData={tabContent.cmResourceData || {}} />
        );
      default:
        return (
          <p className="text-center text-red-500 py-10">
            Data Not Available for this tab.
          </p>
        );
    }
  };

  return (
    <div>
      <div
        className={`transition-opacity duration-500 ${
          isSticky
            ? "fixed top-0 left-0 w-full opacity-100 z-50 shadow-lg"
            : "opacity-100"
        }`}
      >
        <ul
          className="flex items-center justify-between space-x-4 px-4 py-2 overflow-x-auto"
          style={{
            background: "linear-gradient(180deg, #173CBA 0%, #06AFA9 100%)",
          }}
        >
          {isScrollable && (
            <button
              className="bg-[#00CC99] border border-white rounded text-white py-3 px-2"
              onClick={() => handleScrollByAmount("left")}
            >
              <span className="material-icons">
                <EpRight className="rotate-180" />
              </span>
            </button>
          )}

          <div
            id="tabContainer"
            ref={tabContainerRef}
            className="overflow-x-auto scrollbar-hide w-full mx-auto"
          >
            <ul className="list-none flex gap-4 items-center text-center w-full mx-auto xl:justify-center">
              {tabs.map((tab) => (
                <li
                  key={tab.orderIndex}
                  className={`text-base py-1.5 px-3.5 font-semibold rounded-sm cursor-pointer whitespace-nowrap ${
                    activeTab === tab.name
                      ? "bg-green text-white"
                      : "bg-white text-black hover:bg-green hover:text-white"
                  }`}
                  onClick={() => handleTabChange(tab.name)}
                >
                  {tab.title}
                </li>
              ))}
            </ul>
          </div>

          {isScrollable && (
            <button
              className="bg-[#00CC99] border border-white rounded text-white py-2 px-2"
              onClick={() => handleScrollByAmount("right")}
            >
              <span className="material-icons">
                <EpRight />
              </span>
            </button>
          )}
        </ul>
      </div>
      <div className="w-full">{renderContent()}</div>
    </div>
  );
};

export default NavigationTabs;
