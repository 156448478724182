import React, { useState } from "react";
import BlogCard from "./BlogCard";
import WebinarCard from "./WebinarCard";
import VideoCardWithModal from "./VideoCardWithModal";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";

interface BlogsArticlesProps {
  exploreMore: ExploreMoreData;
}
interface SpotLight {
  image: string;
  pushlishDate: string;
  link: string;
  description: string;
  title: string;
}

interface VisualStory {
  id: number;
  title: string;
  description: string;
  stories: {
    id: number;
    type: "video" | "article" | "image";
    url: string;
    careerVisualStoryDataId?: number;
  }[];
}

interface Article {
  publishDate: string;
  description: string;
  title: string;
  slug: string;
  image: string;
}

interface Webinar {
  date: string;
  by: string;
  description: string;
  time: string;
  title: string;
  image1: string;
  name2: string;
  image2: string;
  name1: string;
}

interface ExploreMoreData {
  spotLight: SpotLight[];
  visualStories: VisualStory[];
  articles: Article[];
  webinar: Webinar[];
}

const BlogsArticles: React.FC<BlogsArticlesProps> = ({ exploreMore }) => {
  const [selectedCategory, setSelectedCategory] = useState("articles");
  const [selectedVideoLink, setSelectedVideoLink] = useState<string | null>(
    null
  );

  const { data } = useCourseDetails();
  const { blogsAndArticles } = data;
  const categories = ["articles"];
  //const categories = ["news", "spotLight", "articles", "webinar"];
  const selectedItems =
    selectedCategory === "spotLight"
      ? exploreMore.spotLight
      : selectedCategory === "webinar"
      ? exploreMore.webinar
      : selectedCategory === "articles"
      ? exploreMore.articles
      : [];

  const formattedArticles = exploreMore.articles.map((article: any) => ({
    title: article.title,
    description: article.description,
    image: article.image,
    publishDate: article.publishDate,
    slug: article.slug,
  }));

  const handleOpenVideoModal = (link: string) => {
    setSelectedVideoLink(link);
  };

  const handleCloseVideoModal = () => {
    setSelectedVideoLink(null);
  };

  return (
    <div className="flex flex-col gap-9 bg-white relative" id="blogs-articles">
      <div className="flex flex-col gap-2.5">
        <div className="flex max-md:w-full">
          <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
            Blogs & Articles
          </h3>
        </div>

        <div className="flex flex-col gap-0 px-2 py-8 md:py-5 md:px-9">
          {/* Category Tabs */}
          <div className="flex flex-row gap-4 lg:gap-10 justify-center">
            {categories.map((category, index) => (
              <button
                key={index}
                onClick={() => setSelectedCategory(category)}
                className={`py-3 px-3 sm:px-5 text-base md:text-xl transition-all duration-300 ease-in-out rounded-t-md
                  ${
                    selectedCategory === category
                      ? "border-yellow-200 bg-[#FFEFB5] text-gray-800 font-bold"
                      : "text-gray-500"
                  }`}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
          </div>

          {/* Render Cards */}
          {selectedCategory === "articles" && formattedArticles.length > 0 && (
            <div className="bg-[#FFEFB5] pb-6">
              <BlogCard items={formattedArticles} />
            </div>
          )}
          {/*  {selectedItems.length > 0 && (
            <div className="bg-[#FFEFB5]">
              {selectedCategory === "webinar" ? (
                <WebinarCard
                  items={selectedItems}
                  handlePopup={handleOpenVideoModal}
                />
              ) : selectedCategory === "spotLight" ? (
                <VideoCardWithModal items={selectedItems} />
              ) : (
                <BlogCard items={selectedItems} />
              )}
            </div>
          )} */}
        </div>
      </div>

      {/* Video Modal */}
      {selectedVideoLink && (
        <div className="fixed top-0 left-0 h-screen w-screen flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-5 rounded-lg max-w-xl w-full relative">
            <button
              className="absolute top-2 right-2"
              onClick={handleCloseVideoModal}
            >
              &times;
            </button>
            <iframe
              width="100%"
              height="400"
              src={selectedVideoLink}
              title="Video"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogsArticles;
