import "./CareerDetailsStyles.scss";

const KnowledgeAndSkills: React.FC<{ knowledgeSkills: any }> = ({
  knowledgeSkills,
}) => {
  if (!knowledgeSkills) return null;

  return (
    <section>
      <div className="container mx-auto">
        <div className="bg-white relative">
          <div className="flex flex-col lg:flex-row justify-between md:pr-8 gap-6">
            <div className="flex flex-col w-full lg:w-4/5">
              <div className="flex max-md:flex-col max-md:w-full">
                <h3 className="text-xl max-md:text-center lg:text-2xl font-semibold text-white bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0">
                  {knowledgeSkills.title}
                </h3>
              </div>
              <div
                className="text-black text-base leading-7 flex flex-col gap-5 py-5 px-8 salaryDetailsDescription"
                dangerouslySetInnerHTML={{
                  __html: knowledgeSkills.description as string,
                }}
              ></div>
            </div>
            <div className="flex flex-col items-center justify-center w-full lg:w-1/5 relative bg-[#FFF1FA] max-xl:hidden">
              <div
                className="education-loan"
                style={{
                  background:
                    "linear-gradient(#FFFFFF, #F4FBFE, #D7F0FB, #B7E4F7, #FFFFFF);",
                }}
              >
                <div className="relative ">
                  <img
                    src="/images/course-details/education-finance.png"
                    alt=""
                    className="absolute top-[15px] left-[25px] z-10"
                  />
                  <img
                    src="/images/course-details/education-loan.png"
                    alt=""
                    className="z-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KnowledgeAndSkills;
