import React from "react";

interface MBAAdmissionsInfoProps {
  title: string;
  description: string;
}

const MBAAdmissionsInfo: React.FC<MBAAdmissionsInfoProps> = ({
  title,
  description,
}) => (
  <section className="mba-admissions-info">
    <h4 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
      {title}
    </h4>
    <div
      className="text-base leading-relaxed p-3 flex flex-col gap-4 collegeHighlights"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  </section>
);

export default MBAAdmissionsInfo;
