import React from "react";

interface FacilitySectionProps {
  title: string;
  description: string;
  index: number;
  image?: string;
  tabType: "campusFacilities" | "collegeLife";
}

const FacilitySection: React.FC<FacilitySectionProps> = ({
  title,
  description,
  index,
  image,
  tabType,
}) => {
  const isEven = index % 2 === 0;
  const bgColor =
    tabType === "campusFacilities"
      ? isEven
        ? "bg-[#D9F6EF]"
        : "bg-[#FFF9E5]"
      : isEven
      ? "bg-[#FFF0FA]"
      : "bg-[#FFF9E5]";

  return (
    <div className={`py-10 ${bgColor}`}>
      <div className="container mx-auto">
        <div
          className={`flex ${
            image
              ? isEven
                ? "lg:flex-row"
                : "lg:flex-row-reverse"
              : "lg:flex-row"
          } flex-col gap-10`}
        >
          <div className={`w-full ${image ? "lg:w-1/2" : "lg:w-full"}`}>
            <h3 className="text-2xl font-bold text-blue mb-4">{title}</h3>
            <div
              className="text-black leading-relaxed CampusFacilitiesList"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          {image && (
            <div className="w-full lg:w-1/2">
              <img src={image} alt={title} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FacilitySection;
