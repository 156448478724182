import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate

const countryIds: { [key: string]: string } = {
  CA: "CANADA",
  GB: "UK",
  US: "USA",
  Au: "AUSTRALIA",
  DE: "GERMANY",
  AE: "UAE",
};

const StudyAbroadOptions = ({
  animationCheckPointRef,
}: {
  animationCheckPointRef: (el: HTMLDivElement | null) => void;
}) => {
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const navigate = useNavigate(); // Initialize useNavigate

  const countries = [
    { name: "CANADA", imageSrc: "/images/canada.png" },
    { name: "UK", imageSrc: "/images/uk.png" },
    { name: "USA", imageSrc: "/images/usa.png" },
    { name: "AUSTRALIA", imageSrc: "/images/australia.png" },
    { name: "GERMANY", imageSrc: "/images/germany.png" },
    { name: "UAE", imageSrc: "/images/uae.png" },
  ];

  const handleMapLoad = (event: React.SyntheticEvent<HTMLObjectElement>) => {
    const svgDoc = (event.target as HTMLObjectElement).contentDocument;
    if (svgDoc) {
      Object.keys(countryIds).forEach((countryId) => {
        const countryElement = svgDoc.getElementById(countryId);
        if (countryElement) {
          countryElement.addEventListener("click", () => {
            setSelectedCountry(countryIds[countryId]);
          });
        }
      });
    }
  };

  return (
    <div className="flex flex-col mb-6 pt-6 items-center">
      <div className="flex md:justify-center items-center max-md:container relative text-center">
        <div
          className="absolute -left-24 top-1/2 -translate-y-1/2 h-16 w-16"
          ref={animationCheckPointRef}
        ></div>
        <h3 className="text-black text-3xl font-extrabold ">
          Study Abroad Options
        </h3>
      </div>

      {selectedCountry && (
        <div className="text-center mt-6">
          <h4 className="text-xl font-bold text-blue-600">
            Selected Country: {selectedCountry}
          </h4>
        </div>
      )}

      <div className="mt-14 w-full max-md:mt-10 max-md:max-w-full min-max-md:w-full min-max-lg:w-full ">
        <div className="flex gap-5 max-md:flex-col container min-max-md:flex-col">
          <div className="flex flex-col w-[65%] max-md:ml-0 max-md:w-full cursor-pointer min-max-md:w-full min-max-lg:[50%] ">
            <object
              type="image/svg+xml"
              data="/images/world.svg"
              className="w-full max-w-4xl h-auto object-contain"
              aria-label="World map"
              onLoad={handleMapLoad}
            ></object>
          </div>

          <div className="flex flex-col ml-5 w-[35%] max-md:ml-0 max-md:w-full min-max-md:w-full min-max-lg:w-[50%]">
            <div className="self-stretch my-auto max-md:mx-auto min-max-md:mx-auto">
              <div className="flex gap-5 max-md:flex-row max-md:gap-3">
                {[0, 1, 2].map((columnIndex) => (
                  <div
                    key={columnIndex}
                    className="flex flex-col gap-8 max-md:ml-0 max-md:w-full"
                  >
                    <div className="flex flex-col grow max-md:mt-6 gap-4">
                      {countries
                        .slice(columnIndex * 2, columnIndex * 2 + 2)
                        .map((country) => (
                          <div
                            key={country.name}
                            className="w-[123px] cursor-pointer max-md:w-full"
                          >
                            <Link to={`/study-abroad?country=${country.name}`}>
                              <div
                                key={country.name}
                                className="w-[123px] cursor-pointer max-md:w-full"
                              >
                                <div className="flex flex-col justify-center items-center bg-[#D9D9D9] max-md:w-full  h-[123px] w-[123px] max-md:px-5">
                                  <div className="rounded-full">
                                    <img
                                      loading="lazy"
                                      src={country.imageSrc}
                                      alt={`${country.name} flag`}
                                      className="object-contain aspect-square w-[70px] max-md:w-full"
                                    />
                                  </div>
                                </div>
                                <div className="self-center text-sm leading-8 text-center text-black">
                                  {country.name}
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyAbroadOptions;
