import React, { useState, useRef, useEffect } from "react";
import EmiCalculator from "./EmiCalculator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faIndianRupeeSign,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import LeadGenCtaBtn from "../../../../features/lead-generation/LeadGenCtaBtn";

interface TabContentProps {
  activeTab: number;
}
interface Tab {
  label: string;
}
const TabContent: React.FC<TabContentProps> = ({ activeTab }) => {
  const headPara = {
    headings: [
      "College Mentor Education Loan Program",
      "Education Loans Have Different Interest Rates",
      "Get Up to 100% Financing",
      "Flexible Repayment Options",
      "Instant Student Loans",
      "Customizable Loans",
      "Doorstep Service",
    ],
    paragraphs: [
      "A College Mentor initiative in partnership with multiple banks and NBFCs, providing financial support for students admitted to higher educational institutions in India and abroad. We’re here to assist you in securing the most favorable interest rates for your education loans.",
      "Rates vary by loan type, lender, and your financial profile. Compare options to find the best rate.",
      "We offer comprehensive education financing solutions that cover the full cost of your education.",
      "Choose how and when to repay your loan with options tailored to your unique financial needs.",
      "Apply for a fast-track student loan and receive funds within 3 working days!",
      "Our education financing solutions are designed to fit your specific financial requirements.",
      "Need help with your student loan? Apply online, and we’ll send an executive to assist with the paperwork.",
    ],
  };

  return (
    <div className="mt-4">
      {activeTab === 0 && (
        <div className="flex max-md:flex-col">
          <div className="md:w-2/5 p-4">
            <h2 className="text-[#173CBA] font-roboto text-3xl font-medium leading-[50px]">
              College Mentor<br></br> Education Loan Program
            </h2>
            <img
              src="\images\scholarship\landing\girl-with-mobile-notifications.png"
              alt="Image"
              className="mb-4"
            />
          </div>
          <div className="md:w-3/5 p-4">
            {headPara.headings.map((heading, index) => (
              <div key={index}>
                <div className="text-black font-roboto text-[14px] font-semibold leading-[36px]">
                  {heading}
                </div>
                <div className="text-black font-roboto text-[12px] leading-[24px]">
                  {headPara.paragraphs[index]}
                </div>
              </div>
            ))}
            <div className="flex gap-4 items-center mt-4">
              {/* <div className="bg-green rounded-md overflow-hidden pr-2">
                <select className="bg-green text-white p-2">
                  <option>2 Lakh</option>
                  <option>5 Lakhs</option>
                  <option>10 Lakhs</option>
                  <option>15+ Lakhs</option>
                </select>
              </div>
              <div className="bg-green rounded-md overflow-hidden pr-2">
                <select className="bg-green text-white p-2">
                  <option>B.tech</option>
                  <option>M.tech</option>
                  <option>Diploma</option>
                  <option>PhD</option>
                </select>
              </div> */}
              <LeadGenCtaBtn
                pageName="scholarship-landing"
                ctaName="calculate-and-submit"
                className="bg-green text-white font-bold p-2 rounded-md flex gap-4 items-center"
              >
                Search Loan <FontAwesomeIcon icon={faArrowRight} />
              </LeadGenCtaBtn>
            </div>
          </div>
        </div>
      )}
      {activeTab === 1 && (
        <div className="flex flex-col text-base">
          <div className="flex max-md:flex-col">
            <div className="md:w-1/3 p-4">
              <h2 className="text-4xl font-medium">
                Education Loan EMI Calculator
              </h2>
            </div>
            <div className="md:w-2/3 p-4">
              <p className="font-bold">Education Loan EMI Calculator</p>
              <p className="mt-2">
                Quickly estimate your monthly loan payments with our easy-to-use
                calculator. Enter your loan amount, interest rate, and term to
                see what your EMI will be. Plan your finances better with
                accurate payment projections.
              </p>
              <p className="font-bold mt-4">Estimate Your Education Loan EMI</p>
              <p className="mt-2">
                Provide a few details, and we’ll help you calculate your EMI.
              </p>
            </div>
          </div>
          <div className="w-full md:w-4/5 mx-auto">
            <EmiCalculator />
          </div>
          <div className="flex max-md:flex-col">
            <div className="md:w-1/3 p-4">
              <p className="mt-8">
                <strong>Disclaimer</strong> : The aforementioned values,
                calculations and results are for illustrative and informational
                purposes only and may vary basis various parameters laid down by
                Official Banks
              </p>
            </div>
            <div className="md:w-2/3 p-4">
              <p className="font-bold">
                How to calculate EMI for an education loan.
              </p>
              <p className="mt-2">
                At College Mentor, we use the following mathematical formula to
                calculate your EMI:
              </p>
              <p className="font-bold mt-4">
                {"EMI = [L x I x (1 + I)N] / [(1 + I)N-1]"}
              </p>
              <p className="mt-2">
                L stands for the principal amount, which is your actual loan
                amount. I refers to the rate of interest and N is your tenure in
                months. Let’s assume you have borrowed INR 5 lakhs at the
                interest rate of 12%. You have opted to repay the loan in 2
                years, which is 24 months. Your estimated EMI works out to
                RS:26,536 per month
              </p>
            </div>
          </div>
        </div>
      )}
      {activeTab === 2 && (
        <div className="flex max-md:flex-col md:items-center text-base">
          <div className="md:w-1/2 p-4">
            <h2 className="text-4xl font-medium">
              What Is a College Course Expense Calculator
            </h2>
            <p className="font-bold mt-4">
              What Is a College Course Expense Calculator?
            </p>
            <p className="mt-2">
              A College Course Expense Calculator helps estimate your total
              college costs to determine how much to borrow. Here’s how:
            </p>
            <p className="mt-4">
              <strong>Provide Financial Details</strong> : Input your current
              finances.
            </p>
            <p>
              <strong>Enter Course Costs</strong> : Include tuition, living
              expenses, textbooks, and devices.
            </p>
            <p>
              <strong>Calculate Total Expenses</strong> : Get an estimate of
              overall costs.
            </p>
            <p>
              <strong>Determine Loan Needs</strong> : Use the estimate to decide
              your borrowing amount.
            </p>
            <p className="mt-8">
              <strong>Disclaimer</strong> : The aforementioned values,
              calculations and results are for illustrative and informational
              purposes only and may vary basis various parameters laid down by
              Official Banks
            </p>
          </div>
          <div className="md:w-1/2 p-4">
            <div className="border border-grey-4 rounded-lg shadow-lg p-8">
              <h3 className="text-2xl font-semibold">Note:</h3>
              <p>
                Use the course expense calculator to estimate the total amount
                you may need to borrow for the entire duration of your course
              </p>
              <strong className="mt-6">
                Complete the fields below using total figures
              </strong>
              <div className="flex flex-col gap-4">
                {[
                  "Personal Savings",
                  "Help From Friends/Relatives",
                  "Scholarship Approved",
                  "Grants Approved",
                  "Family Savings",
                ].map((item) => (
                  <div className="border-b border-grey-4 flex items-center gap-4 py-2">
                    <FontAwesomeIcon
                      icon={faIndianRupeeSign}
                      className="text-grey"
                      stroke="1px"
                    />
                    <input type="text" placeholder={item} />
                  </div>
                ))}
                <LeadGenCtaBtn
                  pageName="scholarship-landing"
                  ctaName="calculate-and-submit"
                  className="w-fit self-center rounded-full px-6 py-1 text-white bg-green"
                >
                  Submit
                </LeadGenCtaBtn>
              </div>
            </div>
          </div>
        </div>
      )}
      {activeTab === 3 && (
        <div className="flex max-md:flex-col md:items-center text-base">
          <div className="md:w-1/2 p-4">
            <h2 className="text-4xl font-medium">
              What Is the Study Loan Eligibility Criteria?
            </h2>
            <p className="font-bold mt-4">
              What Is the Study Loan Eligibility Criteria?
            </p>
            <p className="mt-2">
              To secure a study loan for higher education in India or abroad,
              you must meet the following criteria:
            </p>
            <p className="font-bold mt-4">For Students</p>
            <ul className="ml-6 mt-4">
              <li>Be an Indian citizen aged 18 or older</li>
              <li>Have confirmed admission before final loan disbursal</li>
              <li>Have a co-borrower with income in India</li>
            </ul>
            <p className="font-bold mt-4">For Co-borrowers</p>
            <ul className="ml-6 mt-4">
              <li>
                Must be an Indian citizen and a parent, legal guardian, sibling,
                or close blood relative
              </li>
              <li>Must have a bank account in India for signing cheques</li>
              <li>Must be the primary debtor</li>
              <li>
                Ensure you meet these requirements before applying for your
                education loan
              </li>
            </ul>
            <p className="mt-8">
              <strong>Disclaimer</strong> : The aforementioned values,
              calculations and results are for illustrative and informational
              purposes only and may vary basis various parameters laid down by
              Official Banks
            </p>
          </div>
          <div className="md:w-1/2 p-4">
            <div className="border border-grey-4 rounded-lg shadow-lg p-8">
              <h3 className="text-2xl font-semibold">Eligibility Calculator</h3>
              <div className="flex flex-col gap-4">
                {[
                  "Monthly Income",
                  "Monthly Commitments",
                  "Loan interest rate",
                  "Tenure in Months",
                ].map((item) => (
                  <div className="border-b border-grey-4 flex items-center gap-4 py-2">
                    <FontAwesomeIcon
                      icon={faIndianRupeeSign}
                      className="text-grey"
                      stroke="1px"
                    />
                    <input type="text" placeholder={item} />
                  </div>
                ))}
                <LeadGenCtaBtn
                  pageName="scholarship-landing"
                  ctaName="calculate-and-submit"
                  className="w-fit self-center rounded-full px-6 py-1 text-white bg-green"
                >
                  Submit
                </LeadGenCtaBtn>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const tabsRef = useRef<HTMLDivElement>(null);
  const tabs: Tab[] = [
    { label: "Education Bank Loan" },
    { label: "Education Loan Calculator" },
    { label: "College Course Expense Calculator" },
    { label: "Eligibility Calculator " },
  ];
  useEffect(() => {
    if (new URLSearchParams(location.search).get("scrollToTabs") === "true") {
      tabsRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);
  return (
    <>
      <div className="w-full bg-[#00CC99] p-4 ">
        <div className="pl-10 flex mb-4 py-4 " ref={tabsRef}>
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={` px-4 border-r border-white last:border-r-0 ${
                activeTab === index ? "bg-blue-500 text-white" : " text-white"
              }`}
              onClick={() => setActiveTab(index)}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div className="bg-white mx-12 mb-12">
          <TabContent activeTab={activeTab} />
        </div>
      </div>
    </>
  );
};

export default Tabs;
