import React, { useState } from "react";
import "./DreamColleges.scss";

import "animate.css";
import { Link } from "react-router-dom";
import { Card } from "../../../../components/card/Card";

const dreamCollegesData = [
  {
    id: 1484,
    image: "/images/dream-colleges/SRM-01.jpg",
    title: "SRM University ",
    linktag: "srm-university",
  },
  {
    id: 1485,
    image: "/images/dream-colleges/Amrita-University3.jpg",
    title: "Amrita University",
    linktag: "amrita-university",
  },
  {
    id: 1486,
    image: "/images/dream-colleges/Aditya-University-03.jpg",
    title: " Aditya University",
    linktag: "aditya-university",
  },
  {
    id: 1487,
    image: "/images/dream-colleges/Bennett-University.jpg",
    title: "Bennett University",
    linktag: "bennett-university",
  },
  {
    id: 1488,
    image: "/images/dream-colleges/Manav-Rachna-02.jpg",
    title: " Manav Rachana University",
    linktag: "manav-rachana-university",
  },
  {
    image: "/images/dream-colleges/Sri-RamaChandra-University.jpg",
    title: "Sri RamaChandra University",
    linktag: "sri-ramachandra-university",
  },
  {
    image: "/images/dream-colleges/UPES-05.jpg",
    title: "UPES University",
    linktag: "upes-university",
  },
  {
    image: "/images/dream-colleges/Woxsen-University.jpg",
    title: " Woxsen University",
    linktag: "woxsen-university",
  },
];

export const DreamColleges = ({
  animationCheckPointRef,
}: {
  animationCheckPointRef: (el: HTMLDivElement | null) => void;
}) => {
  const [showAll, setShowAll] = useState(false);
  const [buttonText, setButtonText] = useState("View all colleges");
  return (
    <div className="dream-colleges-section py-[60px]">
      <div className="w-[1360px] mx-auto flex flex-col gap-10 max-md:w-full items-center min-max-md:w-full min-max-lg:w-full">
        <div className="flex justify-center items-center relative">
          <h3 className="text-black text-3xl font-extrabold">Dream Colleges</h3>
          <div
            className="absolute -left-24 top-1/2 -translate-y-1/2 h-16 w-16"
            ref={animationCheckPointRef}
          ></div>
        </div>

        <div
          className={`grid grid-cols-1 min-max-lg:grid-cols-2 xl:grid-cols-4 min-max-md:grid-cols-2 gap-[24px] mx-auto`}
        >
          {showAll
            ? dreamCollegesData.map((college) => {
                return <Card {...college} />;
              })
            : dreamCollegesData.slice(0, 8).map((college, index) => {
                return (
                  <div
                    key={index}
                    className={`${
                      index < 2
                        ? "flex flex-col w-full md:w-auto"
                        : "hidden md:block"
                    }`}
                  >
                    <Card {...college} />
                  </div>
                );
              })}
        </div>
      </div>
      <div className="flex items-center justify-center md:ml-[-50px]">
        <Link
          to={"/college-intermediate"}
          className={`text-base  py-3.5 px-[34px] white-color map-mentor  button-styles text-white font-semibold rounded-full flex flex-row items-center arrow-button justify-center gap-2`}
        >
          {buttonText}

          {/* You can customize the arrow icon here */}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="currentColor"
            className="arrow-icon"
          >
            <path
              d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
              fill="currentColor"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};
