import React from "react";

interface PlacementLatestUpdatesProps {
  title: string;
  description: string;
}

const PlacementLatestUpdates: React.FC<PlacementLatestUpdatesProps> = ({
  title,
  description,
}) => (
  <section className="placement-latest-updates">
    <h4 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
      {title}
    </h4>
    <div
      className="text-base leading-relaxed bg-white p-3 flex flex-col gap-4 collegeHighlights"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  </section>
);

export default PlacementLatestUpdates;
