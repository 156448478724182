import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import NavigationTabs from "../../components/exam-details/NavigationTabs";
import config from "../../util/config";
import "./exam-details-styles.scss";
import Banner from "../../components/exam-details/Banner";
import KnowledgeHub from "./KnowledgeHub";

export interface Tab {
  name: string;
  title: string;
}

interface Action {
  icon: string;
  text: string;
  link: string;
}

interface Button {
  text: string;
  link: string;
  type: "primary" | "secondary";
}

interface BannerData {
  examTitle: string;
  examDescription: string;
  examBanner: string;
  buttons: Button[];
}

const ExamDetails: React.FC = () => {
  const { examName } = useParams<{ examName: string }>();
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [bannerData, setBannerData] = useState<BannerData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchExamDetails = async () => {
      try {
        // Fetch the banner data
        const bannerResponse = await fetch(
          `${config.apiUrl}/api/exam-details/${examName}`,
          {
            method: "GET",
            headers: { Accept: "*/*" },
          }
        );

        if (!bannerResponse.ok) {
          throw new Error("Failed to fetch exam details");
        }

        const bannerData = await bannerResponse.json();
        setBannerData({
          examTitle: bannerData.examTitle,
          examDescription: bannerData.examDescription,
          examBanner: bannerData.examBanner,
          buttons: [
            { text: "Apply Now", link: "#apply", type: "primary" },
            {
              text: "Talk to Mentor",
              link: "#talk-to-mentor",
              type: "secondary",
            },
          ],
        });

        // Fetch the tab data
        const tabsResponse = await fetch(
          `${config.apiUrl}/api/exam-details/tabnames`,
          {
            method: "GET",
            headers: { Accept: "*/*" },
          }
        );

        if (!tabsResponse.ok) {
          throw new Error("Failed to fetch tab names");
        }

        const tabsData = await tabsResponse.json();
        setTabs(tabsData);
      } catch (err) {
        console.error("Error fetching exam details:", err);
        setError("Unable to load exam details.");
      } finally {
        setLoading(false);
      }
    };

    fetchExamDetails();
  }, [examName]);

  if (loading) {
    return <Loader />;
  }

  if (error || !bannerData || tabs.length === 0) {
    return (
      <div className="container mx-auto text-center py-10">
        <p className="text-red-500 font-bold">
          {error || "No data available."}
        </p>
      </div>
    );
  }

  return (
    <div>
      <Banner data={bannerData} />
      <NavigationTabs tabs={tabs} examName={examName || ""} />
      <KnowledgeHub />
    </div>
  );
};

export default ExamDetails;
