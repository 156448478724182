import { useState } from "react";
import { SVGProps } from "react";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";
import UniversityCard from "./UniversityCard";
import { Link } from "react-router-dom";

export function MingcuteRightLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="10px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
        ></path>
      </g>
    </svg>
  );
}

const DegreeTable = ({ data }: { data: any }) => {
  return (
    <div className="flex flex-col md:flex-row gap-6">
      <div className="w-full">
        <table className="w-full border-collapse border-[#383838] border">
          <tbody>
            <tr className="border-[#383838] border bg-[#EEF2FF] md:bg-[#EEF2FF]">
              <td className="border-[#383838] border py-2.5 px-5 text-lg text-[#4C495C] font-medium lg:w-52">
                Degree Name
              </td>
              <td className="py-2.5 px-5 text-lg text-[#4C495C] font-medium">
                {data.degree.name}
              </td>
            </tr>
            {data.degree.tableDataOne.map((detail: any, index: number) => (
              <tr
                key={index}
                className={`border-[#383838] border ${index % 2 === 0
                  ? "bg-[#fff] md:bg-[#fff]"
                  : "bg-[#EEF2FF] md:bg-[#EEF2FF]"
                  }`}
              >
                <td className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]">
                  {detail.title}
                </td>
                <td
                  className="py-2.5 px-5 text-base text-[#4C495C] whitespace-break-spaces"
                  dangerouslySetInnerHTML={{
                    __html: renderContentAsHtml(detail),
                  }}
                />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const renderContentAsHtml = (detail: any) => {
  if (detail.title === "Entrance Exams") {
    return detail.content
      .split(", ")
      .map(
        (exam: string) =>
          `<a href="/exam-details" class="hover:text-[#678CFF]">${exam}</a>`
      )
      .join(' <span class="text-pink px-[3.5px]">|</span> ');
  } else if (Array.isArray(detail.content)) {
    if (detail.title === "Job Profiles" || detail.title === "Top Recruiters") {
      return detail.content
        .map(
          (item: string, index: number) =>
            `<a href="${detail.title === "Job Profiles"
              ? `/careers/career-details/${item}`
              : "#"
            }" class="hover:text-[#678CFF]">${item}</a>`
        )
        .join(' <span class="text-pink px-[3.5px]">|</span> ');
    } else {
      return detail.content.join(", ");
    }
  } else if (typeof detail.content === "object") {
    return Object.entries(detail.content)
      .map(([key, value]) => `${value}`)
      .join("\n");
  } else {
    return detail.content;
  }
};

const HighlightsMecEng = () => {
  const { data } = useCourseDetails();
  const {
    courseTitle = "",
    highlights,
    highlight,
    careers_in,
    recruiting_companies,
    averageSalaries,
    eligibilityCriteria,
  } = data || {};


  const UniversityCardData = [
    {
      logo: "/images/course-details/Bennett_University_021.png ",
      name: "Bennett UniversityB.Tech Admissions 2024",
      description:
        "Scholarship Seats Application Deadline - 25th June 2024 Highest CTC 1.37 Crore Per Annum | Average CTC 7.99 LPA",
      time: "June 29, 2024 19:51 IST",
    },
    {
      logo: " /images/course-details/amity-university_21.png",
      name: "Amity University Ranked India's #1",
      description:
        "Not-for-profit Pvt. University for six years - India Today Ranked amongst TOP 3% Universities Globally- QS University Rankings",
      time: "June 29, 2024 19:51 IST",
    },
    {
      logo: "/images/course-details/LPU_logo_21.png ",
      name: "Lovely Professional University Admissions 2024",
      description:
        "Applications End Date: 30th Jun’24, 100% Placements Record | Highest Package Offered : 3 Cr PA",
      time: "June 29, 2024 19:51 IST",
    },
    {
      logo: " /images/course-details/UPES-logo_21.png",
      name: "Upes University B.Tech Admissions 2024",
      description:
        "Ranked #52 among universities in India by NIRF | Accredited Grade 'A' by NAAC Ranked #243 by QS Asia University Rankings 2023 | University of the year�(North India)",
      time: "June 29, 2024 19:51 IST",
    },
  ];

  return (
    <div id="highlights">
      <div className="block md:flex relative">
        <div className="flex flex-col bg-[#fff] w-full md:w-3/4">
          <div className="flex max-md:w-full">
            <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
              {`Highlights of ${courseTitle}`}
            </h3>
          </div>
          <div className="flex px-2 py-8 md:py-5 md:px-9">

            <div className="flex flex-col px-2 py-8 md:py-5 md:px-9 gap-6 blog-content"
              dangerouslySetInnerHTML={{ __html: highlights }} />
          </div>
        </div>
        <div className="application-forms-section w-full md:w-3/12 bg-[#FFF1FA] px-7 py-14 hidden md:flex flex-col">
          <div className="view-all-application-forms">
            <div className="view-all-applications-header bg-[#173CBA]">
              <h2 className="text-sm text-white text-center leading-7">
                View All Application Forms
              </h2>
            </div>
            <div className="view-all-applications-main bg-[#fff] p-2">
              {UniversityCardData.map((card) => {
                return <UniversityCard {...card} />;
              })}
            </div>

            <div className="view-all-applications-button bg-[#0C9] h-8 text-center">
              <Link
                to={"/application-forms"}
                className="flex mx-auto  text-white py-1 text-sm px-3 bg-[#173CBA] rounded-full  group w-[228px] md:hidden max-md:hidden"
              >
                <span className="mr-2">View All application forms</span>
                <div className="bg-[#0C9] text-[#fff] rounded-full p-1 group-hover:translate-x-2 transition-transform duration-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9.293 4.293a1 1 0 011.414 0L16 9.586a1 1 0 010 1.414l-5.293 5.293a1 1 0 01-1.414-1.414L13.586 11H4a1 1 0 110-2h9.586L9.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default HighlightsMecEng;
