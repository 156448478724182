import config from "../../util/config";

const BlogCard = ({
  title,
  description,
  image,
  imageUrl,
  publishDate,
  publishedDate,
  slug,
}: {
  title: string;
  description: string;
  image?: string;
  imageUrl?: string;
  publishDate?: string;
  publishedDate?: string;
  slug: string;
}) => {
  const displayImage = image ? image : imageUrl;
  const displayDate = publishDate ? publishDate : publishedDate;

  return (
    <div className="bg-white border border-[#EAEAEA] rounded-xl overflow-hidden">
      <div className="flex flex-col gap-5 h-48 min-max-md:h-32 min-max-lg:h-32 overflow-hidden">
        <a href={`blogs/career-courses/${slug}`} target="_blank">
          {displayImage ? (
            <img
              src={displayImage}
              alt={title}
              className="object-cover w-full h-full"
            />
          ) : null}
        </a>
      </div>
      <div className="flex flex-col gap-5 pt-4 px-4 pb-9">
        <div className="flex flex-col gap-2.5">
          <h4 className="text-base font-semibold text-black line-clamp-1">
            {title}
          </h4>
          {displayDate && (
            <p className="text-sm text-[#555555] font-normal flex flex-row gap-1">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_308_4127)">
                  <path
                    d="M10.9968 2.35682H10.4574V1.27808H9.37865V2.35682H3.98494V1.27808H2.9062V2.35682H2.36683C1.77352 2.35682 1.28809 2.84225 1.28809 3.43556V12.0655C1.28809 12.6588 1.77352 13.1442 2.36683 13.1442H10.9968C11.5901 13.1442 12.0755 12.6588 12.0755 12.0655V3.43556C12.0755 2.84225 11.5901 2.35682 10.9968 2.35682ZM10.9968 12.0655H2.36683V5.05367H10.9968V12.0655Z"
                    fill="#00C798"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_308_4127">
                    <rect
                      width="12.9449"
                      height="12.9449"
                      fill="white"
                      transform="translate(0.208984 0.73877)"
                    />
                  </clipPath>
                </defs>
              </svg>
              {displayDate}
            </p>
          )}
        </div>
        <div className="flex flex-col">
          <p className="text-sm h-10 text-[#555555] line-clamp-2">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
