import React from "react";

const referencesData = [
  {
    name: "Ravi",
    feedback:
      "Thanks to the referral program, I was able to pay my tuition fees with the referral amount. It made a huge difference in my financial situation!",
  },
  {
    name: "Anita",
    feedback:
      "I used my referral earnings to buy a laptop, which has been incredibly helpful for my studies. I couldn't have done it without this program!",
  },
  {
    name: "Kiran",
    feedback:
      "With the money I earned from referrals, I joined additional certification courses that have enhanced my skills and career prospects. I'm grateful for this opportunity!",
  },
];

const WhatOurReferences = () => {
  return (
    <div className="flex flex-col px-20 pt-9 pb-20 w-full bg-[#FFF1FA] max-md:px-7 max-md:max-w-full my-6">
      <h2 className="self-start text-[40px] font-bold leading-none text-[#173CBA]">
        What Our Referrers Have To Say
      </h2>

      <div className="w-full max-md:w-full flex flex-row max-lg:flex-col justify-between mt-10 max-lg:gap-[21px] ">
        {referencesData.map((reference, index) => (
          <div
            key={index}
            className="w-[413px] min-max-lg:w-[260px] min-max-md:mx-auto flex flex-col px-7 bg-white shadow-[0px_0px_15px_0px_rgba(255,102,204,0.25)] gap-2 py-10 max-md:w-full"
          >
            <span className="text-[30px] text-[#0c9] font-bold">"</span>
            <span className="text-black text-[18px] font-semibold">
              {reference.name}
            </span>
            <div className="w-[361px] min-max-lg:w-[180px] max-md:w-full text-[#747474] text-[18px] font-normal">
              <p>"{reference.feedback}"</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatOurReferences;
