import React, { useState } from "react";
import UnrivaledConversionRatesImg from "../../images/study-abroad/University/Unrivaled-img.png";
import { EpRight } from "../study-abroad-landing/Banner/Banner";
import LeadGeneration from "../LeadGeneration";
import LeadGenCtaBtn from "../../features/lead-generation/LeadGenCtaBtn";

interface UnrivaledConversionRatesProps {
  data: {
    title: string;
    descriptionOne: string;
    descriptionTwo: string;
    buttonText: string;
  };
}

const UnrivaledConversionRates: React.FC<UnrivaledConversionRatesProps> = ({
  data,
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  return (
    <section className="bg-[#FFF9E5] py-9">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row items-center gap-10 ">
          <div className="lg:w-[6%] hidden lg:block"></div>
          <div className="w-full lg:w-1/2">
            <div className="flex flex-col gap-7">
              <div className="flex flex-col gap-4">
                <h3 className="text-2xl font-medium lg:font-light text-blue">
                  {data.title}
                </h3>
                <div className="flex flex-col gap-4 md:gap-8">
                  <p className="text-base text-black">{data.descriptionOne}</p>
                  <p className="text-base text-black">{data.descriptionTwo}</p>
                </div>
                <div className="flex flex-row">
                  <LeadGenCtaBtn
                    pageName="study-abroad-university"
                    ctaName="connect-to-marketing-head"
                    className="bg-green hover:bg-blue button-styles py-3.5 px-3 green-arrow-button flex flex-row items-center justify-center gap-2 text-white"
                  >
                    {data.buttonText} <EpRight className="green-arrow-icon" />
                  </LeadGenCtaBtn>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4 xl:w-1/2 hidden lg:block"></div>
          <div className="w-full lg:w-1/2">
            <div className="flex w-full h-full">
              <img
                src="https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/University/study-abroad-university-unriviledconversation.png"
                alt={data.title}
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UnrivaledConversionRates;
