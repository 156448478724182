import React, { useState } from "react";

interface InputField {
  placeholder: string;
  type: string;
  name: string;
  options?: string[];
}

const BookCounselling = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    destination: "",
    city: "",
    education: "",
    admissionYear: "",
  });

  const inputFields: InputField[] = [
    { placeholder: "Your Name", type: "text", name: "name" },
    { placeholder: "Your E-mail", type: "email", name: "email" },
    { placeholder: "10 Digit Mobile Number", type: "tel", name: "phone" },
    {
      placeholder: "Study Destination",
      type: "select",
      name: "destination",
      options: ["USA", "UK", "Canada", "Australia", "Germany", "France"],
    },
    { placeholder: "Your City", type: "text", name: "city" },
    { placeholder: "Latest Education", type: "text", name: "education" },
    {
      placeholder: "Preferred admission Year",
      type: "text",
      name: "admissionYear",
    },
  ];

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form submitted:", formData);

    // Clear input fields after submission
    setFormData({
      name: "",
      email: "",
      phone: "",
      destination: "",
      city: "",
      education: "",
      admissionYear: "",
    });
  };

  return (
    <div className="flex flex-wrap gap-10 justify-between items-center px-20 py-14 bg-[#0C9] max-md:px-5 mb-6">
      <div className="flex flex-col self-stretch my-auto min-w-[240px] w-[542px] max-lg:max-w-full">
        <h3 className="text-4xl font-bold leading-none text-[#173CBA] ">
          Book Your Free Counselling
        </h3>
        <p className="mt-2 text-base font-medium leading-7 text-white ">
          Our team of Mentors are quick and responsive, contact us now for a
          free consultation.
        </p>
      </div>

      <form
        onSubmit={handleSubmit}
        className="flex flex-col justify-center self-stretch px-10 py-14 my-auto text-sm border-[#173CBA] border-solid border-[5px] min-h-[487px] min-w-[240px] rounded-[31px] w-[587px] max-md:px-5 max-md:pb-24 max-md:max-w-full"
      >
        <div className="flex flex-col w-full max-w-[505px] max-lg:max-w-full">
          <div className="grid grid-cols-2 gap-5 w-full text-[#7C7C7C] max-md:max-w-full">
            {inputFields.map((field, index) => (
              <div
                key={index}
                className="col-span-1 px-3 py-2.5 my-auto bg-white border  rounded-lg min-h-[40px]"
              >
                <label className="sr-only" htmlFor={field.name}>
                  {field.placeholder}
                </label>

                {field.type === "select" ? (
                  <select
                    id={field.name}
                    name={field.name}
                    value={formData[field.name as keyof typeof formData]}
                    onChange={handleInputChange}
                    className="w-full h-full bg-transparent outline-none"
                  >
                    <option value="" disabled>
                      {field.placeholder}
                    </option>
                    {field.options &&
                      field.options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                  </select>
                ) : (
                  <input
                    type={field.type}
                    id={field.name}
                    name={field.name}
                    placeholder={field.placeholder}
                    value={formData[field.name as keyof typeof formData]}
                    onChange={handleInputChange}
                    className="w-full h-full bg-transparent outline-none"
                  />
                )}
              </div>
            ))}
          </div>

          <div className="flex justify-center mt-6">
            <button
              type="submit"
              className="px-6 py-3 bg-[#173CBA] text-white font-semibold rounded-lg w-full max-w-[200px]"
            >
              Book an Appointment
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BookCounselling;
