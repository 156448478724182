import React from "react";

const topEarners = [
  { name: "Mahesh", amount: "₹ 1, 25,000" },
  { name: "Sunder", amount: "₹ 1, 00,000" },
  { name: "Brahma", amount: "₹ 70,000" },
  { name: "Abhilash", amount: "₹ 30,000" },
  { name: "kiran", amount: "₹ 30,000" },
];

const TopEarners: React.FC = () => {
  return (
    <div className="flex flex-col  items-start px-20 min-max-md:px-14 py-16  w-full bg-[#FFF9E5] max-md:px-7 max-md:mt-7 max-md:max-w-full">
      <div className="flex flex-col w-full  max-md:max-w-full">
        <h2 className="self-start text-4xl font-bold leading-none text-[#173CBA] max-md:max-w-full">
          Top 5 Earners
        </h2>
        <div className="mt-9 max-md:max-w-full">
          <div className="flex  max-md:flex-col flex-row justify-between">
            <div className="flex flex-col w-[445px]   max-md:ml-0 max-md:w-full min-max-md:w-[45%] min-max-lg:w-[47%]">
              <img
                loading="lazy"
                src="/images/top-earner.png"
                alt="Top earners illustration"
                className="object-contain h-[501px] max-xl:h-[403px] w-full aspect-[0.91] max-md:mt-10 max-md:max-w-full"
              />
            </div>
            <div className="flex flex-col w-[497px] max-md:ml-0 max-md:w-full xl:mr-[160px] min-max-md:w-[45%] min-max-lg:w-[47%]">
              <div className="flex flex-col self-stretch my-auto text-sm text-neutral-700 max-md:mt-10 max-md:max-w-full">
                <table className="min-w-full bg-white border border-solid border-black">
                  <thead>
                    <tr>
                      <th className="px-3 py-2 text-left text-base border border-black">
                        No
                      </th>
                      <th className="px-3 py-2 text-left text-base border border-black">
                        Top 5 Earners
                      </th>
                      <th className="px-3 py-2 text-left text-base border border-black">
                        Referral Amount Earned
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {topEarners.map((earner, index) => (
                      <tr key={index}>
                        <td className="px-3 py-2 text-base border border-black">
                          {index + 1}
                        </td>
                        <td className="px-3 py-2 text-base border border-black">
                          {earner.name}
                        </td>
                        <td className="px-3 py-2 text-base border border-black">
                          {earner.amount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p className="mt-2.5 leading-6 max-md:mr-2 max-md:max-w-full">
                  <span className="font-semibold">Note</span>: The top 5 earners
                  have successfully referred both online and offline.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopEarners;
