import React, { useState, useEffect, useContext, useRef } from "react";
import FAQuestions from "./resources/Faqs";
import AskQuestions from "./resources/AskQuestions";
import BlogsArticles from "./resources/BlogsArticles";
import config from "../../../util/config";
import { LoadingContext } from "../../Spinner/LoadingContext";

interface IStreamData {
  streamTitle: string;
  streamName: string;
  streamIcon: string;
}

interface SpotLight {
  image: string;
  pushlishDate: string;
  link: string;
  description: string;
  title: string;
}

interface VisualStory {
  id: number;
  title: string;
  description: string;
  stories: {
    id: number;
    type: "video" | "article" | "image";
    url: string;
    careerVisualStoryDataId?: number;
  }[];
}

interface Article {
  publishDate: string;
  description: string;
  title: string;
  slug: string;
  image: string;
}

interface Webinar {
  date: string;
  by: string;
  description: string;
  time: string;
  title: string;
  image1: string;
  name2: string;
  image2: string;
  name1: string;
}

interface ExploreMoreData {
  spotLight: SpotLight[];
  visualStories: VisualStory[];
  articles: Article[];
  webinar: Webinar[];
}

interface IApiData {
  streams: IStreamData[];
  exploreMore: ExploreMoreData;
}

const CourseResources: React.FC = () => {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [data, setData] = useState<IApiData | null>(null);
  const [error, setError] = useState<string | null>(null);

  const exploreMore = data?.exploreMore || {
    spotLight: [],
    visualStories: [],
    articles: [],
    webinar: [],
  };

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const response = await fetch(
          config.apiUrl + "/api/course/get-all-streams-courses"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
      } catch (err: any) {
        setError(err.message || "An unknown error occurred");
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [startLoading, stopLoading]);

  return (
    <div>
      <div className="bg-[#ECECEC] py-6">
        <div className="container mx-auto">
          <div className="flex flex-col lg:gap-8">
            <div className="flex lg:flex-row flex-col">
              <div className="w-full lg:w-9/12 flex flex-col gap-12">
                <FAQuestions />
              </div>
              <div className="w-full lg:w-3/12">
                <div className="bg-[#fff] w-full h-full flex justify-center items-center"></div>
              </div>
            </div>
            {/* <div className="flex lg:flex-row flex-col gap-12 lg:gap-14 bg-white">
              <div className="w-full lg:w-3/4 flex flex-col gap-12">
                <AskQuestions /> 
              </div>
            </div>  */}
            <div className="flex lg:flex-row flex-col gap-12 lg:gap-14">
              <div className="w-full flex flex-col gap-12">
                <BlogsArticles exploreMore={exploreMore} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseResources;
