import React from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import CustomSwiper from '../../../../components/Slider/CustomSwiper';


const MentorCard = ({
  imageUrl,
  name,
  role,
  experiance,
}: {
  imageUrl: string;
  name: string;
  role: string;
  experiance: string;
}) => (
  <a href='/mentors' className='cursor-pointer'>
  <div className="flex flex-col w-full md:w-[237px] relative overflow-hidden group">
  <div className="w-full h-[256px]">
    <img className="w-full h-full object-cover" src={imageUrl} alt={name} />
  </div>
  <div className="relative text-center bg-[#173CBA] py-2">
  <div className='absolute right-2 -translate-y-6 z-10'>
    <img src="/images/scholarship/landing/medal.svg" alt="medal" />
  </div>

    <div className="text-white  text-base font-bold leading-[21px]">{name}</div>
    <div className="text-white text-center font-open-sans text-[14px] font-normal leading-5">{role}</div>
  </div>
  <div className="absolute inset-0 bg-black bg-opacity-80 transition-transform transform translate-y-full group-hover:translate-y-0 overflow-hidden duration-300">
    <div className="text-white mt-4 font-sans text-lg font-normal leading-5 p-2">
      {experiance}
    </div>
    <div className='absolute bottom-16 ml-4 gap-4 flex flex-col md:flex-row'><div className='text-white border border-green px-1 py-1 rounded-lg mr-4'>Mentoring</div><div className='text-white  rounded-lg border border-green px-1 py-1'>Admission</div></div>
    {/* <button className="bg-green hover:bg-blue-700 text-white font-bold py-2 px-2 rounded absolute bottom-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap"> 
      Chat with me
    </button>  */}
  </div>
</div>
</a>
);

const Mentors = () => {
  const mentorsData = useSelector((state: RootState) => state.scholarship.mentors);

  const cardContent = mentorsData?.map((card) => (
    <MentorCard
      {...card}
    
    />
  ));
  return (<div className='bg-[#D9F6EF] py-12'>
    <div className="container flex flex-col"> 
      <h1 className="text-#383838 font-roboto text-xl font-normal leading-snug py-2"><span className='font-bold'>Mentors</span> : Scholarships and Loans</h1> 
      <p className="text-#383838 font-roboto text-[16px] font-normal leading-snug">
      Get personalized guidance on securing scholarships and loans with our expert mentors
            </p>
            
            <div className="flex justify-center mt-6">
            <CustomSwiper
              className="!px-2"
              items={cardContent}
              navigation={"side"}
              autoplay={true}
              breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 20 },
                768: { slidesPerView: 2, spaceBetween: 20 },
                1024: { slidesPerView: 3, spaceBetween: 20 },
                1280: { slidesPerView: 4, spaceBetween: 30 },
              }}
              progressBar={true}
            />
          </div>
    </div>
    </div>
  );
};

export default Mentors;