import React from "react";
import loginPageImage from "../../assets/loginimage.svg";
import logo from "../../images/cm-logo-color.png";
import { Box, Grid } from "@mui/material";
import { image } from "../../services/apiReq";
import CarouselView from "../../components/Carousel";
import { Outlet } from "react-router-dom";
function SignInOutlet() {
  const carouselList: image[] = [
    { scr: loginPageImage, title: "image2", alt: "image2" },
    { scr: loginPageImage, title: "image1", alt: "image1" },
  ];
  return (
    <Grid container spacing={24}>
      <Grid item xs={6} sm={6}>
        <Box>
          <CarouselView images={carouselList} />
        </Box>
      </Grid>

      <Grid item xs={18} sm={6} style={{ padding: "192px 0 38px 13px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 1,
            m: 1,
            paddingBottom: 20,
          }}
        >
          <Box
            sx={{
              alignItems: "left",
            }}
          >
            <img src={logo} alt="" width={"100%"} />
          </Box>
          <Box
            sx={{
              alignItems: "right",
            }}
          >
            About Us
          </Box>
        </Box>
        <>
          <Outlet />
        </>
      </Grid>
    </Grid>
  );
}

export default SignInOutlet;
