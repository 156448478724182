import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../../util/config";
import Loader from "../../components/Loader/Loader";
import BlogTab from "../../components/courses/ExploreMore/BlogTab";

const KnowledgeHub: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabIndicatorStyle, setTabIndicatorStyle] =
    useState<React.CSSProperties>({ marginLeft: 0, width: 0 });
  const [loading, setLoading] = useState(true);
  const [latestNews, setLatestNews] = useState<any[]>([]);
  const [articles, setArticles] = useState<any[]>([]);
  const tabRef = useRef<HTMLButtonElement[]>([]);

  // Fetch data from the API
  const getBlogsAndNews = () => {
    axios
      .get(`${config.apiUrl}/api/blogs/blogsandnews`)
      .then((response) => {
        const data =
          response.data.sections[0]["Exams And Admissions"].subcategories;

        // Extract and prepare Latest News
        const fetchedNews = data["Latest News"].results;
        setLatestNews(fetchedNews);

        // Extract and prepare Articles & Blogs
        const fetchedArticles = data["Articles & Blogs"].results;
        setArticles(fetchedArticles);

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
    setIndicatorToActiveTab(index);
  };

  const handleTabMouseEnter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const tabRect = event.currentTarget.getBoundingClientRect();
    const containerRect =
      event.currentTarget.parentElement?.getBoundingClientRect();
    const tabStart = tabRect.left;
    const containerStart = containerRect?.left || 0;
    const distanceTabStartToContainerStart = tabStart - containerStart;
    const halfWidth = tabRect.width / 2;
    const marginLeft =
      distanceTabStartToContainerStart + (tabRect.width - halfWidth) / 2;

    setTabIndicatorStyle({
      marginLeft: `${marginLeft}px`,
      width: `${halfWidth}px`,
    });
  };

  const handleTabMouseLeave = () => {
    setIndicatorToActiveTab(selectedTab);
  };

  const setIndicatorToActiveTab = (index: number) => {
    const tabElement = tabRef.current[index];
    if (!tabElement) return;
    const tabRect = tabElement.getBoundingClientRect();
    const containerRect = tabElement.parentElement?.getBoundingClientRect();
    const tabStart = tabRect.left;
    const containerStart = containerRect?.left || 0;
    const distanceTabStartToContainerStart = tabStart - containerStart;

    setTabIndicatorStyle({
      marginLeft: `${distanceTabStartToContainerStart + tabRect.width / 4}px`,
      width: `${tabRect.width / 2}px`,
    });
  };

  useEffect(() => {
    getBlogsAndNews();
    setIndicatorToActiveTab(selectedTab);
  }, [selectedTab]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="py-16 relative">
      <div className="container mx-auto">
        <div className="flex flex-col gap-4">
          <h3 className="text-3xl font-bold text-black">Knowledge Hub</h3>{" "}
          {/* Updated Section Name */}
          <div className="relative">
            <div
              className="tab-container gap-4"
              onMouseLeave={handleTabMouseLeave}
            >
              {["Blogs & Articles"].map((tab, index) => (
                <button
                  key={index}
                  ref={(el) => (tabRef.current[index] = el!)}
                  onClick={() => handleTabClick(index)}
                  onMouseEnter={handleTabMouseEnter}
                  className={`py-2 text-xl hover:text-[#173CBA] transition-all ${
                    selectedTab === index
                      ? "text-[#173CBA] font-bold"
                      : "text-[#A8A8A8]"
                  }`}
                >
                  {tab}
                </button>
              ))}
              <div
                className="absolute bottom-[-4px] h-[6px] bg-[#00C798] rounded-lg transition-all"
                style={tabIndicatorStyle}
              />
            </div>
          </div>
          {selectedTab === 0 && <BlogTab articles={articles} />}{" "}
          {/* Display Blogs & Articles */}
        </div>
      </div>
    </div>
  );
};

export default KnowledgeHub;
