import React from "react";
import OverviewSection from "../overview/OverviewSection";
import BulletinBoard from "../overview/BulletinBoard";
import HighlightsSection from "../overview/HighlightsSection";
import CollaborationSection from "../overview/CollaborationsSection";
import SocialSection from "../overview/Social";
import VideoSection from "../overview/Video";
import ContactSection from "../overview/ContactUs";
import collabrationsImg from "../../../images/college/details/collabrations-img.png";
import CoursesList from "../sidebar/CoursesList";
import ApplicationForms from "../sidebar/ApplicationForms";

interface OverviewTabProps {
  collegeDetails: {
    details: {
      key_0?: { title: string; description: string };
      key_5?: { title: string; description: string };
      key_7?: { title: string; description: string };
      key_1?: {
        title: string;
        description: string;
      };
      key_2?: {
        title: string;
        description: string;
      };
      key_3?: { title: string; description?: string };
      key_4?: { title: string; description?: string };
      key_6?: {
        title: string;
        videos?: Array<{ url: string; title: string }>;
      };
    };
  };
}

const OverviewTab: React.FC<OverviewTabProps> = ({ collegeDetails }) => {
  const overview = collegeDetails?.details?.key_1;
  const highlights2025 = collegeDetails?.details?.key_2;
  const internationalCollaboration = collegeDetails?.details?.key_3;
  const amritaSocials = collegeDetails?.details?.key_5;
  const amritaVideos = collegeDetails?.details?.key_6;
  const contactInfo = collegeDetails?.details?.key_4;
  const bulletinBoard = collegeDetails?.details?.key_7;

  const collaborationsData = {
    rightSideImage: {
      src: collabrationsImg,
      alt: "Student with books",
      caption:
        "Find Out <span class='text-xl text-blue font-semibold'>Colleges in India</span> Where you get <span class='text-2xl font-bold text-blue'>Admission?</span>",
      buttonText: "Check College Predictor now",
      buttonLink: "/college-predictor",
    },
  };

  if (!overview) {
    return <div>No overview data available</div>;
  }

  return (
    <>
      <section className="bg-[#FFF9E5]">
        <div className="container mx-auto py-10">
          <div className="flex flex-col lg:flex-row gap-6">
            <div
              className={`w-full ${bulletinBoard ? "lg:w-9/12" : "lg:w-full"}`}
            >
              {" "}
              <OverviewSection
                title={overview.title || "Overview"}
                content={overview.description || "No description available"}
              />
            </div>
            {bulletinBoard && (
              <div className="w-full lg:w-3/12">
                <BulletinBoard
                  title={bulletinBoard.title || "Bulletin Board"}
                  description={
                    bulletinBoard.description || "No updates available"
                  }
                />
              </div>
            )}
          </div>
        </div>
      </section>

      {highlights2025 && (
        <section className="py-6 bg-[#F4F4F4]" id="Highlights">
          <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row gap-6">
              <div className="w-full lg:w-9/12">
                <HighlightsSection
                  title={highlights2025.title || "Highlights 2025"}
                  content={
                    highlights2025.description || "No highlights available"
                  }
                />
              </div>
              <div className="w-full lg:w-3/12 hidden lg:block">
                <div className="flex flex-col gap-10">
                  <ApplicationForms />
                  <CoursesList />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {internationalCollaboration && (
        <section className="py-6 bg-white" id="InternationalCollaboration">
          <div className="container mx-auto">
            <CollaborationSection
              title={
                internationalCollaboration.title ||
                "International Collaborations"
              }
              description={
                internationalCollaboration.description ||
                "No collaboration details available"
              }
              rightSideImage={collaborationsData.rightSideImage}
            />
          </div>
        </section>
      )}

      {amritaSocials && (
        <section className="py-6 bg-[#E5F4FF]" id="AmritaSocials">
          <div className="container mx-auto">
            <SocialSection
              title={amritaSocials.title || "Amrita Socials"}
              content={
                amritaSocials.description || "No social updates available"
              }
            />
          </div>
        </section>
      )}

      {amritaVideos && (
        <section className="py-6 bg-[#F4F4F4]" id="AmritaVideos">
          <div className="container mx-auto">
            <VideoSection
              title={amritaVideos.title || "Amrita Videos"}
              videos={amritaVideos.videos || []}
            />
          </div>
        </section>
      )}

      {contactInfo && (
        <section className="py-6" id="ContactAmrita">
          <div className="container mx-auto">
            <ContactSection
              title={contactInfo.title || "Contact Amrita"}
              description={
                contactInfo.description || "No contact information available"
              }
            />
          </div>
        </section>
      )}
    </>
  );
};

export default OverviewTab;
