import { useEffect, useState } from "react";
import DesktopFilterMenu, {
  IFilterDataItem,
} from "../../../components/filter/components/DesktopFilterMenu";
import useSelectedFilter, {
  ICategory,
  IGetFilterDataFromPath,
  IGetPathFromFilterData,
} from "../../../components/filter/customHooks/useSelectedFilter";
import { FilterCategory } from "../../../components/filter/FilterEnum";
import ScholarshipCard from "../../course-intermediate/components/ScholarshipCard";
import Banner from "./components/Banner";
import MobileFilterMenu from "../../../components/filter/components/MobileFilterMenu";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterChips from "../../../components/filter/components/FilterChips";
import config from "../../../util/config";
import { useAxios } from "../../../components/useAxios";

// const PAGE_SIZE = 10;

const CATEGORY_FILTER_CATEGORY = "category";

const FILTER_CATEGORIES: ICategory[] = [
  {
    key: CATEGORY_FILTER_CATEGORY,
    type: FilterCategory.MultiSelect,
    showinPath: false,
  },
];

const categoryFilterData: IFilterDataItem = {
  categoryName: "Select category",
  categoryKey: CATEGORY_FILTER_CATEGORY,
  type: FilterCategory.MultiSelect,
  options: [
    { name: "For Girls", key: "1" },
    { name: "Mean based", key: "2" },
    { name: "Merit based", key: "3" },
  ],
};

const getPathName: IGetPathFromFilterData = (filterData) => {
  return "list-of-scholarships-in-india";
};

const getPathData: IGetFilterDataFromPath = (path) => {
  return {};
};

const ScholarshipIntermediate = () => {
  // const [listData, setListData] = useState<any>(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         config.apiUrl + "/api/scholarship-loan/scholarship-intermediate"
  //       );
  //       setListData(response.data?.data);
  //     } catch (e) {
  //       console.log("Error fetching scholarship intermediate data");
  //     }
  //   };

  //   fetchData();
  // }, []);

  // const listingContainerRef = useRef<HTMLDivElement>(null);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const isMediumDevice = window.innerWidth >= 768;
  // const [isFirstLoad, setIsFirstLoad] = useState(true);

  // useEffect(() => {
  //   setIsFirstLoad(false);
  // }, []);

  const {
    selectedFilter,
    updateSelectedFilter,
    clearFilter,
    filterDataForApiBody,
  } = useSelectedFilter(
    FILTER_CATEGORIES,
    "scholarships",
    getPathName,
    getPathData
  );

  const {
    data: listData,
    loading: listDataLoading,
    error: listDataError,
    fetchData: fetchListData,
  } = useAxios<any>({
    url: config.apiUrl + "/api/scholarship-loan/scholarship-intermediate",
    method: "POST",
  });

  useEffect(() => {
    if (filterDataForApiBody)
      fetchListData(
        {},
        {
          ...filterDataForApiBody,
        }
      );
  }, [filterDataForApiBody]);

  // const { searchKeyword, handleKeywordChange, deboundedSearchKeyword } =
  //   useDebouncedSearch();

  // const {
  //   totalPages,
  //   currentPage,
  //   updateTotalCount,
  //   handlePrevPage,
  //   handleNextPage,
  //   handleResetPagination,
  // } = useQueryParamPagination(PAGE_SIZE);

  // useEffect(() => {
  //   if (!isFirstLoad && listingContainerRef.current) {
  //     listingContainerRef.current.scrollIntoView();
  //     console.log("change scroll");
  //   }
  // }, [currentPage]);

  const filterData: IFilterDataItem[] = [];

  filterData.push(categoryFilterData);

  // if (!listData) return <Loader />;

  // return (
  //   <div className="bg-pink-light text-black font-inter">
  //     <Banner />
  //     <div className="container max-w-[1024px] py-12 flex flex-col gap-4">
  //       {listData?.map((item: any) => (
  //         <ScholarshipCard
  //           title={item.scholarshipName}
  //           logo={item.logo}
  //           slug={item.slug}
  //         />
  //       ))}
  //     </div>
  //   </div>
  // );

  return (
    <div className="bg-pink-light text-black font-inter">
      <Banner />
      <div className="container">
        <div className="flex gap-6 py-12">
          {isMediumDevice && (
            <div className="w-[30%]">
              <DesktopFilterMenu
                filterData={filterData}
                selectedFilter={selectedFilter}
                updateSelectedFilter={updateSelectedFilter}
                clearFilter={clearFilter}
              />
            </div>
          )}
          {!isMediumDevice && showMobileFilter && (
            <MobileFilterMenu
              filterData={filterData}
              selectedFilter={selectedFilter}
              updateSelectedFilter={updateSelectedFilter}
              clearFilter={clearFilter}
              closeFilter={() => setShowMobileFilter(false)}
            />
          )}

          <div className="w-full md:w-[70%] flex flex-col gap-6">
            <FilterChips
              filterData={filterData}
              selectedFilter={selectedFilter}
              updateSelectedFilter={updateSelectedFilter}
            />
            <div className="flex items-center justify-between gap-4">
              {!isMediumDevice && (
                <button
                  className="bg-white rounded-md p-3"
                  onClick={() => setShowMobileFilter(true)}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                    color="white"
                    stroke="#7D7B89"
                    size="lg"
                    strokeWidth={40}
                  />
                </button>
              )}
              {/* <div className="flex-1 flex items-center bg-white pr-4 rounded-md overflow-hidden">
                <input
                  className="p-2 text-base placeholder-grey w-full"
                  value={searchKeyword}
                  onChange={(e) => handleKeywordChange(e.target.value)}
                  placeholder={
                    isMediumDevice ? "Search your dream course" : "Search"
                  }
                />
                {searchKeyword && searchKeyword.length > 0 ? (
                  <button onClick={() => handleKeywordChange("")}>
                    <FontAwesomeIcon
                      icon={faX}
                      size="sm"
                      className="text-grey"
                    />
                  </button>
                ) : (
                  <FontAwesomeIcon
                    icon={faSearch}
                    size="sm"
                    className="text-grey"
                  />
                )}
              </div> */}
            </div>
            {listData?.data?.map((item: any) => (
              <ScholarshipCard
                title={item.scholarshipName}
                logo={item.logo}
                slug={item.slug}
              />
            ))}
            {/* <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipIntermediate;
