import ArticlesBlogs from "./components/ArticlesBlogs";
import GetAdmissionAdvise from "./components/GetAdmissionAdvise";
import WhatWeOffer from "./components/WhatWeOffer";
import CollegeAdmissionProcess from "./components/CollegeAdmissionProcess";
import Testimonials from "./components/Testimonials";
import ApplicatonToAdmission from "./components/ApplicationToAdmission";
import TopColleges from "./components/TopColleges";
import ExploreCollegeLife from "./components/ExploreCollegeLife";

import ExploreYourDreamCollege from "./components/ExploreYourDreamCollege";
import MentorSection from "./components/MentorSection";
import { Banner } from "./components/Banner";
import ConnectWithTopUniversities from "./components/ConnectWithTopUniversities";

const ApplicationsDirectAdmissions = () => {
  return (
    <div>
      <Banner />
      <TopColleges />

      <CollegeAdmissionProcess />

      <WhatWeOffer />
      <GetAdmissionAdvise />
      <ExploreCollegeLife />
      <Testimonials />
      <ApplicatonToAdmission />
      <MentorSection />
      <ArticlesBlogs />
      <ExploreYourDreamCollege />
      <ConnectWithTopUniversities />
    </div>
  );
};

export default ApplicationsDirectAdmissions;
