import React from "react";
import { motion } from "framer-motion";
import Avatar from "../../../../../../images/authentication/mail-icon.svg";

type CardProps = {
  title: string;
  isSelected: boolean;
  onClick: () => void;
  cardSize: any;
  image: string;
  defaultColor: string;
  nonSelectedColor: string;
  highlightColor: string;
  hasSelection: boolean;
};

const OptionCard: React.FC<CardProps> = ({
  title,
  isSelected,
  onClick,
  cardSize,
  image,
  defaultColor,
  nonSelectedColor,
  highlightColor,
  hasSelection,
}) => {
  
  return (
    <motion.div
      onClick={onClick}
      // animate={isSelected ? { scale: 1.1 } : { scale: 1 }}
      transition={{ duration: 0.3 }}
      // with card animation

      // className={`flex items-center justify-start p-2 rounded-full shadow-md transition-shadow duration-300 ease-in-out w-${cardSize} max-sm:w-full cursor-pointer border-2 transform hover:scale-105 hover:shadow-2xl bg-white
      // ${isSelected ? `border-[#00c798] border-1` : "border-gray-300"}
      // ${isSelected ? "shadow-lg" : "hover:shadow-lg"}
      // `}
      //animation removed for card item
      // className={`flex items-center justify-start p-2 rounded-full shadow-md  w-${cardSize} max-sm:w-full cursor-pointer border-2 bg-white
      // ${isSelected ? `border-[#00c798] border-1` : "border-gray-300"}

      // `}
      className={` w-${cardSize} p-2 border rounded-lg cursor-pointer inline-flex items-center gap-2 bg-white 
        ${isSelected ? "border-2 border-[#173CBA]" : "border-gray-300"} 
        transition-all`}
      style={{
        backgroundColor: isSelected
          ? highlightColor
          : hasSelection
          ? nonSelectedColor
          : defaultColor,
      }}
    >
      {/* <div className="relative mr-2">
        <div
          className={`h-4 w-4 rounded-full border-2 flex items-center justify-center ${
            isSelected ? `#00c798` : "border-gray-400"
          }`}
          style={{ backgroundColor: isSelected ? "#00c798" : "transparent" }}
        ></div>
      </div> */}
      {/* <img src={image} alt={`${title} icon`} className="w-6 h-6 mr-1" /> */}

      <span className="text-sm font-semibold ">{title}</span>
    </motion.div>
  );
};

export default OptionCard;
