import React from "react";
import { Link } from "react-router-dom";

export const PricingPlans = () => {
  const plans = [
    {
      title: "Academic Success Pack",
      description:
        "Includes 3 Sessions each with Student Buddy | Admission Mentor.",
      cost: "Rs: 3,000",
      taxes: "+ Taxes",
    },
    {
      title: "Career & College Prep Pack",
      description:
        "2 sessions each with Student Buddy Mentor | Career Mentor | Admission Mentor | Scholarship Mentor.",
      cost: "Rs: 6,000",
      taxes: "+ Taxes",
    },
    {
      title: "Premium All-Inclusive Pack",
      description:
        "Includes: 2 sessions with all mentors Student Buddy | Career Mentor | Admission Mentor | Student Health Mentor | Scholarship Mentor | Loan Mentorship Mentor.",
      cost: "Rs: 9,999",
      taxes: "+ Taxes",
    },
  ];

  return (
    <div className="bg-[#173CBA]  py-10 px-5">
      <div className="container">
        <div className=" mb-8">
          <h2 className="text-5xl font-bold text-[#0C9] mb-2">
            The Mentorship Platform Built to Help You Grow
          </h2>
          <p className="text-xl text-[#F7F7F7]">
            Connect with the right people to clear your doubts and enhance your
            skills with confidence.
          </p>
          <p className="text-xl text-[#F7F7F7]">
            Unlock the full value of mentorship and reach your potential.
          </p>
        </div>

        <div className="flex md:justify-center md:space-x-6 min-max-xss:flex-col min-max-xs:flex-col gap-6">
          {plans.map((plan, index) => (
            <div
              key={index}
              className="bg-yellow-50 relative w-[413px] h-[466px] text-[#333] p-6 rounded-lg shadow-md min-max-xss:w-full min-max-xs:w-full"
            >
              <h3
                className="text-lg font-semibold bg-[#0C9] text-[#FFF9E5] p-2 mb-4 text-center -ml-6"
                style={{ borderRadius: "0px 50px 50px 0px" }}
              >
                {plan.title}
              </h3>
              <p className="text-sm mb-4">{plan.description}</p>
              <div className="absolute bottom-6 items-end">
                <p className="text-[#173CBA] text-lg ">
                  <span className="">Cost:</span> <br />
                  <span className="text-2xl font-bold ">{plan.cost}</span>
                  {plan.taxes}
                </p>
                <button
                  className="text-[#383838] mt-4 inline-block "
                  title="Mentor Scheduling will be available from January - 2025"
                >
                  Get Started →
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center mt-10 text-sm text-white">
          <p>
            (FAQS) • Pause or cancel your plan at any time. • You have access to
            all mentors on all plans. • Credits are valid for 12 months after
            purchase date. • Refund unused credits within 30 days of purchase.
          </p>
        </div>
      </div>
    </div>
  );
};
