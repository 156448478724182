import React from "react";
import { Accordion, Option } from "./Accordion";
import { useSearchParams } from "react-router-dom";

export interface FilterCategory {
  categoryName: string;
  categoryKey: string;
  options: Option[];
}

interface DesktopFiltersProps {
  filters: FilterCategory[];
  setSelectedVal: (selected: any) => void;
  setFilters: (filters: FilterCategory[]) => void;
}

const DesktopFilters: React.FC<DesktopFiltersProps> = ({
  filters,
  setSelectedVal,
  setFilters,
}) => {
  const onlyFilter = filters?.filter(
    (filter) => filter.categoryKey !== "sortBy"
  );

  const [searchParams] = useSearchParams();

  const toggleInUrlParams = (key: string, value: string) => {
    const url = new URL(window.location.href); // Get the current URL
    const params = new URLSearchParams(url.search); // Get the current search parameters

    if (params.has(key)) {
      const currentValue = params.get(key);

      let values = currentValue?.split(",");
      console.log("value is ", values);

      const isValueAlreadyThere = values?.includes(value);

      if (isValueAlreadyThere) {
        values = values?.filter((r) => r !== value);
        if (values) {
          searchParams.set(key, values?.join(","));
        }
      } else {
        const updatedValue = [currentValue, value];
        searchParams.set(key, updatedValue.join(","));
      }
    } else {
      const normalizedValue = Array.isArray(value) ? value.join(",") : value;

      searchParams.set(key, normalizedValue);
    }

    window.history.replaceState(
      null,
      "",
      `${url.pathname}?${searchParams.toString()}`
    );
  };
  const handleFilterChange = (
    value: string,
    filter: FilterCategory,
    index: number
  ) => {
    toggleInUrlParams(filter.categoryKey, value);

    setSelectedVal((prevSelected: any) => {
      const updatedCategory =
        prevSelected[filter.categoryKey] === value ? "" : value;

      const newSelectedVal = {
        ...prevSelected,
        [filter.categoryKey]: updatedCategory,
      };

      const requestPayload: { [key: string]: string | number } = {};

      Object.keys(newSelectedVal).forEach((key) => {
        const value = newSelectedVal[key];

        if (value) {
          requestPayload[key] = value;
        }
      });

      requestPayload.sortBy = "";
      requestPayload.offset = 0;
      requestPayload.fetchCount = 10;
      requestPayload.search = "";
      return newSelectedVal;
    });

    const updatedFilter = filters.map((filter, filterIndex) => {
      if (filterIndex === index) {
        const options = filter.options.map((option) => {
          const isAlreadySelected = option?.isSelected;
          return {
            ...option,
            isSelected:
              option.key === value
                ? isAlreadySelected
                  ? false
                  : true
                : option.isSelected,
          };
        });

        return {
          ...filter,
          options: options,
        };
      }
      return filter;
    });

    setFilters(updatedFilter);
  };

  return (
    <>
      {filters.map((filter, index) => (
        <Accordion
          key={index}
          filters={{
            label: filter.categoryName,
            options: filter.options,
          }}
          isOpen={false}
          onFilterChange={(value: string) => {
            handleFilterChange(value, filter, index);
          }}
          title={filter.categoryName}
        />
      ))}
    </>
  );
};

export default DesktopFilters;
