import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";

interface BlogCardProps {
  items: {
    title: string;
    description: string;
    image: string;
    publishDate: string;
    slug: string;
  }[];
}

const BlogCard: React.FC<BlogCardProps> = ({ items }) => {
  return (
    <Swiper
      spaceBetween={30}
      modules={[Autoplay]}
      loop={true}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 4,
        },
      }}
      className="ExploreMore w-full"
      navigation={{
        prevEl: ".mentor-prev-btn",
        nextEl: ".mentor-next-btn",
      }}
    >
      {items.map((item, index) => (
        <SwiperSlide key={index}>
          <div className="px-4">
            <div className=" bg-white rounded-xl overflow-hidden  translate-y-10  xl:w-[290px] ">
              <a href={`/blogs/career-courses/${item.slug}`} target="_blank">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-48 object-cover"
                />
              </a>
              <div className="flex flex-col p-4">
                <h4 className="text-base font-semibold text-black line-clamp-1 mb-2">
                  {item.title}
                </h4>
                <p className="text-sm text-gray-600 mb-2">{item.publishDate}</p>
                <p className="text-sm h-10 text-[#555555] line-clamp-2">
                  {item.description}
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BlogCard;
