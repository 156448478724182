import Image from "../../../components/Image";
import LeadGenCtaBtn from "../../../features/lead-generation/LeadGenCtaBtn";

const BookSession = () => {
  return (
    <>
      <div className="py-12 bg-[#FFF9E5] text-black">
        <div className="container flex gap-6 items-center max-lg:flex-col">
          <div className="w-full lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl font-bold">
              Book Free Session with our Admission Mentors
            </h1>
            <p className="text-base mt-4">
              Start your educational journey with confidence! Book a free
              session with our admission experts for personalized advice and
              application assistance. Whether you’re applying to universities,
              or specialized programs, we’re here to help you succeed.
            </p>
            <LeadGenCtaBtn
              pageName="college-compare"
              ctaName="book-free-session"
              className="button-styles white-color mt-12 px-6 py-2 text-white bg-blue rounded-lg"
            >
              Book Free Session
            </LeadGenCtaBtn>
          </div>
          <div className="w-full lg:w-1/2">
            <Image
              src="/images/college-compare/book-session.png"
              aspectRatio={60}
              className="rounded-2xl overflow-hidden"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BookSession;
