import "./CareerDetailsStyles.scss";

const EducationPathway: React.FC<{ pathway: any }> = ({ pathway }) => {
  if (!pathway) return null;

  return (
    <section>
      <div className="container mx-auto">
        <div className="bg-white relative">
          <div className="flex flex-col lg:flex-row justify-between md:pr-8 gap-6">
            <div className="flex flex-col w-full lg:w-4/5">
              <div className="flex max-md:flex-col max-md:w-full">
                <h3 className="text-xl max-md:text-center lg:text-2xl font-semibold text-white bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0">
                  {pathway.title}
                </h3>
              </div>
              <div
                className="text-black text-base leading-7 flex flex-col gap-5 py-5 px-8 educationPathwayList"
                dangerouslySetInnerHTML={{
                  __html: pathway.description as string,
                }}
              ></div>
            </div>
            <div className="flex flex-col items-center justify-center w-full lg:w-1/5 relative bg-[#FFF1FA] max-xl:hidden   ">
              <div className="relative  ">
                <div className="study-abroad-mentor">
                  <img src="/images/course-details/mentor.png" alt="mentor" />
                </div>
                <a
                  href="/mentors"
                  className="w-[220px] h-[38px] absolute bottom-0 left-1/2 transform -translate-x-1/2 mx-auto flex flex-row w-60 font-inter text-white text-xs sm:text-sm md:text-base font-medium md:leading-8 rounded-3xl bg-[#173CBA] group cursor-pointer justify-between items-center p-2"
                >
                  <span>Study Abroad Mentor</span>
                  <img
                    src="/images/rightarrow.svg"
                    className="w-5 h-5 lg:w-8 lg:h-8 group-hover:translate-x-2 transition-transform duration-300"
                    alt="Right Arrow"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EducationPathway;
