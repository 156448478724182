import React, { useState, useEffect } from "react";
import SlideTrack from "../../../components/Slider/SlideTrack";
import { Link } from "react-router-dom";

export default function IndiasTopInstitutions() {
  const imageLinks = [
    {
      id: 1484,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/Bennett.png",
      redirectPath: "/college-details",
      altText: "Bennett university logo",
    },
    {
      id: 1485,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/bml-logo.png",
      redirectPath: "/college-details",
      altText: "BML Munjal University logo",
    },
    {
      id: 1486,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/Gitam-University.png",
      redirectPath: "/college-details",
      altText: "Gitam University logo",
    },
    {
      id: 1494,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/saveetha-university.png",
      redirectPath: "/college-details",
      altText: "Saveetha University logo",
    },
    {
      id: 1487,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/chandigarh-university.png",
      redirectPath: "/college-details",
      altText: "Chandigarh University logo",
    },
    {
      id: 1488,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/amrita-logo.png",
      redirectPath: "/college-details",
      altText: "Amrita University logo",
    },
    {
      id: 1489,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/KIIT-University.webp",
      redirectPath: "/college-details",
      altText: "KIIT University logo",
    },
    {
      id: 1490,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/kl-university-logo.png",
      redirectPath: "/college-details",
      altText: "K L University logo",
    },
    {
      id: 1491,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/LPU-logo.png",
      redirectPath: "/college-details",
      altText: "LPU logo",
    },
    {
      id: 1492,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/manav-rachna.png",
      redirectPath: "/college-details",
      altText: "Manav Rachna University logo",
    },
    {
      id: 1493,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/satya-bhama.png",
      redirectPath: "/college-details",
      altText: "Satyabhama University logo",
    },

    {
      id: 1495,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/UPES-logo.png",
      redirectPath: "/college-details",
      altText: "UPES logo",
    },
    {
      id: 1496,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/Woxsen-logo.png",
      redirectPath: "/college-details",
      altText: "Woxsen University logo",
    },
    {
      id: 1498,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/aditya-univ-logo.jpg",
      redirectPath: "/college-details",
      altText: "Aditya University logo",
    },
  ];

  return (
    <div className="universities-section container">
      <div className="flex flex-col justify-center gap-2">
        <div className="flex max-md:flex-col md:gap-1 justify-center items-center">
          <p className="text-sm font-medium text-black">
            Supported By India’s Top Institutions
          </p>
          <p className="text-sm font-medium text-black">
            (as Application Partner)
          </p>
        </div>
        <SlideTrack
          items={[...imageLinks].map((college) => (
            <img
              key={college.id}
              src={college.imageUrl}
              alt={college.altText}
              className="w-full h-auto"
            />
          ))}
          speed={1}
          itemWidth={150}
          gap={30}
          windowSize={10}
        />
      </div>
    </div>
  );
}
