import React from "react";

const TermsAndConditions = () => {
  const terms = [
    "You can refer multiple friends and win cashback up to ₹1,50,000*.",
    "The referred student must be a new user, not an existing student registered with College Mentor.",
    "The referred student must enroll through College Mentor tie-up Universities.",
    "The referred payments will be made only after the student joins the university and pays the tuition fees.",
    "The referral payment will not be issued if the admission is canceled in the same Academic year.",
    "You will receive 50% cashback on your referral earnings after 45 days from the date the student enrolls in classes. The remaining 50% will be processed after 90 days from the enrollment date.",
    "Referral payments vary depending on the college and destination.",
    "₹1,50,000* payment will be based on the highest referral program benefits.",
    "This offer is personal to the customer and is non-transferable.",
    "This offer is only applicable to digital clients and valid from 01 Jan 2025 to 30 September 2025.",
    "College Mentor may substitute the cashback with an Amazon voucher if we are unable to process the cash transfer.",
  ];
  return (
    <div>
      <div className="flex flex-col px-20 pt-9 pb-20 w-full bg-[#fff] max-md:px-7 max-md:max-w-full">
        <h2 className="self-start text-2xl font-bold leading-none text-[#173CBA]">
          Terms and Conditions
        </h2>

        <div className="w-[840px] max-lg:w-full font-normal text-black text-base mt-7">
          <ul className="list-disc pl-5 space-y-4 text-black">
            {terms.map((term, index) => (
              <li key={index}>{term}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
