import React, { useState, useRef, useEffect } from "react";
import type { SVGProps } from "react";
import "./CustomNavigation.scss";
import TrendIconRight from "../../../../images/blog-article/TrendIconRight.svg";
import TrendIconLeft from "../../../../images/blog-article/TrendIconLeft.svg";
import axios from "axios";
import config from "../../../../util/config";
import { useLocation, useNavigate } from "react-router-dom"; // Use useNavigate for navigation
interface LevelEntry {
  logo: string;
  name: string;
}

interface TopicData {
  stateLevel: LevelEntry[];
  nationalLevel: LevelEntry[];
  privateUniversity: LevelEntry[];
}

interface CustomTabsNavigationBarProps {
  tabs: string[];
  data: TopicData;
}
interface DataSectionProps {
  title: string;
  data: LevelEntry[] | undefined;
}
const CustomNavigation: React.FC<CustomTabsNavigationBarProps> = ({
  tabs,
  data,
}) => {
  // console.log("CustomTabsNavigationBar", tabs,data);
  const [activeTab, setActiveTab] = useState(0);
  const [hover, setHover] = useState<number | null>(null);
  const [isSticky, setIsSticky] = useState(false);
  const menuRef = useRef<HTMLUListElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [title, setTitle] = useState<string>("Engineering");
  const navigate = useNavigate(); 
  const location = useLocation();
  const [datadynamic, setDatadynamic] = useState<any>(null); // Change `any` to a more specific type if needed
  const handleClick = (id: number) => {
    console.log("Tab clicked:", id);
    setActiveTab(id);
    console.log("Tab clicked:", tabs[id]);
    setTitle(tabs[id]);
  };
  console.log(datadynamic);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabName = title;
    if (tabName) {
      searchParams.set("predictor-tools", tabName);
    } else {
      searchParams.delete("predictor-tools"); 
    }
    navigate(`?${searchParams.toString()}`, { replace: true });
  }, [activeTab,location.search, navigate]);
  useEffect(() => {
    if (title) {
      // Fetch data from API
      axios({
        url: config.apiUrl + `/api/college/predictedExamsByStream/${title}`,
        method: "GET",
      })
        .then((response) => {
          setDatadynamic(response.data.data || []);
          console.log(`data FormData of ${title}:`, datadynamic); // Store data in local state
        })
        .catch((error) => {
          console.error("Failed to fetch data", error);
          setDatadynamic([]);
        });
    }
  }, [title]);

  const handleScroll = () => {
    const stickyPosition = 730;
    setIsSticky(window.pageYOffset > stickyPosition);
  };

  const scrollLeft = () => {
    menuRef.current?.scrollBy({ left: -200, behavior: "smooth" });
    checkScrollButtons();
  };

  const scrollRight = () => {
    menuRef.current?.scrollBy({ left: 200, behavior: "smooth" });
    checkScrollButtons();
  };

  const checkScrollButtons = () => {
    if (menuRef.current) {
      setCanScrollLeft(menuRef.current.scrollLeft > 0);
      setCanScrollRight(
        menuRef.current.scrollWidth >
          menuRef.current.clientWidth + menuRef.current.scrollLeft
      );
    }
  };
  useEffect(() => {
    checkScrollButtons();
    window.addEventListener("resize", checkScrollButtons);
    return () => {
      window.removeEventListener("resize", checkScrollButtons);
    };
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const TabContent = () => {
    return (
      <div className="tab-content">
        <div>
          <div className="data-section">
            <>
              <DataSection
                title="State Level Exams"
                data={datadynamic?.stateLevel}
              />
              <DataSection
                title="National Level Exams"
                data={datadynamic?.nationalLevel}
              />
              <DataSection
                title="Private University Exams"
                data={datadynamic?.privateUniversity}
              />
            </>
          </div>
        </div>
      </div>
    );
  };
  const DataSection: React.FC<DataSectionProps> = ({ title, data }) => {
    return (
      <div className="mb-12">
        <h3 className="h3 mt-10 mb-6 text-center md:text-start">
          <span className="px-1 py-1.5 bg-[#173CBA] text-white rounded-sm">
            {title}
          </span>
        </h3>
        <div>
          <ul className="grid-container">
            {data?.map((entry, index) => (
              <a href={`/college-predictor-intermediate/${entry.name}`}>
                <li
                  key={index}
                  className="grid-item flex items-center gap-2 whitespace-nowrap"
                >
                  <img
                    className="w-[29px] h-[29px]"
                    src={entry.logo}
                    alt={entry.name}
                  />
                  <p>{entry.name}</p>
                </li>
              </a>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const tabList = (tabs: any) => {
    return (
      <>
        {tabs?.map((tab: any, index: number) => (
          <li
            key={tab.id}
            className={`
        custom-tab ${
          activeTab === index ? "active" : ""
        } cursor-pointer bg-white`}
            onClick={() => handleClick(index)}
          >
            <button className="tab-name">{tab}</button>
          </li>
        ))}
      </>
    );
  };

  return (
    <div className="">
      <section className="">
        <div className="flex flex-col justify-center  ">
          <div
            className={`w-full bg-white rounded-lg bg-gradient-to-b from-[#173CBA] to-[#06AFA9] py-2 ${
              isSticky
                ? " top-0 z-20 bg-white w-full transition duration-700"
                : "relative transition duration-300"
            }`}
          >
            <div className="container mx-auto flex items-center justify-center  relative lg:px-60">
              <button
                onClick={scrollLeft}
                className={`relative p-2 ${
                  !canScrollLeft ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!canScrollLeft}
              >
                <svg
                  width="31"
                  height="32"
                  viewBox="0 0 31 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="30"
                    height="31"
                    rx="3.5"
                    fill="#00CC99"
                    stroke="#ECFFFF"
                  />
                  <path
                    d="M19.7206 14.7735H10.1951L13.329 11.633C13.4183 11.5443 13.4891 11.4387 13.5375 11.3224C13.5859 11.2061 13.6107 11.0813 13.6107 10.9553C13.6107 10.8293 13.5859 10.7045 13.5375 10.5882C13.4891 10.4719 13.4183 10.3663 13.329 10.2776C13.1505 10.0998 12.9091 10 12.6574 10C12.4058 10 12.1644 10.0998 11.9859 10.2776L7.89941 14.3821C7.54164 14.7385 7.33953 15.2225 7.3374 15.728C7.34204 16.2302 7.54396 16.7103 7.89941 17.0644L11.9859 21.1689C12.0747 21.2573 12.18 21.3273 12.2958 21.3749C12.4116 21.4225 12.5356 21.4467 12.6608 21.4463C12.786 21.4458 12.9098 21.4207 13.0253 21.3723C13.1407 21.3239 13.2456 21.2532 13.3337 21.1642C13.4219 21.0752 13.4918 20.9696 13.5393 20.8536C13.5867 20.7375 13.611 20.6132 13.6105 20.4878C13.6101 20.3624 13.585 20.2383 13.5367 20.1226C13.4884 20.0069 13.4178 19.9018 13.329 19.8135L10.1951 16.6826H19.7206C19.9733 16.6826 20.2156 16.582 20.3942 16.403C20.5728 16.224 20.6732 15.9812 20.6732 15.728C20.6732 15.4749 20.5728 15.2321 20.3942 15.0531C20.2156 14.874 19.9733 14.7735 19.7206 14.7735Z"
                    fill="#ECFFFF"
                  />
                </svg>
              </button>
              <div className="flex items-center justify-center overflow-hidden pl-4 pr-5">
                <ul
                  className="tabs flex overflow-x-auto whitespace-nowrap scrollbar-hide list-none"
                  ref={menuRef}
                >
                  {tabList(tabs)}
                </ul>
              </div>
              <button
                onClick={scrollRight}
                className={`relative p-2 ${
                  !canScrollRight ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!canScrollRight}
              >
                <svg
                  width="31"
                  height="32"
                  viewBox="0 0 31 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="30"
                    height="31"
                    rx="3.5"
                    fill="#00CC99"
                    stroke="white"
                  />
                  <path
                    d="M9.64411 14.7735H19.1697L16.0358 11.633C15.9465 11.5443 15.8756 11.4387 15.8273 11.3224C15.7789 11.2061 15.754 11.0813 15.754 10.9553C15.754 10.8293 15.7789 10.7045 15.8273 10.5882C15.8756 10.4719 15.9465 10.3663 16.0358 10.2776C16.2142 10.0998 16.4557 10 16.7073 10C16.959 10 17.2004 10.0998 17.3789 10.2776L21.4653 14.3821C21.8231 14.7385 22.0252 15.2225 22.0273 15.728C22.0227 16.2302 21.8208 16.7103 21.4653 17.0644L17.3789 21.1689C17.2901 21.2573 17.1847 21.3273 17.0689 21.3749C16.9531 21.4225 16.8291 21.4467 16.7039 21.4463C16.5788 21.4458 16.4549 21.4207 16.3395 21.3723C16.224 21.3239 16.1192 21.2532 16.031 21.1642C15.9428 21.0752 15.873 20.9696 15.8255 20.8536C15.778 20.7375 15.7538 20.6132 15.7542 20.4878C15.7547 20.3624 15.7798 20.2383 15.8281 20.1226C15.8764 20.0069 15.9469 19.9018 16.0358 19.8135L19.1697 16.6826H9.64411C9.39147 16.6826 9.14918 16.582 8.97055 16.403C8.79191 16.224 8.69155 15.9812 8.69155 15.728C8.69155 15.4749 8.79191 15.2321 8.97055 15.0531C9.14918 14.874 9.39147 14.7735 9.64411 14.7735Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div>
            <TabContent />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CustomNavigation;
