/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { IFilterDataItem } from "../../components/filter/components/DesktopFilterMenu";
import Pagination from "../../components/filter/components/Pagination";
import TabSelector from "../../components/filter/components/TabSelector";
import useDebouncedSearch from "../../components/filter/customHooks/useDebouncedSearch";
import useQueryParamPagination from "../../components/filter/customHooks/useQueryParamPagination";
import {
  ISelectedFilter,
  IUpdateSelectedFilter,
} from "../../components/filter/customHooks/useSelectedFilter";
import { FilterCategory } from "../../components/filter/FilterEnum";
import Loader from "../../components/Loader/Loader";
import config from "../../util/config";
import "./CollegeIntermediate.scss";
import { CollegeList } from "./components/CollegeList";
import FiltersSection from "./components/FilterSection";
import { SortBy } from "./components/SortBy";
import { log } from "console";

// Define the Option interface here to match the SortBy component's requirement
interface OptionType {
  name: string;
  count: number;
  key: string;
  isSelected?: boolean;
}

type Category = {
  categoryName: string;
  categoryKey: string;
  count?: number;
  options: OptionType[];
};

type FiltersType = Category[];

const filterCategories: IFilterDataItem = {
  categoryName: "Stream Category",
  categoryKey: "streamCategory",
  type: FilterCategory.SingleSelect,
  options: [
    { name: "Engineering", key: "engineering", count: 0 },
    { name: "Medical", key: "medical", count: 0 },
    { name: "Management", key: "management", count: 0 },
    { name: "Law", key: "law", count: 0 },
    { name: "Liberal Arts", key: "liberalArts", count: 0 },
    { name: "Design", key: "design", count: 0 },
    { name: "Hotel Management", key: "hotelManagement", count: 0 },
  ] as OptionType[],
};

export interface ISelectedFilters {
  [key: string]: string[] | string;
}

export const useFiltersAndTags = () => {
  const [selectedVal, setSelectedVal] = useState<ISelectedFilters>({});
  const [filtersData, setFiltersData] = useState<FiltersType>([]);

  const handleFilterRemove = (category: string, value: string) => {
    setSelectedVal((prev) => {
      let selectedCategory = { ...prev };

      delete selectedCategory[category];

      return selectedCategory;
    });

    setFiltersData((oldFilterData) => {
      return oldFilterData.map((eachCategory) => {
        if (eachCategory?.categoryKey === category) {
          return {
            ...eachCategory,
            options: eachCategory?.options?.map((eachOption) => {
              return {
                ...eachOption,
                isSelected:
                  eachOption?.key === value ? false : eachOption?.isSelected,
              };
            }),
          };
        } else {
          return eachCategory;
        }
      });
    });
  };

  // console.log("params are ", searchParams.get("university"));

  console.log("location is   is ", window.location.search);

  // useEffect(() => {

  // },[])

  return {
    selectedVal,
    filtersData,
    setSelectedVal,
    setFiltersData,
    handleFilterRemove,
  };
};

const CollegeIntermediate = () => {
  const navigate = useNavigate();
  const listingContainerRef = useRef<HTMLDivElement>(null);

  const [intermediateData, setIntermediateData] = useState([]);
  const [selectedTab, setSelectedTab] = useState<string>("");

  const {
    filtersData,
    setFiltersData,
    selectedVal,
    setSelectedVal,
    handleFilterRemove,
  } = useFiltersAndTags();
  const [totalCollegeCount, setTotalCollegeCount] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedSort, setSelectedSort] = useState<string>("featured_colleges");
  const [stream, setStream] = useState("");
  const { searchKeyword, handleKeywordChange, deboundedSearchKeyword } =
    useDebouncedSearch();

  const updateSelectedFilter: IUpdateSelectedFilter = (key, action, value) => {
    setStream(value);
    setSelectedTab(
      filterCategories.options.find((option) => option.key === value)?.name ||
        ""
    );
    setSelectedFilter((prev) => ({
      ...prev,
      singleSelectFilters: {
        ...prev.singleSelectFilters,
        [key]: value,
      },
    }));
    console.log("Updated stream:", value); // Debug updated stream
  };

  const sortOptions =
    filtersData.find((filter) => filter.categoryKey === "sortBy")?.options ||
    [];

  const handleSortChange = (sortKey: string) => {
    setSelectedSort(sortKey);
  };

  const PAGE_SIZE = 10;
  const {
    totalPages,
    currentPage,
    updateTotalCount,
    handlePrevPage,
    handleNextPage,
    handleResetPagination,
  } = useQueryParamPagination(PAGE_SIZE);

  useEffect(() => {
    updateTotalCount(totalCollegeCount || 0);
  }, [totalCollegeCount]);

  useEffect(() => {
    handleResetPagination();
  }, [selectedVal]);

  useEffect(() => {
    console.log("Selected Filters", selectedVal); // Log selectedVal before the API request
    const fetchData = async () => {
      console.log("Selected stream:", stream); // Debug the stream value

      setLoading(true);
      try {
        const response = await axios.post(
          config.apiUrl + "/api/college/college/intermediate-page",
          {
            ...selectedVal,
            sortBy: selectedSort,
            stream: stream,
            offset: currentPage - 1,
            fetchCount: PAGE_SIZE,
            search: deboundedSearchKeyword,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
            },
          }
        );
        setIntermediateData(response.data.data.data);
        if (response.data.data.streams) {
          setFiltersData(response.data.data.streams);
        }

        if (filtersData?.length === 0) {
          setFiltersData(response.data.data.filters);
        }
        setTotalCollegeCount(response.data.data?.totalCollegeCount);
      } catch (error) {
        console.error("Error making API request:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedVal, selectedSort, stream, currentPage, deboundedSearchKeyword]);

  const initialSelectedFilter: ISelectedFilter = {
    singleSelectFilters: {
      "sample-category": "tab1",
    },
    multiSelectFilters: {},
    radioFilters: {},
  };

  const [selectedFilter, setSelectedFilter] = useState<ISelectedFilter>(
    initialSelectedFilter
  );

  const handleClearFilters = () => {
    setSelectedVal({});
    setFiltersData((prevFilters: any) => {
      const updatedFilters = prevFilters.map((filter: any) => ({
        ...filter,
        options: filter.options.map((option: any) => ({
          ...option,
          isSelected: false,
        })),
      }));
      return updatedFilters;
    });

    handleResetPagination();

    if (listingContainerRef.current) {
      listingContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Updated: Use handleFilterChange here

  const clearFilter = () => {
    setSelectedFilter({
      singleSelectFilters: {
        "": "",
      },
      multiSelectFilters: {},
      radioFilters: {},
    });
    setSelectedTab("");
  };

  const isMediumDevice = window.innerWidth >= 768;

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const handleMobileFilter = () => {
    setIsFilterMenuOpen(true);
  };
  const handleMobileFilterClose = () => {
    setIsFilterMenuOpen(false);
  };

  const handleViewOnMaps = () => {
    navigate("/map-view");
  };

  return (
    <>
      <div className="bg-[#F4F7FB] pb-16">
        <div className="college-intermediate-banner-section">
          <div className="bg-gradient-to-r from-[#0B3D8C] to-[#06AFA9]">
            <div className="container max-w-screen-2xl text-white py-8 md:py-16">
              <div className="flex flex-col md:flex-row gap-8">
                <div className="relative w-full md:w-[70%] flex flex-col justify-center ">
                  <div className="md:mb-10">
                    <Breadcrumbs
                      links={[
                        { name: "Home", path: "/" },
                        {
                          name: "List of colleges in India",
                          path: "/colleges/list-of-colleges-in-india",
                        },
                      ]}
                    />
                  </div>
                  <h3 className="text-4xl md:text-5xl min-max-xss:mt-6 min-max-xs:mt-6 font-bold line-clamp-2">
                    {"List of Colleges in India"}
                  </h3>
                  <p className="text-lg md:text-xl mt-4 mb-auto line-clamp-3">
                    {
                      "India offers a diverse range of colleges across various educational levels, from undergraduate to postgraduate and doctoral programs."
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto">
          <div className="flex flex-col">
            <div className="flex gap-6 justify-center pt-12 mb-14">
              <TabSelector
                filterData={filterCategories}
                selectedFilter={selectedFilter}
                updateSelectedFilter={updateSelectedFilter}
                clearFilter={clearFilter}
                color={"green"}
              />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-5 relative">
            <div className="filter-section flex gap-4 md:flex-col lg:w-3/12">
              <div className="map-button hidden lg:flex justify-center md:justify-start mb-4 md:mb-0 w-full">
                <button
                  className="flex flex-row p-1 items-center"
                  onClick={handleViewOnMaps}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33"
                      height="33"
                      viewBox="0 0 33 33"
                      fill="none"
                    >
                      <path
                        d="M16.1717 3.78125H5.15625C4.79158 3.78125 4.44184 3.92612 4.18398 4.18398C3.92612 4.44184 3.78125 4.79158 3.78125 5.15625V27.8438C3.78125 28.2084 3.92612 28.5582 4.18398 28.816C4.44184 29.0739 4.79158 29.2188 5.15625 29.2188H27.8438C28.2084 29.2188 28.5582 29.0739 28.816 28.816C29.0739 28.5582 29.2188 28.2084 29.2188 27.8438V5.15625C29.2188 4.79158 29.0739 4.44184 28.816 4.18398C28.5582 3.92612 28.2084 3.78125 27.8438 3.78125H24.4419"
                        stroke="#00B489"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20.3068 2.31494C18.5647 2.31498 16.894 3.00705 15.6621 4.2389C14.4303 5.47076 13.7383 7.1415 13.7383 8.88359C13.7383 14.0241 18.7665 20.2205 20.0683 21.7389C20.1058 21.7827 20.1524 21.8179 20.2048 21.8419C20.2573 21.866 20.3143 21.8783 20.372 21.878C20.4297 21.8777 20.4866 21.8648 20.5389 21.8403C20.5911 21.8157 20.6373 21.7801 20.6743 21.7359C21.9541 20.2125 26.8756 14.0207 26.8756 8.88359C26.8756 8.02097 26.7057 7.16679 26.3756 6.36984C26.0455 5.57288 25.5616 4.84874 24.9516 4.23879C24.3417 3.62883 23.6175 3.14499 22.8206 2.81489C22.0236 2.4848 21.1694 2.31491 20.3068 2.31494ZM20.3068 11.3384C19.8213 11.3384 19.3466 11.1945 18.9429 10.9247C18.5392 10.655 18.2245 10.2715 18.0387 9.82297C17.8529 9.37438 17.8043 8.88078 17.899 8.40457C17.9938 7.92835 18.2276 7.49093 18.5709 7.1476C18.9143 6.80428 19.3517 6.57048 19.8279 6.47576C20.3041 6.38104 20.7977 6.42967 21.2463 6.61549C21.6949 6.80131 22.0783 7.11597 22.348 7.51969C22.6178 7.92341 22.7617 8.39805 22.7617 8.88359C22.7617 9.53467 22.5031 10.1591 22.0427 10.6194C21.5823 11.0798 20.9579 11.3384 20.3068 11.3384Z"
                        stroke="#00B489"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M23.9182 17.2272L29.2188 16.4104"
                        stroke="#00B489"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.78125 20.3306L17.4073 18.2307"
                        stroke="#00B489"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.3133 19.0159L9.74219 3.78125"
                        stroke="#00B489"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  View on Maps
                </button>
              </div>

              <div
                className={`bg-[#061958] rounded-lg ${
                  isMediumDevice ? "p-4" : ""
                }`}
              >
                {isMediumDevice && (
                  <div className="flex flex-row justify-between border-b border-[#E0E0E0] items-center">
                    <h4 className="text-lg font-bold text-[#fff]">Filter by</h4>

                    <button
                      onClick={handleClearFilters}
                      className="text-[#fff] text-sm font-medium"
                    >
                      Clear All
                    </button>
                  </div>
                )}

                <div>
                  <FiltersSection
                    // filters={filters}
                    filters={filtersData}
                    setSelectedVal={setSelectedVal}
                    setFilters={setFiltersData}
                    mobileFilter={isFilterMenuOpen}
                    handleMobileFilterClose={handleMobileFilterClose}
                  />
                </div>
              </div>
            </div>
            <div className="college-list-container flex-1 flex flex-col gap-5">
              <div
                className={`w-full flex flex-row items-start lg:items-center ${
                  !isMediumDevice ? "justify-between" : ""
                }`}
              >
                {!isMediumDevice && (
                  <button
                    className="bg-white rounded-md p-3 mr-4"
                    onClick={handleMobileFilter}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="filter"
                      className="svg-inline--fa fa-filter fa-lg "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      color="white"
                      stroke="#7D7B89"
                      strokeWidth="40"
                    >
                      <path
                        fill="currentColor"
                        d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"
                      ></path>
                    </svg>
                  </button>
                )}
                <div
                  className={`search-box relative flex flex-col justify-between items-center gap-4 ${
                    !isMediumDevice ? "w-full" : "w-full"
                  }`}
                >
                  <div className="flex max-md:flex-col justify-between w-full max-lg:gap-6">
                    <div className="flex flex-row items-center align-middle relative gap-4 max-lg:w-full">
                      <input
                        type="text"
                        placeholder={
                          isMediumDevice
                            ? "Search your Dream College"
                            : "Search"
                        }
                        className={`search-input ${
                          isMediumDevice ? "w-full lg:w-[378px]" : "w-full"
                        } rounded-md px-5 py-3`}
                        value={searchKeyword}
                        onChange={(e) => handleKeywordChange(e.target.value)}
                      />
                      {searchKeyword && searchKeyword.length > 0 ? (
                        <button
                          className="absolute right-5 top-4"
                          onClick={() => handleKeywordChange("")}
                        >
                          <FontAwesomeIcon
                            icon={faX}
                            size="sm"
                            className="text-grey"
                          />
                        </button>
                      ) : (
                        <button className="absolute right-5 top-3">
                          <FontAwesomeIcon
                            icon={faSearch}
                            size="sm"
                            className="text-grey"
                          />
                        </button>
                      )}
                    </div>
                    {isMediumDevice && (
                      <div className="flex flex-row items-center align-middle gap-4">
                        <button className="flex flex-row text-[#828282] text-sm items-center">
                          <span className="mr-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="14"
                              viewBox="0 0 18 14"
                              fill="none"
                            >
                              <path
                                d="M4.56699 0.292791C4.4166 0.105317 4.21265 0 4 0C3.78735 0 3.5834 0.105317 3.43301 0.292791L0.225123 4.29285C0.0790376 4.48146 -0.00179691 4.73406 3.03165e-05 4.99626C0.00185754 5.25846 0.0862002 5.50928 0.234892 5.69469C0.383584 5.8801 0.584728 5.98527 0.795003 5.98755C1.00528 5.98983 1.20786 5.88903 1.35911 5.70687L3.19803 3.41384V13C3.19803 13.2652 3.28252 13.5196 3.43292 13.7071C3.58332 13.8946 3.7873 14 4 14C4.2127 14 4.41668 13.8946 4.56708 13.7071C4.71748 13.5196 4.80197 13.2652 4.80197 13V3.41384L6.64089 5.70687C6.79214 5.88903 6.99472 5.98983 7.205 5.98755C7.41527 5.98527 7.61642 5.8801 7.76511 5.69469C7.9138 5.50928 7.99814 5.25846 7.99997 4.99626C8.0018 4.73406 7.92096 4.48146 7.77488 4.29285L4.56699 0.292791Z"
                                fill="#828282"
                              />
                              <path
                                d="M14.567 13.7072C14.4166 13.8947 14.2127 14 14 14C13.7873 14 13.5834 13.8947 13.433 13.7072L10.2251 9.70715C10.079 9.51854 9.9982 9.26594 10 9.00374C10.0019 8.74154 10.0862 8.49072 10.2349 8.30531C10.3836 8.1199 10.5847 8.01473 10.795 8.01245C11.0053 8.01017 11.2079 8.11097 11.3591 8.29313L13.198 10.5862V1.00002C13.198 0.734795 13.2825 0.480436 13.4329 0.292897C13.5833 0.105358 13.7873 0 14 0C14.2127 0 14.4167 0.105358 14.5671 0.292897C14.7175 0.480436 14.802 0.734795 14.802 1.00002V10.5862L16.6409 8.29313C16.7921 8.11097 16.9947 8.01017 17.205 8.01245C17.4153 8.01473 17.6164 8.1199 17.7651 8.30531C17.9138 8.49072 17.9981 8.74154 18 9.00374C18.0018 9.26594 17.921 9.51854 17.7749 9.70715L14.567 13.7072Z"
                                fill="#828282"
                              />
                            </svg>
                          </span>
                          Sort By
                        </button>

                        {sortOptions.length > 0 && (
                          <div className="mb-4">
                            <SortBy
                              options={sortOptions}
                              selectedSort={selectedSort}
                              onSortChange={handleSortChange}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="flex max-lg:flex-col justify-start w-full items-center gap-2">
                    {isMediumDevice && (
                      <div className="text-base md:text-lg text-grey shrink-0 min-w-48">
                        {!loading &&
                        totalCollegeCount !== undefined &&
                        totalCollegeCount > 0
                          ? `Showing ${totalCollegeCount} Colleges`
                          : "Loading..."}
                      </div>
                    )}
                    {selectedTab && (
                      <div className="filter-chip flex items-center border rounded-full px-3 py-1 text-sm bg-white shadow-sm">
                        {" "}
                        {selectedTab}{" "}
                        <button
                          onClick={() => {
                            clearFilter();
                            setSelectedTab("");
                          }}
                          className="ml-2 text-gray-500 hover:text-gray-700"
                        >
                          &times;
                        </button>
                      </div>
                    )}
                    <div className="selected-filters-container flex gap-2">
                      {Object.entries(selectedVal).map(([category, values]) =>
                        Array.isArray(values) && values.length > 0 ? (
                          values.map((value) => {
                            if (value && value.length > 0) {
                              return (
                                <div
                                  key={value}
                                  className="filter-chip flex items-center border rounded-full px-3 py-1 text-sm bg-white shadow-sm"
                                >
                                  <span>{value}</span>
                                  <button
                                    onClick={() =>
                                      handleFilterRemove(category, value)
                                    }
                                    className="ml-2 text-gray-500 hover:text-gray-700"
                                  >
                                    &times;
                                  </button>
                                </div>
                              );
                            }
                            return null;
                          })
                        ) : values && values.length > 0 ? (
                          <div className="filter-chip flex items-center border rounded-full px-3 py-1 text-sm bg-white shadow-sm">
                            <span>{values}</span>
                            <button
                              onClick={() =>
                                handleFilterRemove(category, values as string)
                              }
                              className="ml-2 text-gray-500 hover:text-gray-700"
                            >
                              &times;
                            </button>
                          </div>
                        ) : null
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <CollegeList colleges={intermediateData} />
              )}
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                handleNextPage={handleNextPage}
                handlePrevPage={handlePrevPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollegeIntermediate;
