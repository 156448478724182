import React from "react";

const benefitsData = [
  { program: "B.E | B.TECH", benefit: "₹ 5,000 - ₹ 20,000" },
  { program: "MBA | M.Tech", benefit: "₹ 5,000 - ₹ 25,000" },
  { program: "Medical Health Scineces", benefit: "₹7,500 - ₹35,000" },
  { program: "B.Sc | M.Sc", benefit: "₹ 3,000 - ₹ 10,000" },
  { program: "Other Programs", benefit: "₹ 5,000 - ₹ 10,000" },
  { program: "Study Abroad", benefit: "₹ 20,000 - ₹ 35,000" },
];

const ReferralBenefits: React.FC = () => {
  return (
    <div className="flex flex-col pb-12 mt-1.5 w-full  max-md:max-w-full bg-[#001662]">
      <div className="flex relative flex-col items-center px-20 pt-8 pb-16 w-full text-center text-white min-h-[226px] max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          src="/images/referall-benefit.svg"
          alt=""
          className="object-cover absolute inset-0 size-full"
        />
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1440"
          height="226"
          viewBox="0 0 1440 226"
          fill="none"
          className="object-cover absolute inset-0 size-full"
        >
          <path
            d="M185.889 0L0 226H1440L1247.75 0H185.889Z"
            fill="url(#paint0_linear_1_117)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1_117"
              x1="664.615"
              y1="-6.61576e-06"
              x2="664.615"
              y2="200.811"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#00A3FF" />
              <stop offset="1" stop-color="#001662" />
            </linearGradient>
          </defs>
        </svg> */}
        <div className="flex relative flex-col max-w-full w-[639px]">
          <h2 className="text-4xl font-bold leading-none max-md:max-w-full">
            How to Earn Referral Benefits
          </h2>
          <h3 className="self-center mt-5 text-3xl font-light leading-[55px]">
            The Program Wise Benefits *
          </h3>
        </div>
      </div>
      <div className="flex flex-col items-start px-20 xl:mt-12 w-full max-md:px-7 min-max-md:mt-1 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-wrap justify-between gap-10 self-stretch max-md:max-w-full">
          <div className="flex flex-col max-md:max-w-full min-max-md:mx-auto min-max-lg:mx-auto">
            <table className="table-auto w-full border-collapse">
              <thead>
                <tr>
                  <th className="border border-black px-3 py-2 text-base leading-none bg-white text-black w-[234px] text-left">
                    Study India or Abroad
                  </th>
                  <th className="border border-black px-3 py-2 text-sm leading-none bg-white text-black w-[246px] text-left">
                    Referral Benefits
                  </th>
                </tr>
              </thead>
              <tbody>
                {benefitsData.map((item, index) => (
                  <tr key={index}>
                    <td className="border border-black px-3 py-2 text-sm leading-none bg-white text-black w-[234px] text-left">
                      {item.program}
                    </td>
                    <td className="border border-black px-3 py-2 text-sm leading-none bg-white text-black w-[246px] text-left">
                      {item.benefit}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex flex-col  px-9 w-[494px] py-11 my-auto bg-white rounded-xl max-md:px-5 max-md:max-w-full min-max-md:mx-auto min-max-lg:mx-auto">
            <h3 className="text-2xl font-semibold text-[#0c9] rounded-xl max-md:text-[19px] max-md:max-w-full">
              How to Earn upto ₹1,50,000 ?
            </h3>
            <div className="flex gap-5 justify-between mt-7 w-full text-black">
              <div className="flex flex-col font-semibold whitespace-nowrap max-md:text-[12px]">
                <span className="text-base">Estimated</span>
                <div className="flex gap-6 items-center px-1.5 mt-2 w-full text-sm cursor-pointer leading-none rounded-xl bg-[#E3E3E3] min-h-[28px] max-md:mr-1">
                  <span className="gap-1.5 self-stretch px-1.5 py-0.5 my-auto min-h-[21px]">
                    1
                  </span>
                  {/* <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7f4a2a536169b6f0c3433eb11b6d03f1214a9ae6eaeaa17e62be8ff80ffa245e?placeholderIfAbsent=true&apiKey=ca2ef0cfcb7f46a5b3544d3f790c5e8f"
                    alt=""
                    className="object-contain shrink-0 self-stretch my-auto aspect-square w-[18px]"
                  /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                  >
                    <path
                      d="M14.0324 6.20952C13.9626 6.13922 13.8797 6.08343 13.7883 6.04535C13.6969 6.00727 13.5989 5.98767 13.4999 5.98767C13.4009 5.98767 13.3028 6.00727 13.2114 6.04535C13.12 6.08343 13.0371 6.13922 12.9674 6.20952L9.53236 9.64451C9.46264 9.71481 9.37969 9.7706 9.28829 9.80868C9.1969 9.84676 9.09887 9.86636 8.99986 9.86636C8.90085 9.86636 8.80282 9.84676 8.71143 9.80868C8.62004 9.7706 8.53708 9.71481 8.46736 9.64451L5.03236 6.20952C4.96264 6.13922 4.87969 6.08343 4.78829 6.04535C4.6969 6.00727 4.59887 5.98767 4.49986 5.98767C4.40085 5.98767 4.30282 6.00727 4.21143 6.04535C4.12003 6.08343 4.03708 6.13922 3.96736 6.20952C3.82767 6.35004 3.74927 6.54013 3.74927 6.73827C3.74927 6.93641 3.82767 7.1265 3.96736 7.26702L7.40986 10.7095C7.83174 11.1309 8.40361 11.3675 8.99986 11.3675C9.59611 11.3675 10.168 11.1309 10.5899 10.7095L14.0324 7.26702C14.1721 7.1265 14.2505 6.93641 14.2505 6.73827C14.2505 6.54013 14.1721 6.35004 14.0324 6.20952Z"
                      fill="#383838"
                    />
                  </svg>
                </div>
              </div>
              <div className="flex flex-col max-md:text-[12px]">
                <span className="text-base font-semibold">
                  Referral Earnings*
                </span>
                <span className="self-start mt-5 text-xl leading-tight">
                  Earn ₹5,000
                </span>
              </div>
              <button className="flex gap-1.5 self-end mt-10 text-sm font-semibold leading-none text-center text-[#173CBA] ">
                <span className="grow">Refer Now</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="9"
                  viewBox="0 0 9 9"
                  fill="none"
                  className="my-auto "
                >
                  <path
                    d="M0.46967 7.46967C0.176777 7.76256 0.176777 8.23744 0.46967 8.53033C0.762563 8.82322 1.23744 8.82322 1.53033 8.53033L0.46967 7.46967ZM8.75 1C8.75 0.585787 8.41421 0.25 8 0.25L1.25 0.25C0.835786 0.25 0.5 0.585786 0.5 1C0.5 1.41421 0.835786 1.75 1.25 1.75H7.25V7.75C7.25 8.16421 7.58579 8.5 8 8.5C8.41421 8.5 8.75 8.16421 8.75 7.75L8.75 1ZM1.53033 8.53033L8.53033 1.53033L7.46967 0.46967L0.46967 7.46967L1.53033 8.53033Z"
                    fill="#173CBA"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <p className="mt-10 text-sm leading-6 text-white">
          <span className="font-semibold">Note</span>
          <span className=""> : Refer a friend, earn the cash!</span>
        </p>
        <div className="flex flex-col mt-3.5 max-w-full text-base text-white w-[924px]">
          <p className="leading-7 max-md:max-w-full">
            you will receive Rs. 100 for each friend who books their college
            application through College Mentor. Refer a friend to College Mentor
            and earn Rs.100 for each mentor they book."
          </p>
          <p className="mt-3.5 leading-loose max-md:max-w-full">
            For every friend who books a study India or abroad admission program
            through College Mentor, you'll earn ₹. 5,000 - 35,000
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReferralBenefits;
