import React, { useState } from "react";
import "./Accordion.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export interface Option {
  name: string;
  count: number;
  key: string;
  isSelected?: boolean;
}

interface AccordionProps {
  filters: {
    label: string;
    options: Option[];
  };
  isOpen: boolean;
  onFilterChange: (value: string) => void;
  title: string;
}

export const Accordion: React.FC<AccordionProps> = ({
  filters,
  onFilterChange,
  title,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredOptions = filters?.options?.filter((option) => {
    const optionName = option?.name?.toLowerCase() || "";
    const searchLower = searchTerm?.toLowerCase() || "";
    return optionName.includes(searchLower);
  });

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <>
      {filters?.options?.length > 0 && (
        <div className="flex flex-col border-b border-[#E0E0E0] last:border-b-0">
          <div
            className="accordion-header text-[#fff] flex justify-between items-center"
            onClick={toggleAccordion}
          >
            <h4 className="text-base w-full flex flex-row justify-between items-center py-4 min-max-md:px-2 font-bold ">
              {title}
            </h4>
            <FontAwesomeIcon
              icon={isOpen ? faChevronUp : faChevronDown}
              size="sm"
            />
          </div>

          {isOpen && (
            <div className="accordion-content !pt-0 !bg-[#061958]">
              {filters?.options?.length >= 6 && (
                <input
                  type="text"
                  placeholder={`Search by ${filters.label
                    .replace(/_/g, " ")
                    .toLowerCase()
                    .replace(/\b\w/g, (char) => char.toUpperCase())}`}
                  className="w-full mb-4 p-2 rounded-md bg-white text-r-[#ABA9A9] placeholder-[#ABA9A9]"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              )}
              <div className="max-h-48 overflow-y-auto">
                {filteredOptions.map((option) => (
                  <div key={option.key} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      id={option.key}
                      name={filters.label}
                      className={`${
                        option.isSelected ? "checked" : "unchecked"
                      }`}
                      // value={option.isSelected}
                      checked={option.isSelected}
                      onChange={() => onFilterChange(option.key)}
                    />
                    {/* <span>{option.isSelected ? "true" : "false"}</span> */}
                    <label htmlFor={option.key} className="ml-2 text-white">
                      {option.name} {option.count && ` (${option.count})`}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
