import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Mentor } from "../mentors/Mentors";
import MentorCard from "./components/MentorCard";
import MentorDetailsTabs from "./components/MentorDetailsTabs";
import MentorReviews from "./components/MentorReviews";
import MentorDosAndDonts from "./components/MentorDoesAndDonts";
import ArticlesByMentor from "./components/ArticlesByMentor";
import SimilarMentors from "./components/SimilarMentors";
import KnowledgeHubFAQs from "./components/KnowledgeHubFAQs";
import config from "../../util/config";
import Loader from "../../components/Loader/Loader";

const MentorDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [mentor, setMentor] = useState<Mentor | null>(null);
  const [mentorArticles, setMentorArticles] = useState<Mentor | null>(null);
  const [infoResponse, setInfoResponse] = useState([]);

  useEffect(() => {
    const fetchMentorDetails = async () => {
      try {
        const response = await fetch(config.apiUrl + `/api/auth/mentors/${id}`);
        const data = await response.json();
        setMentor(data.mentorResponse);
        setMentorArticles(data.courseResponse.exploreMore.articles);
        setInfoResponse(data.infoResponse || []);
      } catch (error) {
        console.error("Error fetching mentor details:", error);
      }
    };

    fetchMentorDetails();
  }, [id]);

  if (!mentor) {
    return (
      <div className="container">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <MentorCard mentor={mentor} />
      <MentorDetailsTabs infoResponse={infoResponse} />
      <MentorReviews />
      <MentorDosAndDonts />
      <ArticlesByMentor mentorArticles={mentorArticles} />
      <SimilarMentors mentor={mentor} />
      <KnowledgeHubFAQs />
    </div>
  );
};

export default MentorDetails;
