// import { useState } from "react";
// import CustomSwiper from "../../../../components/Slider/CustomSwiper";
// import WebinarCard from "../../../../components/blogs-and-articles/WebinarCard";
// import BlogCard from "../../../../components/blogs-and-articles/BlogCard";
// import VideoCard from "../../../../components/blogs-and-articles/VideoCard";
// import VideoModal from "../../../../components/blogs-and-articles/VideoModal";
// import VisualStory from "../../../../components/VisualStory";
// import LeadGeneration from "../../../../components/LeadGeneration";

// interface BlogsAndArticles {
//   webinar: any[];
//   articles: any[];
//   spotLight: any[];
//   news: any[];
//   visualStories: any[];
// }

// interface WebinarAndArticlesProps {
//   blogsAndArticles: BlogsAndArticles | null;
// }

// const WebinarAndArticles: React.FC<WebinarAndArticlesProps> = ({
//   blogsAndArticles,
// }) => {
//   const [activeTabIndex, setActiveTabIndex] = useState(0);
//   const changeActiveTab = (index: number) => setActiveTabIndex(index);
//   const [leadGenerationPopupIsOpen, setLeadGenerationPopupIsOpen] =
//     useState(false);
//   const [videoModalURL, setVideoModalURL] = useState<string | null>(null);
//   const [visualStoryIndex, setVisualStoryIndex] = useState<number>(-1);

//   if (!blogsAndArticles) return;

//   const isVisualStoryOpen =
//     visualStoryIndex >= 0 &&
//     visualStoryIndex < blogsAndArticles?.visualStories.length;

//   const tabs = [
//     {
//       name: "Articles",
//       content: blogsAndArticles.articles.map((data) => <BlogCard {...data} />),
//     },
//     {
//       name: "Spotlights",
//       content: blogsAndArticles.spotLight.map((data) => (
//         <VideoCard
//           {...data}
//           openVideoModal={() => setVideoModalURL(data.link)}
//         />
//       )),
//     },

//     {
//       name: "Webinar",
//       content: blogsAndArticles.webinar.map((data) => (
//         <WebinarCard
//           {...data}
//           handlePopup={() => setLeadGenerationPopupIsOpen(true)}
//         />
//       )),
//     },
//   ];

//   // {
//   //   name: "News",
//   //   content: blogsAndArticles.news.map((data) => <BlogCard {...data} />),
//   // },
//   // {
//   //   name: "Visual Stories",
//   //   content: blogsAndArticles.visualStories.map((data, index) => (
//   //     <VisualStoryCard
//   //       {...data}
//   //       openVisualStory={() => setVisualStoryIndex(index)}
//   //     />
//   //   )),
//   // }, will add this Sections once data available from API

//   return (
//     <>
//       <div className="mt-12">
//         <div className="flex justify-center text-grey">
//           {tabs.map((tab, index) => (
//             <button
//               className={`text-xs lg:text-sm px-4 py-2 ${
//                 activeTabIndex == index
//                   ? "bg-blue text-white font-semibold"
//                   : ""
//               }`}
//               onClick={() => changeActiveTab(index)}
//             >
//               {tab.name}
//             </button>
//           ))}
//         </div>
//         <div className="bg-blue">
//           <div className="container py-12">
//             <CustomSwiper
//               items={tabs[activeTabIndex].content}
//               navigation={"bottom"}
//               autoplay={true}
//               breakpoints={{
//                 320: { slidesPerView: 1, spaceBetween: 20 },
//                 768: { slidesPerView: 2, spaceBetween: 20 },
//                 1024: { slidesPerView: 3, spaceBetween: 20 },
//                 1280: { slidesPerView: 4, spaceBetween: 30 },
//               }}
//               progressBar={true}
//             />
//           </div>
//         </div>
//       </div>
//       {videoModalURL && (
//         <VideoModal
//           link={videoModalURL}
//           closeModal={() => setVideoModalURL(null)}
//         />
//       )}
//       <VisualStory
//         isOpen={isVisualStoryOpen}
//         slides={
//           (isVisualStoryOpen &&
//             getVisualStorySlides(
//               blogsAndArticles?.visualStories[visualStoryIndex].slides
//             )) ||
//           []
//         }
//         handleClose={() => {
//           setVisualStoryIndex(-1);
//         }}
//       />
//     </>
//   );
// };

// export default WebinarAndArticles;

// const getVisualStorySlides = (
//   slides: {
//     title: string;
//     description: string;
//     image: string;
//   }[]
// ) => {
//   return slides.map((slide) => ({
//     image: slide.image,
//     content: (
//       <div className="p-4">
//         <h1 className="text-xl text-white font-bold text-center">
//           {slide.title}
//         </h1>
//         <p className="mt-2 text-base text-white text-justify">
//           {slide.description}
//         </p>
//       </div>
//     ),
//   }));
// };

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import BlogTab from "../../../../components/courses/ExploreMore/BlogTab";
import config from "../../../../util/config";
import Loader from "../../../../components/Loader/Loader";

const WebinarAndArticles: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabIndicatorStyle, setTabIndicatorStyle] =
    useState<React.CSSProperties>({ marginLeft: 0, width: 0 });
  const [loading, setLoading] = useState(true);
  const [latestNews, setLatestNews] = useState<any[]>([]);
  const [articles, setArticles] = useState<any[]>([]);
  const tabRef = useRef<HTMLButtonElement[]>([]);

  // Fetch data from the API
   const getBlogsAndNews = () => {
    axios
      .get(`${config.apiUrl}/api/blogs/blogsandnews`)
      .then((response) => {
        const data =
          response.data.sections[0]["College And Scholarship"].subcategories;

        // Extract and prepare Latest News
        const fetchedNews = data["Latest News"].results;
        setLatestNews(fetchedNews);

        // Extract and prepare Articles & Blogs
        const fetchedArticles = data["Articles & Blogs"].results;
        setArticles(fetchedArticles);

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
    setIndicatorToActiveTab(index);
  };

  const handleTabMouseEnter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const tabRect = event.currentTarget.getBoundingClientRect();
    const containerRect =
      event.currentTarget.parentElement?.getBoundingClientRect();
    const tabStart = tabRect.left;
    const containerStart = containerRect?.left || 0;
    const distanceTabStartToContainerStart = tabStart - containerStart;
    const halfWidth = tabRect.width / 2;
    const marginLeft =
      distanceTabStartToContainerStart + (tabRect.width - halfWidth) / 2;

    setTabIndicatorStyle({
      marginLeft: `${marginLeft}px`,
      width: `${halfWidth}px`,
    });
  };

  const handleTabMouseLeave = () => {
    setIndicatorToActiveTab(selectedTab);
  };

  const setIndicatorToActiveTab = (index: number) => {
    const tabElement = tabRef.current[index];
    if (!tabElement) return;
    const tabRect = tabElement.getBoundingClientRect();
    const containerRect = tabElement.parentElement?.getBoundingClientRect();
    const tabStart = tabRect.left;
    const containerStart = containerRect?.left || 0;
    const distanceTabStartToContainerStart = tabStart - containerStart;

    setTabIndicatorStyle({
      marginLeft: `${distanceTabStartToContainerStart + tabRect.width / 4}px`,
      width: `${tabRect.width / 2}px`,
    });
  };

  useEffect(() => {
    getBlogsAndNews();
    setIndicatorToActiveTab(selectedTab);
  }, [selectedTab]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="py-16 relative">
      <div className="container mx-auto">
        <div className="flex flex-col gap-4">
          <h3 className="text-3xl font-bold text-black">Explore More</h3>
          <div className="relative">
            <div
              className="tab-container gap-4"
              onMouseLeave={handleTabMouseLeave}
            >
              {["Blogs & Articles"].map((tab, index) => (
                <button
                  key={index}
                  ref={(el) => (tabRef.current[index] = el!)}
                  onClick={() => handleTabClick(index)}
                  onMouseEnter={handleTabMouseEnter}
                  className={`py-2 text-xl hover:text-[#173CBA] transition-all ${
                    selectedTab === index
                      ? "text-[#173CBA] font-bold"
                      : "text-[#A8A8A8]"
                  }`}
                >
                  {tab}
                </button>
              ))}
              <div
                className="absolute bottom-[-4px] h-[6px] bg-[#00C798] rounded-lg transition-all"
                style={tabIndicatorStyle}
              />
            </div>
          </div>
          {selectedTab === 0 && <BlogTab articles={articles} />}{" "}
          {/* Display Blogs & Articles */}
        </div>
      </div>
    </div>
  );
};

export default WebinarAndArticles;
