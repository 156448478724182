import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postCtaData } from "./leadGenThunk";

interface ILeadGen {
  isLoading: boolean;
  isSuccessful: boolean;
  isAlertOpen: boolean;
}

const initialState: ILeadGen = {
  isLoading: false,
  isSuccessful: false,
  isAlertOpen: false,
};

const leadGenSlice = createSlice({
  name: "leadGen",
  initialState,
  reducers: {
    closeAlert: (state) => {
      state.isAlertOpen = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCtaData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postCtaData.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccessful = true;
        state.isAlertOpen = true;
      })
      .addCase(postCtaData.rejected, (state) => {
        state.isLoading = false;
        state.isSuccessful = false;
        state.isAlertOpen = true;
      });
  },
});

export const { closeAlert } = leadGenSlice.actions;

export default leadGenSlice.reducer;
