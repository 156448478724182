import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import config from "../../util/config";
import "./DreamCareers.scss";

export default function DreamCareer({
  animationCheckPointRef,
}: {
  animationCheckPointRef: (el: HTMLDivElement | null) => void;
}) {
  const featuredImagesCarosal = [
    {
      careerDescriptionShort:
        "Agricultural engineers apply technology and science to enhance farming efficiency",
      iconUrl:
        "https://cdncollegementor.blob.core.windows.net/home-page/dream-career/Agricultural Engineer_2309770999.jpg ",
      careerName: "Agricultural Engineer",
      linkTag: "agricultural-engineer",
    },
    {
      careerDescriptionShort:
        "Designs precise prompts to maximize AI model responses.",
      iconUrl:
        "https://cdncollegementor.blob.core.windows.net/home-page/dream-career/AI Prompt Engineer_2458211089.jpg ",
      careerName: "AI Prompt Engineer",
      linkTag: "ai-prompt-engineer",
    },
    {
      careerDescriptionShort:
        "Provides medical care and treatment to diagnose and heal patients.",
      iconUrl:
        " https://cdncollegementor.blob.core.windows.net/home-page/dream-career/Doctor_2466625189.jpg ",
      careerName: "Doctor",
      linkTag: "doctor",
    },
    {
      careerDescriptionShort:
        "Builds and manages businesses, taking on financial risks for innovation.",
      iconUrl:
        " https://cdncollegementor.blob.core.windows.net/home-page/dream-career/Entrepreneur_2492532925.jpg ",
      careerName: "Entrepreneur",
      linkTag: "entrepreneur",
    },
    {
      careerDescriptionShort:
        "Provides legal advice and representation in courts and legal matters.",
      iconUrl:
        " https://cdncollegementor.blob.core.windows.net/home-page/dream-career/Lawyer_2513066565.jpg",
      careerName: "Lawyer",
      linkTag: "lawyer",
    },
    {
      careerDescriptionShort:
        "Flies aircraft, ensuring safe and efficient transportation of passengers and cargo.",
      iconUrl:
        " https://cdncollegementor.blob.core.windows.net/home-page/dream-career/Pilot_1815171710.jpg",
      careerName: " Pilot",
      linkTag: " pilot",
    },
    {
      careerDescriptionShort:
        "Manages financial accounts, audits, and provides expert tax advice.",
      iconUrl:
        " https://cdncollegementor.blob.core.windows.net/home-page/dream-career/Chartered Accountant _2373241609.jpg",
      careerName: "Chartered Accountant",
      linkTag: "chartered-accountant",
    },
    {
      careerDescriptionShort:
        "Studies human behavior and provides therapy for mental well-being.",
      iconUrl:
        "https://cdncollegementor.blob.core.windows.net/home-page/dream-career/Psychologist_2470154533.jpg ",
      careerName: "Psychologist",
      linkTag: "psychologist",
    },
  ];

  const maxCardsToDisplay = 8;

  return (
    <div className="my-[40px] min-h-[53.175rem]">
      <div className="flex flex-col px-5 py-5 w-[1360px] mx-auto max-md:w-full min-max-md:w-full items-center min-max-lg:w-full">
        <div className="w-fit relative">
          <div
            className="absolute -left-24 top-1/2 -translate-y-1/2 h-16 w-16 h-16 w-16"
            ref={animationCheckPointRef}
          ></div>
          <h3 className="text-black text-3xl font-extrabold">Dream Careers</h3>
        </div>

        <div className="grid grid-cols-1 min-max-lg:grid-cols-2 mx-auto xl:grid-cols-4 min-max-md:grid-cols-2 gap-6 max-md:flex max-md:flex-col max-md:gap-3 max-md:mx-auto mt-6">
          {featuredImagesCarosal
            .slice(0, maxCardsToDisplay)
            .map((item: any, index: number) => (
              <Link key={index} to={"/career-details/" + item.linkTag}>
                <div
                  className={`dc-card ${
                    index < 2
                      ? "max-md:flex max-md:flex-col"
                      : "hidden max-md:hidden md:block"
                  }`}
                >
                  <div className="dc-img">
                    <img
                      src={item.iconUrl}
                      alt=""
                      loading="lazy"
                      className="w-[320px h-[275px] rounded-md"
                    />
                  </div>
                  <div className="dc-content text-center">
                    <h3>{item.careerName}</h3>
                    <p>{item.careerDescriptionShort}</p>
                    <button className="arrow-button">
                      View details
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="currentColor"
                        className="arrow-icon"
                      >
                        <path
                          d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                          fill="currentColor"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </Link>
            ))}
        </div>

        <div className="dc-btn-section text-center md:ml-[-30px] ">
          <a
            href="/careers"
            className="arrow-button btn-dc white-color map-mentor button-styles"
          >
            View all careers
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="currentColor"
              className="arrow-icon"
            >
              <path
                d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                fill="currentColor"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}
