import React, { useState } from "react";
import axios from "axios";
import BackgroundPersonalisedGuidance from "../../images/study-abroad/Student/Background-Personalised-Guidance.png";
import { EpRight } from "../study-abroad-landing/Banner/Banner";
import LeadGeneration from "../LeadGeneration";
import config from "../../util/config";
import LeadGenCtaBtn from "../../features/lead-generation/LeadGenCtaBtn";

interface InternationalConsultingProps {
  data: {
    title: string;
    items: {
      checkBoxText: string;
    }[];
    buttonText: string;
  };
}

const InternationalConsulting: React.FC<InternationalConsultingProps> = ({
  data,
}) => {
  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    new Array(data.items.length).fill(false)
  );
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleCheckboxChange = (index: number) => {
    const updatedCheckedItems = checkedItems.map((item, idx) =>
      idx === index ? !item : item
    );
    setCheckedItems(updatedCheckedItems);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const choices = data.items
      .filter((_, index) => checkedItems[index])
      .map((item) => item.checkBoxText);

    // API payload
    const payload = {
      choice: choices.join(", "),
      choiceType: "marketHeadStudyAbroad",
      userId: 4,
    };

    try {
      const response = await axios.post(
        config.apiUrl + "/api/userchoice/addOrUpdate",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("Success:", response.data);
      alert("Choices submitted successfully!");
      setIsSubmitting(false);
      setIsPopupVisible(true);
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error submitting choices:", error);
      alert(
        "An error occurred while submitting your choices. Please try again."
      );
    }
  };

  return (
    <div
      className="py-14 lg:pt-40 lg:pb-9 w-full h-full text-white bg-center bg-no-repeat relative"
      style={{
        backgroundImage: `url("https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/University/study-abroad-university-international.jpg")`,
        backgroundSize: "cover",
      }}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-[#000000] opacity-60 z-0"></div>
      <div className="container mx-auto relative z-10">
        <div className="flex flex-col items-start gap-8 ">
          <h2 className="text-4xl lg:text-5xl font-bold">{data.title}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8 lg:w-4/5">
            {data.items.map((item, index) => (
              <div key={index} className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={checkedItems[index]}
                  onChange={() => handleCheckboxChange(index)}
                  className="w-8 h-8 bg-white flex-shrink-0"
                />
                <p className="text-lg text-white">{item.checkBoxText}</p>
              </div>
            ))}
          </div>
          <LeadGenCtaBtn
            pageName="study-abroad-university"
            ctaName="connect-to-marketing-head"
            className="bg-green hover:bg-blue button-styles py-3.5 px-3 green-arrow-button flex flex-row items-center justify-center gap-2 text-white"
          >
            {data.buttonText} <EpRight className="green-arrow-icon" />
          </LeadGenCtaBtn>
          {/* <button
            onClick={handleSubmit}
            className="bg-green hover:bg-blue button-styles py-3.5 px-3 green-arrow-button flex flex-row items-center justify-center gap-2 text-white"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : data.buttonText}{" "}
            <EpRight className="green-arrow-icon" />
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default InternationalConsulting;
