import { FC, useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { SubmitHandler, useForm } from "react-hook-form";
import OTPInput from "react-otp-input";
import { GetWindowDimensions } from "../../../hooks/window-hooks/WindowHooks";
import axios from "axios";
import config from "../../../util/config";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FormData {
  firstName: string;
  gender: number;
  fatherName: string;
  mobile1: string;
  mobile2: string;
  email: string;
  plus2CollegeName: string;
  board: number;
  scholarshipType: number;
  preferredStream: number;
  preferredState: number;
  preferredFees: number;
}

const scholarshipTypes = [
  { value: "merit-based-scholarships", label: "Merit-Based Scholarships" },
  { value: "government-school-student", label: "Government School Student" },
  { value: "empowering-lgbtq-students", label: "Empowering LGBTQ+ Students" },
  { value: "minority-scholarships", label: "Minority Scholarships" },
  { value: "sports-scholarships", label: "Sports Scholarships" },
  { value: "talent-based-scholarships", label: "Talent-Based Scholarships" },
  {
    value: "disability-scholarships",
    label: "Students With Disability Scholarships",
  },
];

const preferredStreams = [
  { value: "engineering", label: "Engineering" },
  {
    value: "management-and-business-administration",
    label: "Management and Business Administration",
  },
  {
    value: "medicine-and-allied-sciences",
    label: "Medicine and Allied Sciences",
  },
  { value: "law", label: "Law" },
  { value: "animation-and-design", label: "Animation and Design" },
  {
    value: "media-and-mass-communication-and-journalism",
    label: "Media and Mass Communication and Journalism",
  },
  {
    value: "arts-humanities-and-social-sciences",
    label: "Arts, Humanities and Social Sciences",
  },
  { value: "commerce", label: "Commerce" },
  {
    value: "computer-application-and-it",
    label: "Computer Application and IT",
  },
  { value: "pharmacy", label: "Pharmacy" },
  { value: "education", label: "Education" },
  { value: "hospitality-and-tourism", label: "Hospitality and Tourism" },
  { value: "architecture", label: "Architecture" },
  { value: "accounts-and-finance", label: "Accounts & Finance" },
  { value: "medical", label: "Medical" },
];

const indianStates = [
  { value: "andhra-pradesh", label: "Andhra Pradesh" },
  { value: "arunachal-pradesh", label: "Arunachal Pradesh" },
  { value: "assam", label: "Assam" },
  { value: "bihar", label: "Bihar" },
  { value: "chhattisgarh", label: "Chhattisgarh" },
  { value: "goa", label: "Goa" },
  { value: "gujarat", label: "Gujarat" },
  { value: "haryana", label: "Haryana" },
  { value: "himachal-pradesh", label: "Himachal Pradesh" },
  { value: "jharkhand", label: "Jharkhand" },
  { value: "karnataka", label: "Karnataka" },
  { value: "kerala", label: "Kerala" },
  { value: "madhya-pradesh", label: "Madhya Pradesh" },
  { value: "maharashtra", label: "Maharashtra" },
  { value: "manipur", label: "Manipur" },
  { value: "meghalaya", label: "Meghalaya" },
  { value: "mizoram", label: "Mizoram" },
  { value: "nagaland", label: "Nagaland" },
  { value: "odisha", label: "Odisha" },
  { value: "punjab", label: "Punjab" },
  { value: "rajasthan", label: "Rajasthan" },
  { value: "sikkim", label: "Sikkim" },
  { value: "tamil-nadu", label: "Tamil Nadu" },
  { value: "telangana", label: "Telangana" },
  { value: "tripura", label: "Tripura" },
  { value: "uttar-pradesh", label: "Uttar Pradesh" },
  { value: "uttarakhand", label: "Uttarakhand" },
  { value: "west-bengal", label: "West Bengal" },
  {
    value: "andaman-and-nicobar-islands",
    label: "Andaman and Nicobar Islands",
  },
  { value: "chandigarh", label: "Chandigarh" },
  {
    value: "dadra-and-nagar-haveli-and-daman-and-diu",
    label: "Dadra and Nagar Haveli and Daman and Diu",
  },
  { value: "delhi", label: "Delhi" },
  { value: "jammu-and-kashmir", label: "Jammu and Kashmir" },
  { value: "ladakh", label: "Ladakh" },
  { value: "lakshadweep", label: "Lakshadweep" },
  { value: "puducherry", label: "Puducherry" },
];

const preferredFeesOptions = [
  { value: "1-lakh-yearly", label: "1 Lakh Yearly" },
  { value: "1-lakh-2-lakh-yearly", label: "1 Lakh - 2 Lakh Yearly" },
  { value: "2.5-lakh-3.5-lakh-yearly", label: "2.5 Lakh - 3.5 Lakh Yearly" },
  { value: "4+-lakh-yearly", label: "4+ Lakh Yearly" },
];

const RegisterForm: FC<IProps> = ({ isOpen, onClose }) => {
  const { register, handleSubmit, formState, getValues } = useForm<FormData>({
    mode: "onChange",
  });

  const [loading, setLoading] = useState(false);

  const [otpLimitExceeded, setOtpLimitExceeded] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [numberAlreadyUsed, setNumberAlreadyUsed] = useState(false);

  const [otpValue, setOtpValue] = useState("");
  const { width } = GetWindowDimensions();
  const otpValid = /^\d{6}$/.test(otpValue);
  const [accessToken, setAccessToken] = useState<undefined | null | string>(
    undefined
  );
  const [registered, setRegistered] = useState(false);
  const [registrationError, setRegistrationError] = useState(false);

  useEffect(() => {
    setOtpLimitExceeded(false);
    setLoading(false);
    setOtpSent(false);
    setOtpValue("");
    setAccessToken(undefined);
    setRegistered(false);
    setNumberAlreadyUsed(false);
  }, [getValues("mobile1")]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(
        config.apiUrl + "/api/scholarship/submit",
        data,
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setLoading(false);
      if (response.status >= 200 && response.status < 300) {
        setRegistered(true);
      } else setRegistrationError(true);
    } catch (e) {
      setLoading(false);
      setRegistrationError(true);
    }
  };

  const handleSendOtp = async () => {
    setOtpValue("");
    setAccessToken(undefined);
    try {
      setLoading(true);
      const response = await axios.post(
        config.apiUrl + "/api/scholarship/initiate",
        {
          phoneNumber: getValues("mobile1"),
          scholarShipName: "viksitBharatScholarship",
        },
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            "X-Auth-Token":
              "e711724f44c71abf0c2794572472ed6edcea55a351f6428fe67060a692aa9316",
          },
        }
      );
      setLoading(false);
      if (response.status >= 200 && response.status < 300) {
        setOtpSent(true);
      }
    } catch (e) {
      setLoading(false);
      if (
        axios.isAxiosError(e) &&
        e.response?.data?.message == "Otp limit exceeded !!"
      )
        setOtpLimitExceeded(true);
      else if (
        axios.isAxiosError(e) &&
        e.response?.data?.message == "You have already applied"
      )
        setNumberAlreadyUsed(true);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        config.apiUrl + "/api/scholarship/validate-otp",
        {
          mobileNumber: getValues("mobile1"),
          otp: otpValue,
        },
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      console.log(response);
      if (response?.data?.data?.body?.accessToken) {
        setAccessToken(response.data?.data?.body?.accessToken);
      } else setAccessToken(null);
    } catch (e) {
      setLoading(false);
    }
  };

  const nameField = (
    <div className="flex flex-col w-72">
      <label>Name</label>
      <input
        {...register("firstName", { required: "Name is required" })}
        placeholder="Name"
        className="border border-grey-4 rounded px-2 py-1"
      />
      {formState.errors.firstName ? (
        <p style={{ color: "red" }}>
          {formState.errors.firstName.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const fatherNameField = (
    <div className="flex flex-col w-72">
      <label>Father's Name</label>
      <input
        {...register("fatherName", { required: "Father's name is required" })}
        placeholder="Father's Name"
        className="border border-grey-4 rounded px-2 py-1"
      />
      {formState.errors.fatherName ? (
        <p style={{ color: "red" }}>
          {formState.errors.fatherName.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const emailField = (
    <div className="flex flex-col w-72">
      <label>Email</label>
      <input
        {...register("email", {
          required: "Email is required",
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            message: "Invalid email address",
          },
        })}
        placeholder="Email"
        className="border border-grey-4 rounded px-2 py-1"
      />
      {formState.errors.email ? (
        <p style={{ color: "red" }}>
          {formState.errors.email.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const genderField = (
    <div className="flex flex-col w-72">
      <label>Gender</label>
      <div className="flex gap-4 mt-2">
        <label className="flex gap-1 items-center">
          <input
            type="radio"
            value="male"
            {...register("gender", { required: "Gender is required" })}
          />
          Male
        </label>
        <label className="flex gap-1 items-center">
          <input
            type="radio"
            value="female"
            {...register("gender", { required: "Gender is required" })}
          />
          Female
        </label>
        <label className="flex gap-1 items-center">
          <input
            type="radio"
            value="others"
            {...register("gender", { required: "Gender is required" })}
          />
          Others
        </label>
      </div>
      {formState.errors.gender ? (
        <p style={{ color: "red" }}>
          {formState.errors.gender.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const mobileNumber1Field = (
    <div className="flex flex-col w-72">
      <label>Mobile Number (primary)</label>
      <input
        {...register("mobile1", {
          required: "Mobile number is required",
          pattern: {
            value: /^[0-9]{10}$/,
            message: "Invalid mobile number (10 digits required)",
          },
        })}
        placeholder="Mobile Number"
        className="border border-grey-4 rounded px-2 py-1"
      />
      {formState.errors.mobile1 ? (
        <p style={{ color: "red" }}>
          {formState.errors.mobile1.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const mobileNumber2Field = (
    <div className="flex flex-col w-72">
      <label>Mobile Number (secondary)</label>
      <input
        {...register("mobile2", {
          required: "Mobile number 2 is required",
          pattern: {
            value: /^[0-9]{10}$/,
            message: "Invalid mobile number (10 digits required)",
          },
        })}
        placeholder="Mobile Number"
        className="border border-grey-4 rounded px-2 py-1"
      />
      {formState.errors.mobile2 ? (
        <p style={{ color: "red" }}>
          {formState.errors.mobile2.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const twelfthCollegeNameField = (
    <div className="flex flex-col w-72">
      <label>12th College Name</label>
      <input
        {...register("plus2CollegeName", {
          required: "12th College Name is required",
          minLength: {
            value: 3,
            message: "College name must be at least 3 characters",
          },
          maxLength: {
            value: 100,
            message: "College name cannot exceed 100 characters",
          },
        })}
        placeholder="12th College Name"
        className="border border-grey-4 rounded px-2 py-1"
      />
      {formState.errors["plus2CollegeName"] ? (
        <p style={{ color: "red" }}>
          {formState.errors["plus2CollegeName"].message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const boardDropdown = (
    <div className="flex flex-col w-72">
      <label>Board</label>
      <select
        {...register("board", {
          required: "Please select a board",
        })}
        className="border border-grey-4 rounded px-2 py-1"
      >
        <option value="">Select Board</option>
        <option value="state-board">State Board</option>
        <option value="cbse">CBSE</option>
        <option value="icse">ICSE</option>
        <option value="igcse">IGCSE</option>
        <option value="ib">IB</option>
        <option value="nios">NIOS</option>
      </select>
      {formState.errors.board ? (
        <p style={{ color: "red" }}>
          {formState.errors.board.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const scholarshipTypeDropdown = (
    <div className="flex flex-col">
      <label>Scholarship Type</label>
      <select
        {...register("scholarshipType", {
          required: "Please select a scholarship type",
        })}
        className="border border-grey-4 rounded px-2 py-1 w-72"
      >
        <option value="">Select Type</option>
        {scholarshipTypes.map((type) => (
          <option key={type.value} value={type.value}>
            {type.label}
          </option>
        ))}
      </select>
      {formState.errors.scholarshipType ? (
        <p style={{ color: "red" }}>
          {formState.errors.scholarshipType.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const preferredStreamDropdown = (
    <div className="flex flex-col">
      <label>Preferred Stream</label>
      <select
        {...register("preferredStream", {
          required: "Please select a preferred stream",
        })}
        className="border border-grey-4 rounded px-2 py-1 w-72"
      >
        <option value="">Select Stream</option>
        {preferredStreams.map((stream) => (
          <option key={stream.value} value={stream.value}>
            {stream.label}
          </option>
        ))}
      </select>
      {formState.errors.preferredStream ? (
        <p style={{ color: "red" }}>
          {formState.errors.preferredStream.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const preferredStateDropdown = (
    <div className="flex flex-col">
      <label>Preferred State</label>
      <select
        {...register("preferredState", {
          required: "Please select a preferred state",
        })}
        className="border border-grey-4 rounded px-2 py-1 w-72"
      >
        <option value="">Select State</option>
        {indianStates.map((state) => (
          <option key={state.value} value={state.value}>
            {state.label}
          </option>
        ))}
      </select>
      {formState.errors.preferredState ? (
        <p style={{ color: "red" }}>
          {formState.errors.preferredState.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const preferredFeesDropdown = (
    <div className="flex flex-col">
      <label>Preferred Fees</label>
      <select
        {...register("preferredFees", {
          required: "Please select a preferred fee range",
        })}
        className="border border-grey-4 rounded px-2 py-1 w-72"
      >
        <option value="">Select Fees</option>
        {preferredFeesOptions.map((fee) => (
          <option key={fee.value} value={fee.value}>
            {fee.label}
          </option>
        ))}
      </select>
      {formState.errors.preferredFees ? (
        <p style={{ color: "red" }}>
          {formState.errors.preferredFees.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {registered ? (
        <div className="bg-white p-4 lg:p-12 rounded-lg max-h-[80vh] overflow-y-auto text-green">
          Registered successfully!
        </div>
      ) : registrationError ? (
        <p>Please enter all necessary data for OTP verification</p>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white p-4 lg:p-12 rounded-lg max-h-[90vh] overflow-y-auto"
        >
          <h1 className="text-xl font-bold text-blue text-center">
            Register now
          </h1>
          <div className="flex max-md:flex-col lg:gap-8 lg:pt-2">
            {nameField}
            {fatherNameField}
          </div>
          <div className="flex max-md:flex-col lg:gap-8 lg:pt-2">
            {emailField}
            {genderField}
          </div>
          <div className="flex max-md:flex-col lg:gap-8 lg:pt-2">
            {mobileNumber1Field}
            {mobileNumber2Field}
          </div>
          <div className="flex max-md:flex-col lg:gap-8 lg:pt-2">
            {twelfthCollegeNameField}
            {boardDropdown}
          </div>
          <div className="flex max-md:flex-col lg:gap-8 lg:pt-2">
            {scholarshipTypeDropdown}
            {preferredStreamDropdown}
          </div>
          <div className="flex max-md:flex-col lg:gap-8 lg:pt-2">
            {preferredStateDropdown}
            {preferredFeesDropdown}
          </div>
          {!formState.isValid ? (
            <p>Please enter all necessary data for OTP verification</p>
          ) : loading ? (
            <div className="loader"></div>
          ) : numberAlreadyUsed ? (
            <p className="text-red">
              This number is already used for application
            </p>
          ) : otpLimitExceeded ? (
            <p className="text-red">
              OTP Limit Exceeded! Please try again after some time
            </p>
          ) : accessToken ? (
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-blue px-4 py-1 text-white rounded"
              >
                Submit
              </button>
            </div>
          ) : !otpSent ? (
            <div className="flex justify-center">
              <button
                className="bg-blue px-4 py-1 text-white rounded"
                onClick={handleSendOtp}
              >
                Send OTP
              </button>
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <p>Enter OTP sent to your primary mobile number</p>
              <OTPInput
                value={otpValue}
                onChange={setOtpValue}
                numInputs={6}
                renderSeparator={false}
                renderInput={(props) => <input {...props} className="mr-2" />}
                inputStyle={{
                  border: "none",
                  justifyContent: "center",
                  borderRadius: "8px",
                  width: width > 700 ? "54px" : "34px",
                  height: width > 700 ? "54px" : "34px",
                  fontSize: "1.5rem",
                  fontWeight: 800,
                  backgroundColor: "#eee",
                  color: "#000",
                  display: "flex",
                  // caretColor: "blue",
                  outline: "none",
                }}
              />
              {accessToken === null && <p>Invalid OTP! Please check.</p>}
              <div className="flex gap-4">
                {otpValid && (
                  <button
                    className="w-fit bg-blue px-4 py-1 text-white rounded"
                    onClick={handleVerifyOtp}
                  >
                    Verify OTP
                  </button>
                )}
                <button className="text-blue" onClick={handleSendOtp}>
                  Resend OTP
                </button>
              </div>
            </div>
          )}
        </form>
      )}
    </Modal>
  );
};

export default RegisterForm;
