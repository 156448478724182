import { useState } from "react";
import "./Banner.scss";
import QuizWidget, {QuizQuestion} from "../../../../components/courses/QuizWidget";
import Modal from "../../../../components/Modal";
import { fromTheme } from "tailwind-merge";
const Banner = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [mapMyCollegeModalIsOpen, setMapMyCollegeModalIsOpen] = useState(false);

  const openPopup = () => {
    setMapMyCollegeModalIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };
  const MapMyCollegePopup = ({
    isOpen,
    onClose,
  }: {
    isOpen: boolean;
    onClose: () => void;
  }) => {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <QuizWidget
          questions={quizQuestions}
          destinationLink=""
        />
      </Modal>
    );
  };
  
  const quizQuestions: QuizQuestion[] = [
    {
      question: "What subjects did you enjoy most in 12th grade?",
      options: [
        {
          answer: "Mathematics and Physics",
          suggestion:
            "Consider Engineering, Computer Science, or Physical Sciences.",
        },
        {
          answer: "Biology and Chemistry",
          suggestion:
            "Look into Medicine, Biotechnology, or Environmental Science.",
        },
        {
          answer: "Economics and Business Studies",
          suggestion: "Explore Economics, Commerce, or Business Administration.",
        },
        {
          answer: "Literature and History",
          suggestion: "Consider Literature, Arts, Journalism, or History.",
        },
      ],
    },
    {
      question: "How long are you willing to study before starting your career?",
      options: [
        {
          answer: "3-4 years",
          suggestion:
            "Courses like Engineering, Commerce, Computer Science, Arts.",
        },
        {
          answer: "5-6 years",
          suggestion: "Courses like Medicine, Law, Architecture.",
        },
        {
          answer: "Flexible",
          suggestion:
            "Combination programs or dual degrees in Business, Law, or research-focused fields.",
        },
      ],
    },
    {
      question:
        "Are you interested in courses that offer high international mobility?",
      options: [
        {
          answer: "Yes",
          suggestion:
            "Consider Business Administration, Engineering, Medicine, IT.",
        },
        {
          answer: "Maybe",
          suggestion: "Explore Environmental Science, Social Work, Education.",
        },
        {
          answer: "Not a priority",
          suggestion:
            "Consider Regional Studies, Public Administration, Indian Law.",
        },
      ],
    },
    {
      question: "What kind of impact do you want your career to have?",
      options: [
        {
          answer: "Innovation and technology advancement",
          suggestion: "Consider Computer Science, Engineering, Data Science.",
        },
        {
          answer: "Health and well-being of others",
          suggestion: "Consider Medicine, Nursing, Public Health.",
        },
        {
          answer: "Social and community development",
          suggestion: "Consider Psychology, Social Work, Public Policy.",
        },
        {
          answer: "Creativity and cultural enrichment",
          suggestion: "Consider Fine Arts, Journalism, Media Studies.",
        },
      ],
    },
    {
      question: "What is your interest level in working with data and analytics?",
      options: [
        {
          answer: "High",
          suggestion: "Consider Data Science, Economics, Actuarial Science.",
        },
        {
          answer: "Moderate",
          suggestion: "Consider Business Analytics, Marketing, Social Sciences.",
        },
        {
          answer: "Low",
          suggestion: "Consider Performing Arts, Humanities, Social Work.",
        },
      ],
    },
    {
      question: "What kind of course duration suits your learning style?",
      options: [
        {
          answer: "Short-term courses",
          suggestion:
            "Consider Diplomas in Web Design, Animation, Culinary Arts.",
        },
        {
          answer: "Bachelor’s degree (3-4 years)",
          suggestion: "Consider B.A., B.Sc., B.Com. in various fields.",
        },
        {
          answer: "Professional degree with extended training",
          suggestion: "Consider Medicine, Architecture, Law.",
        },
      ],
    },
    {
      question:
        "Are you looking for a course with a structured curriculum or a flexible one with many electives?",
      options: [
        {
          answer: "Structured",
          suggestion: "Consider Law, Engineering, Medicine.",
        },
        {
          answer: "Flexible",
          suggestion: "Consider Liberal Arts, Humanities, Business.",
        },
      ],
    },
    {
      question:
        "What type of work-life balance do you envision after your studies?",
      options: [
        {
          answer: "Intense, high-responsibility roles",
          suggestion: "Consider Medicine, Law, Aerospace Engineering.",
        },
        {
          answer: "Balanced, 9-5 jobs",
          suggestion: "Consider Business, Information Technology, Education.",
        },
        {
          answer: "Flexible or freelance work",
          suggestion:
            "Consider Graphic Design, Freelance Writing, Digital Marketing.",
        },
      ],
    },
    {
      question:
        "Are you comfortable with high-pressure, fast-paced environments?",
      options: [
        {
          answer: "Yes",
          suggestion:
            "Consider Finance, Media, Healthcare (especially emergency services).",
        },
        {
          answer: "Sometimes",
          suggestion: "Consider Business Management, Marketing, Social Sciences.",
        },
        {
          answer: "No",
          suggestion: "Consider Teaching, Research, Environmental Studies.",
        },
      ],
    },
    {
      question:
        "What’s your interest in courses that involve significant teamwork and collaboration?",
      options: [
        {
          answer: "High",
          suggestion: "Consider Business, Engineering, Social Work.",
        },
        {
          answer: "Moderate",
          suggestion: "Consider Healthcare, Education, Environmental Science.",
        },
        {
          answer: "Low",
          suggestion:
            "Consider Literature, Mathematics, Research-oriented fields.",
        },
      ],
    },
    {
      question:
        "What’s your preference for a career that includes travel or fieldwork?",
      options: [
        {
          answer: "Regular travel",
          suggestion:
            "Consider Geology, Environmental Science, Hospitality, International Business.",
        },
        {
          answer: "Occasional travel",
          suggestion: "Consider Marketing, Journalism, Consultancy.",
        },
        {
          answer: "Stationary",
          suggestion: "Consider Computer Science, Finance, Local Governance.",
        },
      ],
    },
    {
      question: "How important is creativity in your future career?",
      options: [
        {
          answer: "Very important",
          suggestion:
            "Consider Design, Media Studies, Architecture, Performing Arts.",
        },
        {
          answer: "Somewhat important",
          suggestion: "Consider Marketing, Business Development, Engineering.",
        },
        {
          answer: "Not a priority",
          suggestion: "Consider Accounting, Data Analysis, Technical Writing.",
        },
      ],
    },
    {
      question:
        "Are you interested in a career that combines your passion for sports with business skills?",
      options: [
        {
          answer: "Yes",
          suggestion:
            "Consider Bachelor’s in Sports Management, combining sports, business, and marketing.",
        },
        {
          answer: "Somewhat",
          suggestion: "Consider broader business-focused programs.",
        },
        {
          answer: "No",
          suggestion: "Consider other fields outside sports management.",
        },
      ],
    },
    {
      question:
        "Do you enjoy creative storytelling and the idea of working in television, film, or digital media?",
      options: [
        {
          answer: "Yes",
          suggestion:
            "Consider Bachelor’s in Media Studies or Mass Communication.",
        },
        {
          answer: "Somewhat",
          suggestion: "Explore other creative fields with a broader focus.",
        },
        {
          answer: "No",
          suggestion: "Consider analytical or structured work fields.",
        },
      ],
    },
    {
      question:
        "Are you inclined towards fashion, styling, and creating innovative designs?",
      options: [
        {
          answer: "Absolutely",
          suggestion:
            "Consider Bachelor’s in Fashion Design or Fashion Technology.",
        },
        { answer: "Somewhat", suggestion: "Consider broader creative fields." },
        {
          answer: "Not really",
          suggestion: "Explore other arts-related fields.",
        },
      ],
    },
    {
      question: "How interested are you in advocating for others?",
      options: [
        {
          answer: "Very interested",
          suggestion: "Consider Bachelor of Laws (LL.B.) or BA in Law.",
        },
        { answer: "Interested", suggestion: "Explore other social sciences." },
        { answer: "Not at all", suggestion: "Consider fields outside of law." },
      ],
    },
    {
      question:
        "Do you have a strong interest in aviation, physics, and the technicalities of flying aircraft?",
      options: [
        {
          answer: "Yes",
          suggestion: "Consider Aviation or Pilot Training Program.",
        },
        { answer: "Somewhat", suggestion: "Consider broader technical fields." },
        { answer: "No", suggestion: "Consider grounded professions." },
      ],
    },
    {
      question:
        "Are you interested in working on nuclear energy, technology, and safety?",
      options: [
        {
          answer: "Yes",
          suggestion:
            "Consider Bachelor’s in Nuclear Engineering or Nuclear Physics.",
        },
        { answer: "Somewhat", suggestion: "Explore broader engineering fields." },
        {
          answer: "Not really",
          suggestion: "Consider fields outside nuclear science.",
        },
      ],
    },
    {
      question:
        "How interested are you in understanding human behavior, emotions, and mental health?",
      options: [
        {
          answer: "Very interested",
          suggestion: "Consider Bachelor’s in Psychology.",
        },
        { answer: "Somewhat", suggestion: "Explore social sciences." },
        { answer: "Not at all", suggestion: "Consider other fields." },
      ],
    },
    {
      question:
        "Do you enjoy studying multiple disciplines and want a broad-based education with flexibility?",
      options: [
        { answer: "Yes", suggestion: "Consider Bachelor’s in Liberal Arts." },
        { answer: "Somewhat", suggestion: "Consider career-focused programs." },
        { answer: "No", suggestion: "Consider specialized fields." },
      ],
    },
    {
      question:
        "Are you interested in visual arts, creating artwork, and expressing yourself through fine arts?",
      options: [
        {
          answer: "Absolutely",
          suggestion: "Consider Bachelor’s in Fine Arts (BFA).",
        },
        { answer: "Somewhat", suggestion: "Explore broader creative fields." },
        { answer: "Not really", suggestion: "Explore structured careers." },
      ],
    },
    {
      question:
        "Do you enjoy working with numbers, analyzing financial trends, and managing business accounts?",
      options: [
        {
          answer: "Yes",
          suggestion:
            "Consider Bachelor’s in Commerce (B.Com.) or Bachelor’s in Finance.",
        },
        { answer: "Somewhat", suggestion: "Explore broader business education." },
        {
          answer: "Not really",
          suggestion: "Consider creative or analytical fields.",
        },
      ],
    },
  ];
  return (
    <section className="relative bg-gradient-to-b from-[#EDFCFF] to-[#89C9ED] md:from-transparent md:to-[#89C9ED]">
      <div
        className="hidden md:block absolute inset-0 flex items-center justify-center"
        style={{
          backgroundImage: 'url(/images/college-predictor/banner.png)',
          backgroundSize: 'contain', 
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <div className="relative flex flex-col items-center justify-center">

        <div className="container pl-8 md:pl-0 flex flex-col md:flex-row items-center md:justify-between py-12 lg:py-20 px-4">
          <div className="md:w-[50%] flex flex-col">
            <div className="predictor-heading">
              Find Your Perfect College Fit
              <br />
              Predict, Plan, and Succeed!
            </div>
            <div className="predictor-subheading mb-4 md:pr-40 lg:pr-60 pt-4">
              Get tailored recommendations and make smart choices with our College Predictor and Rank Predictor tools.
            </div>
            <div className="predictor-para">
              <p>
                Step 1: Choose your Exam <br />
                Step 2: Provide Your Details <br />
                Step 3: Predict Your Dream College and course
              </p>
            </div>
            <div>
              <button 
                onClick={openPopup}
                className="mt-4 w-[314px] h-[32px] flex-shrink-0 rounded-[5px] bg-[#0C9] text-white flex items-center justify-center border-none cursor-pointer"
              >
                Predict My Rank in 2 minutes&nbsp;&nbsp;&nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" className="arrow-svg" width="29" height="12" viewBox="0 0 29 12" fill="none">
                  <path d="M28.783 6.53033C29.0758 6.23744 29.0758 5.76256 28.783 5.46967L24.01 0.696698C23.7171 0.403805 23.2422 0.403805 22.9493 0.696698C22.6564 0.989591 22.6564 1.46446 22.9493 1.75736L27.192 6L22.9493 10.2426C22.6564 10.5355 22.6564 11.0104 22.9493 11.3033C23.2422 11.5962 23.7171 11.5962 24.01 11.3033L28.783 6.53033ZM0.157898 6.75L28.2526 6.75L28.2526 5.25L0.157898 5.25L0.157898 6.75Z" fill="white"/>
                </svg>
              </button>

              {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="bg-white p-4 rounded shadow-lg">
                    <h2 className="text-lg font-bold">Prediction Results</h2>
                    <p>Your rank prediction will appear here.</p>
                    <button 
                      onClick={closePopup}
                      className="mt-2 p-2 bg-red-500 text-white rounded"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="block md:hidden">
          <img src="/images/college-predictor/banner2.png" alt="Banner"/>
        </div>
        <>
        <MapMyCollegePopup
        isOpen={mapMyCollegeModalIsOpen}
        onClose={() => {
          setMapMyCollegeModalIsOpen(false);
        }}></MapMyCollegePopup></>
      </div>
      
    </section>
  );
};

export default Banner;
