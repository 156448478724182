import { Link } from "react-router-dom";

const adBanner =
  "https://cdncollegementor.blob.core.windows.net/cm-scholarship/viksit-bharat-sch-banner.jpg";
const logo =
  "https://cdncollegementor.blob.core.windows.net/cm-scholarship/viksit-bharat-sch-logo.png";

const ViksitBharatScholarshipAd = () => {
  return (
    <Link
      to="/college-mentor-scholarship"
      className="w-fit p-4 mx-auto my-6 flex max-md:flex-col items-center justify-center border border-grey-4 rounded-lg shadow-lg"
    >
      <div className="shrink-1">
        <img src={logo} alt="scholarship logo" />
      </div>
      <div className="shrink-1">
        <img src={adBanner} alt="short description about scholarship" />
      </div>
    </Link>
  );
};

export default ViksitBharatScholarshipAd;
