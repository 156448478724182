import React from "react";

const CollegeMentorServices = () => {
  return (
    <div className="px-20 py-10 w-full bg-[#EEF2FF] max-lg:px-7 max-lg:w-full my-6">
      <div className="flex gap-5 max-lg:flex-col-reverse">
        <div className="flex flex-col w-[51%] max-lg:ml-0 max-lg:w-full">
          <img
            src="https://cdncollegementor.blob.core.windows.net/cm-scholarship/college-mentor-services.jpg"
            alt="a diagram showing mentoring and its related aspects like success, direction, goals, coaching, support, growth, advice and training."
            className="object-contain grow w-full rounded-xl aspect-[1.45] max-lg:mt-10 max-lg:max-w-full"
          />
        </div>
        <div className="flex flex-col space-y-6 ml-5 w-[49%] max-lg:ml-0 max-lg:w-full my-auto leading-[30px]">
          <h3 className="text-[#173CBA] text-[48px] font-light max-lg:text-[32px]">
            College Mentor Services
          </h3>
          <div>
            <span className="text-black text-[24px] font-normal">
              Valued at ₹ 5,000 to ₹ 7,500, including
            </span>
            <ul className="list-disc pl-4 p-3">
              <li>Six mentorship sessions</li>
              <li>Counseling</li>
              <li>One free application to any private deemed university</li>
              <li>Free access to all College Mentor services for six months</li>
              <li>College Mentor Career Book</li>
            </ul>
            <span>
              <strong>Note :</strong> All participants in the Scholarship Test
              will receive a Digital Certificate acknowledging their
              participation.
            </span>
            <br />
            <span>
              <strong>Verification Process :</strong> Winners selecting partner
              universities must provide required documents and fees to confirm
              admission. Failure to do so may result in forfeiture of the award.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeMentorServices;
