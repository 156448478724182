import { combineReducers, configureStore } from "@reduxjs/toolkit";
import collegeLanding from "./slices/collegeLanding";
import predictorLanding from "./slices/predictorLanding";
import collegeCompare from "../screens/college-compare/collegeCompareSlice";
import blogSlice from "./slices/blogSlice";
import collegePredictorIntermediate from "../screens/college-predictor/intermediate/collegePredictorIntermediateSlice";
import auth from "./slices/authSlice";
import registrationFormSlice from "./slices/registrationFormSlice";
import filter from "./slices/filterSlice";
import myProfile from "../features/dashboard/my-profile/myProfileSlice";
import scholarshipSlice from "../screens/scholarship/landing/scholarshipSlice";
import educationLoan from "../screens/scholarship/educationLoanSlice";
import leadGen from "../features/lead-generation/leadGenSlice";

const rootReducer = combineReducers({
  collegeLanding,
  predictorLanding,
  collegeCompare,
  blogSlice,
  collegePredictorIntermediate,
  auth,
  registrationFormSlice,
  filter,
  myProfile,
  scholarship: scholarshipSlice,
  educationLoan,
  leadGen,
});

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
