import React, { useEffect, useState } from "react";
import dm1 from "../../images/dream-mentor/dm1.png";
import dm2 from "../../images/dream-mentor/dm2.png";
import dm3 from "../../images/dream-mentor/dm3.png";
import dm4 from "../../images/dream-mentor/dm4.png";
import dm5 from "../../images/dream-mentor/dm5.png";
import dm6 from "../../images/dream-mentor/dm6.png";
import arrowCircle from "../../images/dream-mentor/arrow-circle.png";
import arrowRight from "../../images/dream-mentor/arrow-right.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import "./Dream-mentor.scss";
import { Link, useNavigate } from "react-router-dom";
import config from "../../util/config";
export interface Mentor {
  id: number;
  name: string;
  role: string;
  experience: string;
  worksOn: string | null;
  imageUrl: string;
  location: string | null;

  featured: boolean;
}
export const dreamImagesCarosal = [
  {
    dreamImgCarosal: dm1,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
  {
    dreamImgCarosal: dm2,
    name: "Jenny wilson",
    details: "Vocie Over Artist , Teacher ,Madyapradesh",
  },
  {
    dreamImgCarosal: dm3,
    name: "Robert FOX",
    details: "Vocie Over Artist ,Teacher, Madyapradesh",
  },
  {
    dreamImgCarosal: dm4,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
  {
    dreamImgCarosal: dm5,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
  {
    dreamImgCarosal: dm1,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
  {
    dreamImgCarosal: dm2,
    name: "Jenny wilson",
    details: "Vocie Over Artist , Teacher ,Madyapradesh",
  },
  {
    dreamImgCarosal: dm3,
    name: "Robert FOX",
    details: "Vocie Over Artist ,Teacher, Madyapradesh",
  },
  {
    dreamImgCarosal: dm4,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
  {
    dreamImgCarosal: dm5,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
  {
    dreamImgCarosal: dm1,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
  {
    dreamImgCarosal: dm2,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
];
export default function DreamMentors({
  animationCheckPointRef,
}: {
  animationCheckPointRef?: (el: HTMLDivElement | null) => void;
}) {
  const navigate = useNavigate();
  const [mentors, setMentors] = useState<Mentor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMentors = async () => {
      try {
        const response = await fetch(
          config.apiUrl + "/api/auth/mentors/mentorList"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch mentor data");
        }
        const responseData = await response.json();
        setMentors(responseData.data); // Access the "data" field from API response
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMentors();
  }, []);

  const handleMentorClick = (mentorId: number) => {
    navigate(`/mentor/${mentorId}`);
  };

  return (
    <div className="dream-mentor-main pt-8 w-screen flex flex-col items-center">
      <div className="w-fit relative">
        <h3 className="text-black text-3xl font-extrabold">Dream Mentors</h3>
        {animationCheckPointRef && (
          <div
            className="absolute -right-24 top-1/2 -translate-y-1/2 h-16 w-16"
            ref={animationCheckPointRef}
          ></div>
        )}
      </div>
      {/* slider section starts */}
      <div className="swiper-section w-[1360px] mx-auto p-8 max-md:w-full min-max-md:w-full min-max-lg:w-full">
        {/* slider section */}
        <div className=" dream-mentor-slider featuredCareers">
          <div className="flex">
            <div className="carousel">
              <div className="slider">
                <div className="slidetrack mentor-card flex">
                  {mentors?.map((item, index) => (
                    <div>
                      <div key={index} className="slide">
                        <img
                          src={item.imageUrl}
                          alt=" "
                          loading="lazy"
                          className="rounded-2xl"
                        />
                        <div className="mentor-details">
                          <h3 className="mentor-name">{item.name}</h3>
                          <p>
                            {item?.location ? item?.location : "location info"}{" "}
                          </p>
                          <Link
                            to={`/mentor/${item.id}`}
                            className="arrow-button text-[#113cc0]"
                          >
                            View details
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="currentColor"
                              className="arrow-icon ml-2"
                            >
                              <path
                                d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                                fill="currentColor"
                              />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="absolute bottom-[35px] z-[1] left-1/2 transform -translate-x-1/2  md:ml-[-28px]">
              <Link
                to={"/mentors"}
                className="arrow-button flex gap-2 justify-center white-color map-mentor  button-styles px-9 py-3.5 mt-9 text-base font-semibold text-white  rounded-[50px] max-md:px-5"
              >
                <span>View all mentors</span>

                {/* You can customize the arrow icon here */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="currentColor"
                  className="arrow-icon"
                >
                  <path
                    d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                    fill="currentColor"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
        {/* slider section ends */}
      </div>
      {/* slider section ends */}
    </div>
  );
}
