import "./SlideTrack.scss";

interface IProps {
  items: React.ReactNode[];
  speed: number;
  itemWidth: number;
  gap: number;
  windowSize: number;
}

const SlideTrack: React.FC<IProps> = ({
  items,
  speed,
  itemWidth,
  gap,
  windowSize,
}) => {
  const count = items.length;

  if (count < windowSize)
    return (
      <div>
        {items.map((item) => (
          <div
            className="inline-block"
            style={{ width: itemWidth, marginRight: gap }}
          >
            {item}
          </div>
        ))}
      </div>
    );

  const track = (
    <div
      className="slide-items-container w-max flex items-stretch"
      style={{
        animation: `horizontal-slide ${
          speed > 0 ? 20 / speed : 20
        }s linear infinite`,
      }}
    >
      {[...items, ...items].map((item) => (
        <div
          className="inline-flex items-center justify-center"
          style={{ width: itemWidth, marginRight: gap }}
        >
          {item}
        </div>
      ))}
    </div>
  );

  return (
    <div
      className="overflow-hidden"
      style={{ maxWidth: (itemWidth + gap) * windowSize }}
    >
      {track}
    </div>
  );
};

export default SlideTrack;
