import React, { useState, useEffect, useRef } from "react";
import Loader from "../../components/Loader/Loader";
import OverviewTab from "../../components/exam-details/OverviewTab";
import config from "../../util/config";
import EligibilityTab from "./EligibilityTab";
import ApplicationTab from "./ApplicationTab";
import ExamSyllabusTab from "./ExamSyllabusTab";
import ExamPatternTab from "./ExamPatternTab";
import AdmitCardTab from "./AdmitCardTab";
import AnswerKeyAnalysisTab from "./AnswerKeyAnalysisTab";
import ResultAndCutoffTab from "./ResultAndCutoffTab";
import CounselingTab from "./CounselingTab";
import { EpRight } from "../college-details/components/NavigationTabs";

interface Tab {
  name: string;
  title: string;
}

interface NavigationTabsProps {
  tabs: Tab[];
  examName: string;
}

const NavigationTabs: React.FC<NavigationTabsProps> = ({ tabs, examName }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0]?.name || "");
  const [tabContent, setTabContent] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const [isLeftDisabled, setIsLeftDisabled] = useState<boolean>(true);
  const [isRightDisabled, setIsRightDisabled] = useState<boolean>(false);
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tabs.length > 0) {
      setActiveTab(tabs[0].name);
      scrollToFirstTab();
    }
  }, [tabs]);

  useEffect(() => {
    if (activeTab && examName) {
      fetchTabContent(activeTab);
      scrollToContentStart();
    }
  }, [activeTab, examName]);

  useEffect(() => {
    const checkScrollable = () => {
      const tabContainer = tabContainerRef.current;
      if (tabContainer) {
        setIsScrollable(tabContainer.scrollWidth > tabContainer.clientWidth);
        setIsLeftDisabled(tabContainer.scrollLeft === 0);
        setIsRightDisabled(
          Math.ceil(tabContainer.scrollLeft + tabContainer.clientWidth) >=
            tabContainer.scrollWidth
        );
      }
    };

    checkScrollable();
    window.addEventListener("resize", checkScrollable);
    return () => window.removeEventListener("resize", checkScrollable);
  }, [tabs]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsSticky(scrollY > 705);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const fetchTabContent = async (tabName: string) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${config.apiUrl}/api/exam-details/${examName}/${tabName}`,
        {
          method: "GET",
          headers: { Accept: "*/*" },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch content for ${tabName}`);
      }

      const data = await response.json();
      setTabContent(data);
    } catch (err) {
      console.error(`Error fetching tab content:`, err);
      setTabContent(null);
    } finally {
      setLoading(false);
    }
  };

  const scrollToFirstTab = () => {
    const tabContainer = tabContainerRef.current;
    if (tabContainer) {
      tabContainer.scrollTo({ left: 0, behavior: "smooth" });
    }
  };

  const scrollToContentStart = () => {
    const contentTop = contentRef.current?.offsetTop || 0;
    const stickyOffset = 760; // Adjust this to match sticky offset height
    window.scrollTo({ top: contentTop - stickyOffset, behavior: "smooth" });
  };

  const handleLeftClick = () => {
    scrollToFirstTab();
  };

  const handleRightClick = () => {
    const tabContainer = tabContainerRef.current;
    if (tabContainer) {
      tabContainer.scrollTo({
        left: tabContainer.scrollWidth - tabContainer.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    const tabContainer = tabContainerRef.current;
    if (tabContainer) {
      setIsLeftDisabled(tabContainer.scrollLeft === 0);
      setIsRightDisabled(
        Math.ceil(tabContainer.scrollLeft + tabContainer.clientWidth) >=
          tabContainer.scrollWidth
      );
    }
  };

  if (!tabs.length) {
    return <p>No tabs available</p>;
  }

  return (
    <div>
      <div
        className={`transition-opacity duration-500 ${
          isSticky
            ? "fixed top-0 left-0 w-full opacity-100 z-50 shadow-lg"
            : "opacity-100"
        }`}
      >
        <ul
          className="flex items-center justify-between space-x-4 px-4 py-2 overflow-x-auto"
          style={{
            background: "linear-gradient(180deg, #173CBA 0%, #06AFA9 100%)",
          }}
        >
          {isScrollable && (
            <button
              className={`bg-[#00CC99] border border-white rounded text-white p-2 ${
                isLeftDisabled ? "opacity-50 pointer-events-none" : ""
              }`}
              onClick={handleLeftClick}
              disabled={isLeftDisabled}
            >
              <span className="material-icons">
                <EpRight className="rotate-180" />
              </span>
            </button>
          )}

          <div
            id="tabContainer"
            ref={tabContainerRef}
            className="overflow-x-auto scrollbar-hide w-full mx-auto"
            onScroll={handleScroll}
          >
            <ul className="list-none flex gap-4 items-center text-center w-full mx-auto xl:justify-center">
              {tabs.map((tab) => (
                <li
                  key={tab.name}
                  className={`text-base py-1.5 px-3.5 font-semibold rounded-sm cursor-pointer whitespace-nowrap ${
                    activeTab === tab.name
                      ? "bg-green text-white"
                      : "bg-white text-black hover:bg-green hover:text-white"
                  }`}
                  onClick={() => setActiveTab(tab.name)}
                >
                  {tab.title}
                </li>
              ))}
            </ul>
          </div>

          {isScrollable && (
            <button
              className={`bg-[#00CC99] border border-white rounded text-white py-2 px-2 ${
                isRightDisabled ? "opacity-50 pointer-events-none" : ""
              }`}
              onClick={handleRightClick}
              disabled={isRightDisabled}
            >
              <span className="material-icons">
                <EpRight />
              </span>{" "}
            </button>
          )}
        </ul>
      </div>

      <div ref={contentRef} className="flex flex-col">
        {loading ? (
          <Loader />
        ) : (
          <div>
            {activeTab === "overview" && <OverviewTab data={tabContent} />}
            {activeTab === "eligibility-criteria" && (
              <EligibilityTab data={tabContent} />
            )}
            {activeTab === "application" && (
              <ApplicationTab data={tabContent} />
            )}
            {activeTab === "exam-syllabus" && (
              <ExamSyllabusTab data={tabContent} />
            )}
            {activeTab === "exam-pattern" && (
              <ExamPatternTab data={tabContent} />
            )}
            {activeTab === "admit-card" && <AdmitCardTab data={tabContent} />}
            {activeTab === "answer-key-analysis" && (
              <AnswerKeyAnalysisTab data={tabContent} />
            )}
            {activeTab === "result-n-cut-off" && (
              <ResultAndCutoffTab data={tabContent} />
            )}
            {activeTab === "counseling" && <CounselingTab data={tabContent} />}
            {activeTab === "college-predictor" &&
              (tabContent && Object.keys(tabContent).length > 0 ? (
                <div></div>
              ) : (
                <p className="text-center text-red-500 py-10">
                  No data available for College Predictor.
                </p>
              ))}
            {activeTab === "faq’s" &&
              (tabContent && Object.keys(tabContent).length > 0 ? (
                <div></div>
              ) : (
                <p className="text-center text-red-500 py-10">
                  No data available for FAQ's.
                </p>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavigationTabs;
