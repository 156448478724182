import React from "react";
import type { SVGProps } from "react";
import LeadGenCtaBtn from "../../features/lead-generation/LeadGenCtaBtn";

export function IconParkOutlineRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="m19 12l12 12l-12 12"
      ></path>
    </svg>
  );
}
interface Guide {
  topic: string;
  download: string;
}

interface GuidesProps {
  guides: Guide[];
}

const Guides: React.FC<GuidesProps> = ({ guides }) => {
  return (
    <section className="py-16 bg-[#FEF8E7]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row items-start gap-10">
          <div className="w-full lg:w-1/3 flex flex-col">
            <div className="flex flex-col">
              <h3 className="text-4xl lg:text-5xl font-light text-black m-0">
                Guides
              </h3>
              <p className="text-2xl text-black font-semibold">
                Download free study abroad guides curated by our experts
              </p>
            </div>

            <div className="flex items-center mt-4">
              <div className="flex items-center relative w-full">
                <input
                  type="tel"
                  placeholder="Mobile Number"
                  className="w-full px-4 py-2 pr-40 border rounded-full text-black focus:outline-none"
                />
                <LeadGenCtaBtn
                  pageName="study-abroad-landing"
                  ctaName="download-guides"
                  className="bg-blue-900 text-white px-4 py-2 rounded-full flex items-center justify-center whitespace-nowrap absolute right-0"
                >
                  Talk to Expert{" "}
                  <span className="ml-2">
                    <IconParkOutlineRight />
                  </span>
                </LeadGenCtaBtn>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-2/3 grid grid-cols-2 md:grid-cols-4 gap-5">
            {guides.map((guide, index) => (
              <div
                key={index}
                className="bg-white rounded-2xl flex flex-col items-center text-center"
              >
                <div className="p-3">
                  <h4 className="text-lg font-light text-black uppercase">
                    {guide.topic}
                  </h4>
                  <div className="h-14"></div>
                </div>
                <hr className="m-0 border-[0.5px] border-[#B7C2E9] w-full" />
                <div className="p-3">
                  <a
                    href={guide.download || "#"}
                    download
                    className="mt-4 flex items-center text-black text-lg font-semibold gap-2.5"
                  >
                    Download
                    <svg
                      width="17"
                      height="19"
                      viewBox="0 0 17 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.579 7.05057H10.9414V0.790097C10.9414 0.626333 10.8049 0.5 10.6402 0.5H6.30135C6.13665 0.5 6.00018 0.626333 6.00018 0.790097V7.05057H2.36252C2.13193 7.05057 2.00487 7.33131 2.16017 7.51379L8.26371 14.3638C8.37195 14.4808 8.56489 14.4808 8.67312 14.3638L14.7861 7.51379C14.9414 7.33131 14.8143 7.05057 14.5837 7.05057H14.579Z"
                        fill="#173CBA"
                      />
                      <path
                        d="M16.9412 13.6761V17.3631C16.9412 17.9901 16.4283 18.5001 15.7883 18.5001H1.14353C0.512942 18.5001 0 17.9901 0 17.3631V13.6761C0 13.0491 0.512942 12.5391 1.14353 12.5391C1.77412 12.5391 2.28706 13.0491 2.28706 13.6761V16.2214H14.64V13.6761C14.64 13.0491 15.153 12.5391 15.7836 12.5391C16.4142 12.5391 16.9365 13.0491 16.9365 13.6761H16.9412Z"
                        fill="#173CBA"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Guides;
