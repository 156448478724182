import React, { useState } from "react";
import HowtoBecomeImg from "../../images/career/details/HowtoBecomeImg.png";
import HtmlContent from "../../components/HtmlContent";

interface HowBecomeCareerDetailsProps {
  content: {
    title: string;
    description: string;
    videoLink: string;
  };
}

const HowBecomeCareerDetails: React.FC<HowBecomeCareerDetailsProps> = ({
  content,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayVideo = () => {
    setIsPlaying(true);
  };

  return (
    <section>
      <div className="container mx-auto">
        <div className="bg-white relative">
          <div className="flex flex-col lg:flex-row justify-center">
            <div className="lg:w-2/3 flex flex-col">
              <div className="flex max-md:w-full">
                <h3 className="text-xl max-md:text-center lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
                  {content?.title}
                </h3>
              </div>
              <div
                className="text-black text-base leading-7 flex flex-col gap-5 py-5 px-8 common"
                dangerouslySetInnerHTML={{ __html: content?.description }}
              />
            </div>

            <div className="flex flex-col items-center justify-center w-full lg:w-1/3 relative">
              <div className="p-8 md:hidden max-md:hidden">
                {isPlaying ? (
                  <div className="relative w-full h-full z-10">
                    <iframe
                      width="100%"
                      height="100%"
                      src={
                        content.videoLink ||
                        "https://www.youtube.com/embed/T6oKlQoHrIg?autoplay=1"
                      }
                      title="YouTube Video"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="w-full h-full rounded-lg"
                    ></iframe>
                  </div>
                ) : (
                  <>
                    <button
                      className=" z-10 bg-white rounded-full shadow-lg focus:outline-none"
                      onClick={handlePlayVideo}
                    >
                      <img
                        src={HowtoBecomeImg}
                        alt="Video thumbnail"
                        className="w-full h-full object-cover rounded-lg cursor-pointer"
                        onClick={handlePlayVideo}
                      />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowBecomeCareerDetails;
